import React from "react";
import Users from "../../components/users/users";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AdminHeader from "../../components/AdminHeader/AdminHeader";



const User = () => {
  return (

    <>

      <AdminHeader />

      <Container maxWidth="lg">
        <Box sx={{ bgcolor: '#eef1f3' }} >
          <Users />
        </Box>
      </Container>

    </>
  )


};

export default User;

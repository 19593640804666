import "./App.css";
import React from 'react';
// import '../src/styles/kendoStyle.css'
import '@progress/kendo-theme-default/dist/all.css';
import 'react-notifications/lib/notifications.css';
import AppRoute from "./AppRoute";

function App() {
  return (
    <div >
      <AppRoute></AppRoute>
    </div>
  );
}

export default App;

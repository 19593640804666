import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box';
import { TextField, Container, Grid, InputLabel, Fade, Button, Paper, Card, Select, FormControl, MenuItem, IconButton, Menu, Checkbox, ClickAwayListener, } from '@mui/material';
import Header from '../../components/Header/Header'
import { makeStyles } from "@mui/styles";
import getAllAlertResponse from '../../apis/alerts/getAllAlert';
import getSearchResponse from '../../apis/search/getSearchResult';
import "../SearchPage/SearchPage.css"
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popper from '@mui/material/Popper';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate } from "react-router-dom";
import 'date-fns';
import { formatISO } from 'date-fns';
import Skeleton from '@mui/material/Skeleton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import {
    bodyContainer, widgetContainer, leftWidgetContainer, skeletonStyles, leftWidgetHead, PopperContainer, moreHoriStyles, emptyGridStyles, formContainer, fieldContainer, formLabelStyles,
    textFieldStyles,
    dateRangeLabelContainer,
    rightWidgetContainer,
    rightWidgetBody,
    rightWidgetCards,
    rightWidgetHead,
    cardDetails,
    cardTitle,
    rightFooter,
    buttonContainer
} from "./SearchPageStyles"
import AdminHeader from '../../components/AdminHeader/AdminHeader';



const StyledTextField = styled(TextField)({
    width: "110px",
    "& input": {
        padding: "4px 0px 5px 0px",
        fontSize: "10px",
        color: "#A2A2A2",
        fontWeight: "regular",
        fontFamily: "Arial",
        width: "50px",
        opacity: "60%"
    },
    "& button": {
        opacity: "60%",
        color: "#A2A2A2",
        padding: "4px 0px 4px 0px",
    },
    "& svg": {
        padding: "0px 0px 0px 25px"
    }
});

const StyledSelect = styled(Select)({
    width: "240px",
    "& input": {
        fontSize: "20px",
        color: "#A2A2A2",
        fontWeight: "regular",
        fontFamily: "Arial",

    },
    "& svg": {
        color: "#A2A2A2",
        opacity: "60%",
    },
    "&::before": {
        borderBottom: "1px solid #2699FB"
    }
});

const StyledSkeleton = styled(Skeleton)({
    maxHeight: "11vh", minHeight: "11vh", marginBottom: "10px"

})


const useStyles = makeStyles({
    pageContainer: {
        backgroundColor: "white",
    },
    customTextField: {
        "& input::placeholder": {
            fontSize: "10px",
            color: "#A2A2A2",
            fontWeight: "regular",
            fontFamily: "Arial",
        },
    },
    placeholderSelect: {
        fontSize: "10px",
        color: "#A2A2A2",

        fontFamily: "Arial",
        opacity: "50%",
    },
    mainGrid: {
        height: '100%',
    },
    leftContainer: {
        boxShadow: "0px 5px 8px #E1E1E1",
        backgroundColor: "white",
        borderRadius: "15px",
        marginTop: '-15px',
        '@media (max-width: 800px)': {
            width: '320%',
            marginBottom: '15px',
          }
    },
    rightContainer: {
        boxShadow: "0px 5px 8px #E1E1E1",
        backgroundColor: "white",
        borderRadius: "15px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%'
    },
    rightWidgetHead: {
        borderBottom: "1px solid #707070",
        fontWeight: "600",
        fontSize: "17px",
        fontFamily: "Manrope",
        height: "25px",
        marginBottom: '15px',
        marginRight: '15px',
        paddingBottom: '8px'
    },
    searchResultItem: {
        boxShadow: "0px 5px 8px #E1E1E1",
        borderRadius: "15px",
        padding: "5px 10px",
        marginBottom: "20px",
        display: 'grid',
        gridTemplateColumns: "1fr 2fr 2fr",
        gap: 5,
        cursor: "pointer"
    },
    leftWidgetHead: {
        fontFamily: "Manrope",
        fontSize: "17px",
        backgroundColor: "black",
        color: "white",
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        paddingBottom: '15px',
        paddingTop: '15px',
        paddingLeft: '15px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px'
    },
    fieldContainer: {
        width: "250px !important",
        margin: '10px 0 10px 10px !important',
        borderLeft: "5px solid #F8AD85",
        paddingLeft: "5px"
    },
    buttonContainer: {
        width: "100% !important",
        margin: '25px 20px 35px 20px !important'
    },

});



function SearchPage() {

    const classes = useStyles();
    const navigate = useNavigate();


    const [allAlerts, setallAlerts] = useState([]);
    const [filteredAlerts, setFilteredAlerts] = useState(null);
    const [skeletonLoader, setSkeletonLoader] = useState(true)
    const [searchPayload, setSearchPayload] = useState({
        "alertId": "",
        "alertstatus": "",
        "customerName": "",
        "accountNo": "",
        "ssnNumber": "",
        "todate": "",
        "fromdate": ""
    })

    const [nameVal, setNameVal] = useState("");
    const [SSNVal, setSSNVal] = useState("");
    const [statusVal, setStatusVal] = useState("");
    const [accountVal, setAccountVal] = useState("");
    const [alertIDVal, setAlertIDVal] = useState("");
    const [dateRangeFromVal, setDateRangeFromVal] = useState(new Date());
    const [dateRangeToVal, setDateRangeToVal] = useState(new Date());

    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(Boolean(anchorEl));

    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();

    const [nameField, setNameField] = useState(true);
    const [alertField, setAlertField] = useState(true);
    const [accountField, setAccountField] = useState(true);
    const [SSNField, setSSNField] = useState(true);
    const [dateField, setDateField] = useState(true);
    const [statusField, setStatusField] = useState(true);

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };



    const getAllAlert = async () => {
        try {
            const getAllAlert = await getAllAlertResponse()
            setallAlerts(getAllAlert.data.data)
            setFilteredAlerts(getAllAlert.data.data)
        } catch (error) {
            console.error("No Response")
        }
    }
    const getSearchResult = async (payload) => {
        try {
            setSkeletonLoader(true)
            const getSearchResult = await getSearchResponse(payload)
            setFilteredAlerts(getSearchResult.data.data)
            setSkeletonLoader(false)
        } catch (error) {
            console.error("No Response")
        }
    }

    useEffect(() => {
        getSearchResult(searchPayload)

    }, [])

    const nameChangeHandler = (e) => {
        setNameVal(e.target.value)
        setSearchPayload((prev) => ({ ...prev, "customerName": e.target.value }))
    }
    // search SSN (case sensitive)
    const SSNChangeHandler = (e) => {
        setSSNVal(e.target.value)
        setSearchPayload((prev) => ({ ...prev, "ssnNumber": e.target.value }))
    }
    // search account num (case sensitive)
    const accountChangeHandler = (e) => {
        setAccountVal(e.target.value)
        setSearchPayload((prev) => ({ ...prev, "accountNo": e.target.value }))
    }
    // search alertID (case sensitive)
    const alertIDChangeHandler = (e) => {
        setAlertIDVal(e.target.value)
        setSearchPayload((prev) => ({ ...prev, "alertId": e.target.value }))
    }
    // search status (case sensitive)
    const statusChangeHandler = (e) => {
        setStatusVal(e.target.value)
        setSearchPayload((prev) => ({ ...prev, "alertstatus": e.target.value.replace(/\s/g, "") }))
    }
    const dateRangeFromHandler = (newRangeFrom) => {
        setDateRangeFromVal(newRangeFrom)

        if (newRangeFrom) {
            const epochTime = newRangeFrom.getTime();
            if (typeof epochTime !== 'number' || Number.isNaN(epochTime)) {
                return false;
            }
            setSearchPayload((prev) => ({ ...prev, "fromdate": epochTime }))
        }
    }

    const dateRangeToHandler = (newRangeTo) => {
        setDateRangeToVal(newRangeTo)

        if (newRangeTo) {
            const epochTime = newRangeTo.getTime();
            if (typeof epochTime !== 'number' || Number.isNaN(epochTime)) {
                return false;
            }
            setSearchPayload((prev) => ({ ...prev, "todate": epochTime }))
        }
    }

    const searchHandler = () => {

        getSearchResult(searchPayload)
    }


    const handleCloseMenu = () => {
        setOpenMenu(false);
    };
    const handleCloseBox = () => {
        setOpen(false)
    };

    const handleMenuItem = (fieldName) => {
        if (fieldName === 'name') {
            setNameField(!nameField)
            setNameVal("");
            setSearchPayload((prev) => ({ ...prev, "customerName": '' }))
        }
        else if (fieldName === 'ssn') {
            setSSNField(!SSNField)
            setSSNVal("")
            setSearchPayload((prev) => ({ ...prev, "ssnNumber": '' }))
        }
        else if (fieldName === 'dateRange') {
            setDateField(!dateField);
            setSearchPayload((prev) => ({ ...prev, "fromdate": '', "todate": '' }))
        }
        else if (fieldName === 'accNo') {
            setAccountField(!accountField)
            setAccountVal('')
            setSearchPayload((prev) => ({ ...prev, "accountNo": '' }))
        }
        else if (fieldName === 'alertId') {
            setAlertField(!alertField);
            setAlertIDVal('')
            setSearchPayload((prev) => ({ ...prev, "alertId": '' }))
        }
        else if (fieldName === 'status') {
            setStatusField(!statusField)
            setStatusVal('')
            setSearchPayload((prev) => ({ ...prev, "alertstatus": '' }))
        }
    }

    const viewAlertHandler = (e, item) => {
        navigate('/alerts', {
            state: {
                id: item.id
            }
        });
    }
    const goToAlertKendo = (e) => {
        navigate(-1)
    }

    const getSkeleton = () => {
        let skeletons = [];
        for (let i = 0; i < 6; i++) {
            skeletons.push(<StyledSkeleton variant="rounded" animation="wave" />)
        }
        return skeletons;
    }



    return (
        <>
            <div className='mainSearchContainer'>
                <AdminHeader />
                <Container>
                    <div className="alert_bac" >
                        <span className="cp" onClick={(e) => goToAlertKendo(e)} ><ArrowBackIcon /></span>
                    </div>
                    <div className='mainDiv_search'>
                        <Grid container spacing={2} className={classes.mainGrid}>
                            <Grid item xs={3} className='leftsideMainContainer'>
                                <div className={classes.leftContainer}>
                                    <Grid container>
                                        <Grid item className={classes.leftWidgetHead}>
                                            <span className='leftHeadText'>Showing results for</span>
                                            <ClickAwayListener onClickAway={handleCloseBox}>
                                            <Box sx={PopperContainer}>                                           
                                                <Popper open={open} anchorEl={anchorEl} placement={placement} transition>    
                                                    {({ TransitionProps }) => (
                                                        <Fade {...TransitionProps} timeout={350}>
                                                            <Paper>
                                                                <MenuItem onClick={(e) => { handleMenuItem('name') }}><Checkbox checked={nameField} />Name</MenuItem>
                                                                <MenuItem onClick={(e) => { handleMenuItem('dateRange') }}><Checkbox checked={dateField} onChange={(e) => setDateField(e.target.checked)} />Date Range</MenuItem>
                                                                <MenuItem onClick={(e) => { handleMenuItem('accNo') }}><Checkbox checked={accountField} onChange={(e) => setAccountField(e.target.checked)} />Account ID</MenuItem>
                                                                <MenuItem onClick={(e) => { handleMenuItem('alertId') }}><Checkbox checked={alertField} onChange={(e) => setAlertField(e.target.checked)} />Alert ID</MenuItem>
                                                                <MenuItem onClick={(e) => { handleMenuItem('status') }}><Checkbox checked={statusField} onChange={(e) => setStatusField(e.target.checked)} />Alert Status</MenuItem>


                                                            </Paper>                                                 
                                                         </Fade> 
                                                    )}                                      
                                                </Popper>

                                                <Grid container justifyContent="center">
                                                    <Grid item>
                                                        <IconButton >                                                          
                                                            <MoreHorizIcon
                                                                sx={moreHoriStyles}
                                                                onClick={handleClick('bottom')}
                                                            />                                                           
                                                        </IconButton>

                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            </ClickAwayListener>
                                        </Grid>
                                        {nameField && (<Grid container item direction="column" justifyContent="flex-end" className={classes.fieldContainer}>


                                            <InputLabel sx={formLabelStyles}>
                                                Name
                                            </InputLabel>

                                            <TextField
                                                InputProps={{ disableUnderline: true }}
                                                sx={textFieldStyles}
                                                classes={{ root: classes.customTextField }}
                                                placeholder="Please type in Name" fullWidth id="standard-basic" variant="standard" value={nameVal} onChange={nameChangeHandler}


                                            />

                                        </Grid>)}

                                        {dateField &&
                                            <Grid item className={classes.fieldContainer}>
                                                <InputLabel sx={formLabelStyles}>
                                                    Date Range
                                                </InputLabel>
                                                <Grid container justifyContent="space-between" >

                                                    <Grid item >
                                                        <Typography sx={dateRangeLabelContainer}>From</Typography>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DesktopDatePicker
                                                                disableFuture
                                                                inputFormat="MM/dd/yyyy"
                                                                value={dateRangeFromVal}
                                                                onChange={dateRangeFromHandler}
                                                                renderInput={(params) => (
                                                                    <StyledTextField {...params} className="myDatePicker" />
                                                                )}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>

                                                    <Grid item >
                                                        <Typography sx={dateRangeLabelContainer}>To</Typography>

                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DesktopDatePicker
                                                                disableFuture
                                                                inputFormat="MM/dd/yyyy"

                                                                value={dateRangeToVal}
                                                                onChange={dateRangeToHandler}
                                                                renderInput={(params) => (
                                                                    <StyledTextField {...params} className="myDatePicker" />
                                                                )}
                                                            />
                                                        </LocalizationProvider>

                                                    </Grid>
                                                </Grid>


                                            </Grid>}


                                        {accountField &&
                                            <Grid item className={classes.fieldContainer}>
                                                <InputLabel sx={formLabelStyles}>
                                                    Account ID
                                                </InputLabel>
                                                <TextField
                                                    InputProps={{ disableUnderline: true }}
                                                    classes={{ root: classes.customTextField }}
                                                    sx={textFieldStyles}
                                                    placeholder="Please type in Account ID" fullWidth id="standard-basic" variant="standard" value={accountVal} onChange={accountChangeHandler} />
                                            </Grid>}

                                        {alertField &&
                                            <Grid item className={classes.fieldContainer}>
                                                <InputLabel sx={formLabelStyles}>
                                                    Alert ID
                                                </InputLabel>
                                                <TextField
                                                    InputProps={{ disableUnderline: true }}
                                                    classes={{ root: classes.customTextField }}
                                                    sx={textFieldStyles}
                                                    placeholder="Please type in Alert ID" fullWidth id="standard-basic" variant="standard" value={alertIDVal} onChange={alertIDChangeHandler} />
                                            </Grid>}

                                        {statusField &&
                                            <Grid item className={classes.fieldContainer}>
                                                <InputLabel sx={formLabelStyles}>
                                                    Status
                                                </InputLabel>

                                                <StyledSelect
                                                    variant="standard"

                                                    displayEmpty
                                                    value={statusVal}
                                                    onChange={statusChangeHandler}
                                                    label="Status"


                                                    renderValue={(selected) => {
                                                        if (selected.length === 0) {
                                                            return <div className={classes.placeholderSelect}>Please Select Status</div>;
                                                        }

                                                        return selected;
                                                    }}

                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={"True Match"}>True Match </MenuItem>
                                                    <MenuItem value={"False Positive"}>False Positive</MenuItem>
                                                    <MenuItem value={"Pending Manual Review"}>Pending Manual Review</MenuItem>
                                                </StyledSelect>
                                            </Grid>}

                                        <Grid item className={classes.buttonContainer}>
                                            <Button className='search_button' onClick={searchHandler} variant="contained" fullWidth >Search </Button>
                                        </Grid>

                                    </Grid>

                                </div>
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={8.75} className={classes.rightContainer}>
                                <div className='rightContainer'>
                                    <div className={classes.rightWidgetHead}>
                                        <p className="card_details">Search Results</p>
                                    </div>
                                    <div className='searchResult'>
                                        {skeletonLoader ? getSkeleton()

                                            : filteredAlerts.length > 0
                                                ? filteredAlerts.map((item) =>
                                                    <>
                                                        <Grid container justifyContent="space-between" sx={rightWidgetCards}
                                                            onClick={(e) => viewAlertHandler(e, item)}>
                                                            <Grid item sx={cardTitle}>
                                                                Alert ID : {item.id.substring(0, 4)}
                                                            </Grid>
                                                            <Grid item sx={cardDetails}>
                                                                <p className="card_details"> Name :  {(item?.customers?.MIDDLE_NAMES) ?
                                                                    item?.customers?.FIRST_NAME
                                                                    + " " + item?.customers?.MIDDLE_NAMES + " " +
                                                                    item?.customers?.LAST_NAME : item?.customers?.FIRST_NAME
                                                                    + " " + item?.customers?.LAST_NAME}</p>
                                                                <p className="card_details"> Nationality :  {item?.customers?.COUNTRY_OF_RESIDENCE}</p>

                                                            </Grid>
                                                            <Grid item sx={cardDetails}>

                                                                <p className="card_details"> Alert Status :  {item.finalstatus?.name}</p>
                                                                <p className="card_details"> Update At :  {item.updatedAt.slice(5, 7) + "-" + item.updatedAt.slice(8, 10) + "-" + item.updatedAt.slice(0, 4)}</p>
                                                                <p className="card_details"> Account ID :  {item?.customers?.primaryaccount?.ACCOUNT_ID || 'N/A'}</p>
                                                            </Grid>

                                                        </Grid>
                                                    </>

                                                )
                                                : <p> No Match Found  &#128533;</p>}

                                    </div>
                                    <div className='bottomBorder'></div>
                                </div>

                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
        </>

    )
}

export default SearchPage

import React, { useState } from 'react';
import { Box, Typography, Grid, Dialog, DialogTitle, DialogActions, DialogContent, OutlinedInput } from "@mui/material";

import AnaptyssText from "./../../resources/images/Header.png";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AlfaLogo from "./../../resources/images/ALFA.svg";
import "./Footer.css"
import { supportStyles, detailStyles, anaptyssStyles, boxStyles, bottomStyles, logoWrapStyles } from "./FooterStyles"
import LinkedIn from "./../../resources/images/linkedin.svg";
import YouTube from "./../../resources/images/youtube.svg";
import supportMailResponse from '../../apis/supports/support';
import { makeStyles } from '@mui/styles';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ContactUsModal from '../ContactUs/ContactUsModal';
import { Public } from '@mui/icons-material';

const useStyles = makeStyles({
    support: {
        display: "inline-block"
    }
})


export default function Footer() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModalHandler = () => {
        setIsModalOpen(false);
    }

    const classes = useStyles();
    return (
        <>

            {/* Grid Box for Bottom Section */}
            < Grid sx={bottomStyles}>

                {/* Box for Footer */}
                < Box sx={boxStyles}  >

                    <Grid container >

                        <Grid container xs={12} >
                            <Grid item container direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start" xs={2} sx={anaptyssStyles}>
                                <Grid item sx={logoWrapStyles}>
                                    <img src={AlfaLogo} className="footer-img" alt="Alfa Logo" />
                                </Grid>
                                <Grid item  ><a href="https://www.anaptyss.com/" target="_blank"><img src={AnaptyssText} alt="Anaptyss Home"></img></a></Grid>
                                <Grid item> ©Anaptyss</Grid>
                            </Grid>
                            <Grid item xs={3} lg={3.5} sm={3}></Grid>
                            <Grid item container direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start" xs={2} sx={detailStyles}>
                                <Grid item >About Us</Grid>
                                <Grid item >Terms & Conditions</Grid>
                                <Grid item >Privacy Policy</Grid>
                            </Grid>
                            <Grid item xs={1} sm={3}lg={3.5}></Grid>
                            <Grid item container direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start" sx={supportStyles} xs={2.5} lg={1} sm={2} >


                                <Grid item>
                                    <Grid container direction="row" spacing={0.5}>
                                        <Grid item > <MailOutlineIcon fontSize='inherit' /></Grid>
                                        <Grid item  >

                                            <div className={classes.support} onClick={() => { setIsModalOpen(true) }}> <a href="#" className='socialLinks'>Contact us</a></div>

                                        </Grid>
                                    </Grid>


                                </Grid>
                                <Grid item>support@goalfa.ai </Grid>

                                <Grid container direction="row" spacing={0.5}>
                                    <Grid item > <a href='https://www.linkedin.com/company/anaptyss-inc/' target="_blank" className='socialLinks'><img src={LinkedIn} alt="Linkedin Icon" className="social_icons" /></a></Grid>
                                    <Grid item > <a href='https://www.youtube.com/channel/UCysXHy5aMY5wfqdW2RHMe5w' target="_blank" className="socialLinks"><img src={YouTube} alt="YouTube Icon" className="social_icons" /> </a></Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        {/* <Grid xs={2} container alignItems="flex-start" justifyContent="space-between" >

                        </Grid> */}
                    </Grid>
                </Box >
            </Grid >
            {/* Dialog box for Support   */}
            <ContactUsModal isModalOpen={isModalOpen} onModalClose={closeModalHandler} />
            <NotificationContainer />


        </>
    )
}
import React, { useState, useEffect } from "react";
import { Grid, Button, Container } from "@mui/material"
import { NotificationContainer, NotificationManager, } from "react-notifications";
import runalertResponse from "../../apis/alerts/runAlert";
import ResetAlert from "../../apis/alerts/ResetAlerts";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const AdminAlerts = () => {
  const [alertLoder, setalertLoder] = useState(false);
  const getrunAlert = async () => {
    try {
      setalertLoder(true)
      const res = await runalertResponse();
      const data = res.data.data;
      console.log("data", data)
       setalertLoder(false)
      await NotificationManager.success("Alert Added Successfully", "Success", 3000);

    } catch (error) {
      console.log(error.response.data);
    }
  };

  const ResetAlerts = async () => {
    try {
      setalertLoder(true)
      const res = await ResetAlert();
      const data = res.data.data;
      setalertLoder(false)
      await NotificationManager.success("Alert Reset Successfully", "Success", 3000);

    } catch (error) {
      console.log(error.response.data);
    }
  };

  return (
    <>

      <AdminHeader />

      <Container maxWidth="xl">

        <div className="section-wrapper">
          <div className="container_admin">
            <div className="admin-container">
              <section className="admin-section-home-first mb_32">
                <Grid container justifyContent="space-evenly" alignItems="center" >

                  <Button label="Run Alerts" onClick={getrunAlert} variant="contained" size="large" sx={{ color: "black", backgroundColor: "#1FBECE", fontWeight: 800, fontSize: "1em", textTransform: "none" }} >Run Alerts</Button>

                  <Button label="Reset Alerts" onClick={ResetAlerts} variant="contained" size="large" sx={{ color: "black", backgroundColor: "#1FBECE", fontWeight: 800, fontSize: "1em", textTransform: "none" }} >Reset Alerts</Button>

                </Grid>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={alertLoder}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>


              </section>
            </div>
          </div>

        </div>

      </Container>
      <NotificationContainer />

    </>
  )
}

export default AdminAlerts
export const avatarStyles = { mr: 1, backgroundColor: "#ef4e64", color: "#b4c5d3" };
export const loginFormStyles = {
    // mx: 15,
    backgroundColor: "#1B1C20",
    boxShadow: 6
};
export const bannerStyle = { mt: -15 }
export const logoWrapStyles = { ml: '-4em', mb: '-3em' };
export const bannerTopText = { ml: '25px', mb: 2, color: "#1DBECF", fontSize: "2em", fontWeight: 'bold', fontFamily: "Merriweather" }
export const bannerBottomText = { ml: '25px', fontStyle: 'italic', color: "white", fontWeight: 'light', fontFamily: "Merriweather", fontSize: "1.4em" }
export const loginFormHead = { mx: 5, my: 5, color: "white", fontWeight: '800', fontFamily: "Manrope", fontSize: "1.2em" }
export const formTopTextStyle = { mx: 5 }
export const loginButtonStyles = { mb: 8, mt: 6, bgcolor: "#EF4E64", width: '60%', fontSize: '14px', fontFamily: "Manrope", fontWeight: 'bold', textTransform: "none" };
export const passwordStyle = { color: "#fff", FontFamily: "Arial", fontWeight: 'bold', fontSize: "1em", mt: 2, mb: -1 };
export const emailStyle = { color: "#fff", FontFamily: "Arial", fontWeight: 'bold', fontSize: "1em", mb: -1 };
export const textFieldStyle = { backgroundColor: "#F1F9FF" };
// export const logingcon = { margin: '7% 14%',}

import axios from "axios";
const jsonConfig = require("../../config.json");

const updateSupressAlert = async (id,payload) => {
  try {
    const getAllResponse = await axios.put(jsonConfig.apiUrl + "suppressalert/"+id,payload, {
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default updateSupressAlert;

import axios from "axios";
const jsonConfig = require("../../config.json");

const getSearchResponse = async (payload) => {
    try {
        const getSearchResult = await axios.post(jsonConfig.apiUrl + "searchalert", payload, {
            headers: {
                "x-functions-key": jsonConfig.key,
            },
        });

        return Promise.resolve(getSearchResult);
    } catch (error) {
        return Promise.reject(error);
    }
};
export default getSearchResponse;
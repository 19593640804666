import React from 'react'
import Roles from '../../components/roles/Roles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AdminHeader from '../../components/AdminHeader/AdminHeader';


function Role() {
  return (

    <>

      <AdminHeader/>

      <Container maxWidth="lg">
        <Box sx={{ bgcolor: '#eef1f3' }} >
          <Roles />
        </Box>
      </Container>

    </>
  )
}

export default Role
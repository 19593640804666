import React, { useEffect, useState } from 'react';

import { Container, Box, Button, Grid, Modal, Paper } from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Footer from "./../../components/Footer/Footer";
import Header from "./../../components/Header/Header";
import "./RulesPage.css"
import getAllRulesResponse from '../../apis/rules/rulesResponse';
import backgroundPic from "./../../resources/images/Background.svg"
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles({
    pageContainer: {
        backgroundImage: `url(${backgroundPic})`
    }
})


export default function RulesPage() {
    const navigate = useNavigate();

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = React.useState(false);
    const [modalText, setModalText] = React.useState("");
    const [modalType, setModalType] = React.useState("");
    const [rulesList, setRulesList] = useState([]);

    const getAllRules = async () => {
        const allRules = await getAllRulesResponse();
        console.log(allRules.data.data);
        setRulesList(allRules.data.data);
    };
    useEffect(() => {
        getAllRules();
    }, []);

    function handleOpen() {
        setOpen(true);
        for (var i = 0; i < rulesList.length; i++) {
            // console.log('rulesList[i].rules_engine_type.name',rulesList[i].rules_engine_type.name);
            if (value == "1" && rulesList[i].rules_engine_type.name === 'True Match') {
                let trueMatch = rulesList[i].description.toString();
                //let trueMatch = "the\nis";
                console.log('trueMatch', trueMatch);
                setModalText(trueMatch);
                setModalType(rulesList[i].rules_engine_type.name);
            }
            else if (value === "2" && rulesList[i].rules_engine_type.name === 'Suppression Rules') {
                let Suppressionrule = rulesList[i].description.toString();
                console.log('Suppressionrule', Suppressionrule);
                setModalText(Suppressionrule);
                setModalType(rulesList[i].rules_engine_type.name);
            } else if (value === "4" && rulesList[i].rules_engine_type.name === 'Weak Algorithms') {
                let Suppressionrule = rulesList[i].description.toString();
                console.log('Suppressionrule', Suppressionrule);
                setModalText(Suppressionrule);
                setModalType(rulesList[i].rules_engine_type.name);
            }

        }
    }
    function handleDOBOpen() {
        setOpen(true);
        for (var j = 0; j < rulesList.length; j++) {
            if (value == "3" && rulesList[j].type === "Date of Birth Information") {
                let dobrule = rulesList[j].description.toString();
                setModalText(dobrule);
                setModalType(rulesList[j].rules_engine_type.name);
            }
        }
    }
    function handleAddressOpen() {
        setOpen(true);
        for (var k = 0; k < rulesList.length; k++) {
            if (value == "3" && rulesList[k].type === "​Address Information​") {
                let addressrule = rulesList[k].description.toString();
                setModalText(addressrule);
                setModalType(rulesList[k].rules_engine_type.name);
            }
        }
    }
    function handleAgeOpen() {
        setOpen(true);
        for (var l = 0; l < rulesList.length; l++) {
            if (value == "3" && rulesList[l].type === '‘Age Approximation’ Logic') {
                let agerule = rulesList[l].description.toString();
                setModalText(agerule);
                setModalType(rulesList[l].rules_engine_type.name);
            }
        }
    }

    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "25vw",
        bgcolor: 'background.paper',
        boxShadow: 24,
        px: 4,
        py: 2
    };

    const classes = useStyles();
    const goback = () => {
    navigate('/dashboard', {})
    };

    return (
        <div className={classes.pageContainer} >

            <div className="BodyWithFooter">
                <div className="ContentBody">

                    <Header />

                    {/* Box for main section */}

                    <Container maxWidth="lg" >
                        <Box sx={{ width: '100%', typography: 'body1', my: 2 }}>
                        <div style={{ }} >
                            <span className="cp" onClick={goback}><ArrowBackIcon /></span>
                        </div>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#000",
                                            color: "#000"
                                        }
                                    }} >
                                        <Tab label="True Match" value="1" />
                                        {/* <Tab label="Suppression Rules" value="2" /> */}
                                        <Tab label="False Positive" value="3" />
                                        <Tab label="Weak Algorithms" value="4" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    {/* Code for tab screen */}

                                    <Paper elevation={3} sx={{ pr: 5, mx: -3 }} >
                                        <Grid sx={{ mx: 2 }} container>
                                            <Grid item xs={6} sx={{ borderBottom: 2, height: "4rem" }}>
                                                <p id="para-with-font" className="Heading">Field Matching Rules</p>
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ px: 2, pb: 20 }}>

                                            {/* Code for row */}
                                            <Grid container item sx={{ borderBottom: 1, mt: 5 }}>
                                                <Grid container item xs={6} >
                                                    <Grid className='tab_inner_box' item xs={6} sx={{ border: 1, px: 1, minWidth: "100%", maxHeight: "4em", boxShadow: 1 }} >
                                                        <p id="para-with-font" className="column1">Date of Birth Information & Address Information</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={6} justifyContent="flex-end" >
                                                    <Grid container item justifyContent="flex-end" xs={4} sx={{ py: 3 }}>
                                                        <Button className='tab_inner_box_button' onClick={handleOpen} label="View Rule" variant="contained" size="small" fullWidth sx={{ color: "black", backgroundColor: "#1FBECE", fontWeight: 800, fontSize: "0.8em", textTransform: "none" }} >View Rule</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                </TabPanel>
                                <TabPanel value="2">
                                    {/* Code for tab screen */}

                                    <Paper elevation={3} sx={{ pr: 5, mx: -3 }} >
                                        <Grid sx={{ mx: 2 }} container>
                                            <Grid item xs={6} sx={{ borderBottom: 2, height: "4rem" }}>
                                                <p id="para-with-font" className="Heading">Field Matching Rules</p>
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ px: 2, pb: 20 }}>
                                            <Grid container item sx={{ borderBottom: 1, mt: 5 }}>
                                                <Grid container item xs={6} >
                                                    <Grid className='tab_inner_box' item xs={6} sx={{ border: 1, px: 1, minWidth: "100%", maxHeight: "4em", boxShadow: 1 }} >
                                                        <p id="para-with-font" className="column1">Y.O.B. Logic</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={6} justifyContent="flex-end" >
                                                    <Grid container item justifyContent="flex-end" xs={4} sx={{ py: 3 }}>
                                                        <Button className='tab_inner_box_button' onClick={handleOpen} label="View Rule" variant="contained" size="small" fullWidth sx={{ color: "black", backgroundColor: "#1FBECE", fontWeight: 800, fontSize: "0.8em", textTransform: "none" }} >View Rule</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                </TabPanel>
                                <TabPanel value="3">
                                    {/* Code for tab screen */}


                                    <Paper elevation={3} sx={{ pr: 5, mx: -3 }} >

                                        {/* Heading Box */}
                                        <Grid sx={{ mx: 2 }} container>
                                            <Grid item xs={6} sx={{ borderBottom: 2, height: "4rem" }}>
                                                <p id="para-with-font" className="Heading">Field Matching Rules</p>
                                            </Grid>
                                        </Grid>

                                        {/* content Box */}
                                        <Grid sx={{ px: 2, pb: 5 }}>
                                            {/* Code for row */}
                                            <Grid container item sx={{ borderBottom: 1, mt: 5 }}>
                                                <Grid container item xs={6} >
                                                    <Grid className='tab_inner_box' item xs={6} sx={{ border: 1, px: 1, minWidth: "100%", maxHeight: "4em", boxShadow: 1 }} >
                                                        <p id="para-with-font" className="column1">Date of Birth Information​</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={6} justifyContent="flex-end" >
                                                    <Grid container item justifyContent="flex-end" xs={4} sx={{ py: 3 }}>
                                                        <Button className='tab_inner_box_button' onClick={handleDOBOpen} label="View Rule" variant="contained" size="small" fullWidth sx={{ color: "black", backgroundColor: "#1FBECE", fontWeight: 800, fontSize: "0.8em", textTransform: "none" }} >View Rule</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* Code for row */}
                                            <Grid container item sx={{ borderBottom: 1, mt: 5 }}>
                                                <Grid container item xs={6} >
                                                    <Grid className='tab_inner_box' item xs={6} sx={{ border: 1, px: 1, minWidth: "100%", maxHeight: "4em", boxShadow: 1 }} >
                                                        <p id="para-with-font" className="column1">Address Information​</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={6} justifyContent="flex-end" >
                                                    <Grid container item justifyContent="flex-end" xs={4} sx={{ py: 3 }}>
                                                        <Button className='tab_inner_box_button' onClick={handleAddressOpen} label="View Rule" variant="contained" size="small" fullWidth sx={{ color: "black", backgroundColor: "#1FBECE", fontWeight: 800, fontSize: "0.8em", textTransform: "none" }} >View Rule</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* Code for row */}
                                            <Grid container item sx={{ borderBottom: 1, mt: 5 }}>
                                                <Grid container item xs={6} >
                                                    <Grid className='tab_inner_box' item xs={6} sx={{ border: 1, px: 1, minWidth: "100%", maxHeight: "4em", boxShadow: 1 }} >
                                                        <p id="para-with-font" className="column1">‘Age Approximation’ Logic</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={6} justifyContent="flex-end" >
                                                    <Grid container item justifyContent="flex-end" xs={4} sx={{ py: 3 }}>
                                                        <Button className='tab_inner_box_button' onClick={handleAgeOpen} label="View Rule" variant="contained" size="small" fullWidth sx={{ color: "black", backgroundColor: "#1FBECE", fontWeight: 800, fontSize: "0.8em", textTransform: "none" }} >View Rule</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>




                                </TabPanel>
                                <TabPanel value="4">
                                    {/* Code for tab screen */}

                                    <Paper elevation={3} sx={{ pr: 5, mx: -3 }} >
                                        <Grid sx={{ mx: 2 }} container>
                                            <Grid item xs={6} sx={{ borderBottom: 2, height: "4rem" }}>
                                                <p id="para-with-font" className="Heading">Field Matching Rules</p>
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ px: 2, pb: 20 }}>
                                            <Grid container item sx={{ borderBottom: 1, mt: 5 }}>
                                                <Grid container item xs={6} >
                                                    <Grid className='tab_inner_box' item xs={6} sx={{ border: 1, px: 1, minWidth: "100%", maxHeight: "4em", boxShadow: 1 }} >
                                                        <p id="para-with-font" className="column1">‘Year of Birth’ Logic​</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={6} justifyContent="flex-end" >
                                                    <Grid container item justifyContent="flex-end" xs={4} sx={{ py: 3 }}>
                                                        <Button className='tab_inner_box_button' onClick={handleOpen} label="View Rule" variant="contained" size="small" fullWidth sx={{ color: "black", backgroundColor: "#1FBECE", fontWeight: 800, fontSize: "0.8em", textTransform: "none" }} >View Rule</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Container >

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Grid sx={{ borderBottom: 1.5, borderColor: "#707070" }}>
                                <p id="para-with-font" className="modal-header">
                                    Engine Rule
                                </p>

                            </Grid>
                            <Grid sx={{ py: 4 }}>
                                <Grid>
                                    <p id="para-with-font" className="modal-title">{modalType}</p>
                                </Grid>
                                <Grid >
                                    <textarea className="ModalText" disabled={true}>{modalText}</textarea>
                                </Grid>

                            </Grid>


                        </Box>
                    </Modal>




                </div>
                <Footer />

            </div >
        </div>
    )
}
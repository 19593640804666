import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CircularProgress from '@mui/material/CircularProgress';
import DashboardSkelton from '../../components/Skelton/DashboardSkelton';

const Landing = (props) => {
  const { userContext } = props;
  const navigate = useNavigate();
  const redirectToDifferentPaths = () => {
    if (userContext.rolename === "Admin") {
      navigate('/adminlanding');
    }
    if (userContext.rolename === "Analyst") {
      navigate('/dashboard/analyst');
    }
    if (userContext.rolename === "Manager") {
      navigate('/dashboard');
    }
  }
  useEffect(() => {
    redirectToDifferentPaths()
  }, [])

  return (
    <div className="BodyWithFooter">
    <div className="ContentBody">
      <Header />
     
        <div>
          {userContext.rolename == "Analyst" || userContext.rolename == "Manager" ? 
          <DashboardSkelton userContext={userContext} /> : <div className='loader_page_wrapper'><CircularProgress /></div>}
        </div>
      

      <Footer />

    </div>
    </div>
  )
}

export default Landing
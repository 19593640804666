import axios from "axios";
const jsonConfig = require("../../config.json");


const getallapprovedsupressalertbymanagerid = async (id) => {
   
    let allResponse = {}
    try {
        const Response = await  axios.get(
            jsonConfig.apiUrl + "getallapprovedsupressalertbymanagerid/"+id, 
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );
        
       const allResponse = Response;
       const ressuppress = allResponse.data.data
        console.log("allResponse",ressuppress)
        return Promise.resolve(allResponse)
    }
    catch (error) {
        return Promise.reject(error)
    }
}


export default getallapprovedsupressalertbymanagerid;
import React, { useState } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom';
import "../admin-landing/adminLanding.css";

import Header from "./../../components/Header/Header";
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import AdminHeader from "../../components/AdminHeader/AdminHeader";

const AdminLanding = () => {
  const navigate = useNavigate();
  const navigateusers = () => {

    navigate('/users');
  };
  const navigateroles = () => {
    navigate('/roles');
  };
  const navigatealerts = () => {

    navigate('/adminalerts');

  };


  return (
    <>

      <AdminHeader />

      <Container maxWidth="xl">

        <div className="section-wrapper">
          <div className="container_admin">

            <div className="admin-container">

              <p className="fs-light fs-35 f-color fs-30 ml_17 mb_20">
                Admin Navigation
              </p>


              <section className="admin-section-home-first mb_32">
                <div
                  className="page-box-element"
                  onClick={navigateusers}
                >
                  <p className="fs-semi-bold fs-20 f-color mt-10">
                    Users
                  </p>
                </div>
                <div
                  className="page-box-element"
                  onClick={navigateroles}
                >

                  <p className="fs-semi-bold fs-20 f-color mt-10">
                    Roles
                  </p>
                </div>
                <div
                  className="page-box-element"
                  onClick={navigatealerts}
                >
                  <p className="fs-semi-bold fs-20 f-color mt-10">
                   Run-Alerts
                  </p>
                </div>
              </section>
            </div>

            {/* )} */}
          </div>
        </div>


      </Container>
    </>
  )
}



export default AdminLanding;
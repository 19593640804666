import axios from "axios";
const jsonConfig = require("../../config.json");

const updateUserByIdResponse = async (editUserData) => {
   console.log('someditUserData',editUserData);
    const updatePayload={
        firstName:editUserData.firstName,
        middleName:editUserData.middleName,
        lastName:editUserData.lastName,
        email:editUserData.email,
        password: editUserData.password,
        roleId : editUserData.roleId,
        managerId : editUserData.managerId,
        updatedBy : ''

    }
    let allResponse = {}
    console.log(jsonConfig.apiUrl)
    try {
        const response = await axios.put(
            jsonConfig.apiUrl + "users/"+editUserData.id,
            JSON.stringify(updatePayload),
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              }
            }

          );
        
        allResponse = response;
        return Promise.resolve(allResponse)
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export default updateUserByIdResponse;
import axios from "axios";
const jsonConfig = require("../../config.json");

const getUserByIdResponse =async (id) => {
    // alert("ok")
    let allResponse = {}
    console.log(jsonConfig.apiUrl)
    try {
        const response = await axios.get(
            jsonConfig.apiUrl + "users/"+id,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              }
            }
          );
        
        allResponse = response;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr",error)
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default getUserByIdResponse;
import React from "react";
import { Outlet, Navigate } from 'react-router-dom';
const ProtectedRoute = (props) => {
  const { userContext } = props;
  return (
     userContext ? <Outlet userContext={userContext}/> : <Navigate to="/" />
   
  )
}

export default ProtectedRoute

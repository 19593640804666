import axios from "axios"
const jsonConfig = require("../../config.json")

const suppressAlertResponse = async (payload) => {
	let allResponse = {}
	try {
		const Response = await axios.post(
			jsonConfig.apiUrl + "suppressalert",
			payload,
			{
				headers: {
					"x-functions-key": jsonConfig.key,
				},
			}
		)

		allResponse = Response
		return Promise.resolve(allResponse)
	} catch (error) {
		return Promise.reject(error)
	}
}

export default suppressAlertResponse;

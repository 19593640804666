import axios from "axios";
const jsonConfig = require("../../config.json");

const getAllAiDecition = async () => {
  try {
    const getAllResponse = await axios.get(jsonConfig.apiUrl + "getallalertstatuses ", {
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default getAllAiDecition;

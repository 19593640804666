
export const firstRowStyles = { my: '1.5vh' };

export const buttonStyles = { color: "#000", borderColor: 'black', fontFamily: "Montserrat", textTransform: 'none', backgroundColor: "#F8A87E", fontWeight: 600, borderRadius: "6px",  };

export const firstReviewBoardStyles = { backgroundColor: "#121212", border: "1px solid #F8A87E", borderRadius: '16px', p: '5px', boxShadow: 6, color: "#fff" };

export const pendingReviewStyles = { p: '5px', borderRadius: '16px' };

export const otherReviewBoardStyles = { border: 0, border: '1px solid #1CBDCD', backgroundColor: "#121212", borderRadius: '16px', p: '5px', boxShadow: 6, color: "#fff" };

export const barchartCardStyles = { boxShadow: 2, px: '50px', py: '75px',marginTop:'20px', marginLeft:'5px', backgroundColor: '#FFF', borderRadius: '6px', color: "#000" };

export const piechartCardStyles = { boxShadow: 2, p: '10px', backgroundColor: '#FFF', borderRadius: '6px' };

export const reviewBtnStyles = { color: "#000", backgroundColor: "#F8A87E", textTransform: "none", height: "2rem", fontWeight: 600, fontFamily: "Montserrat", fontSize: "15px", boxShadow: 2, padding: 1.3,width:'80px' }
export const reviewBtnStyles_pending = { color: "#000", backgroundColor: "#F8A87E", textTransform: "none", height: "2rem", fontWeight: 600, fontFamily: "Montserrat", fontSize: "15px", boxShadow: 2, padding: 1.3,width:'80px',marginTop:'20px' }

export const selectStyles = { lineHeight: "1.4em", border: "2px solid #4D4F5C", borderRadius: '16px', fontWeight: 600, fontSize: "0.6rem", height: "2.4em", backgroundColor: "#F1F9FF" };

export const reviewGridStyles = { marginTop: -0.5, marginBottom: 0.8 };

export const formControlStyles = { minWidth: 110 };

export const dashboardHeadStyles = { fontSize: "1.7rem", fontWeight: "bold", fontFamily: "Merriweather" };

export const piechartHeadStyles = { fontSize: "14px", fontWeight: "600", fontFamily: "Merriweather" };

export const secondRowStyles = { p: '5px', mt: '2vh' };

export const thirdRowStyles = { border: 1, borderColor: '#c9cbd8', borderRadius: '16px', p: '5px', my: 5, boxShadow: 6, backgroundColor: '#F7F7F7' };

export const suppressionBtnGrid = { mr: "-15px" };

export const headerGridStyles = { mt: 2 };

export const dropDownStyle = { borderRadius: 2, width: "125px", height: "30px", fontSize: "15px", padding:"0px 10px", marginLeft:"-8px", boxShadow: "0px 2px 6px 1px rgba(0, 0, 0, 0.3)"}

export const dropDownStyleInAnalyst = { borderRadius: 2, width: "120px", height: "30px", fontSize: "15px", marginLeft:"-6px", padding:"0px 10px", boxShadow: "0px 2px 6px 1px rgba(0, 0, 0, 0.3)"}

export const headingStyle = {fontWeight: 700, fontFamily: "Montserrat", fontSize: "15px"};

export const headingStyles = {fontWeight: 700, fontFamily: "Montserrat", fontSize: "8px",color:'white'};

export const headingStylePrimary = {fontWeight: 700, fontFamily: "Montserrat", fontSize: "24px", paddingTop: "8px"};

export const headingStyleRecent = {fontWeight: 700, fontFamily: "Montserrat", fontSize: "23px",};

export const headingStyleSecondary = {fontWeight: 700, fontFamily: "Montserrat", fontSize: "14px"};
import React, { useState, useEffect } from "react";
import { Container, Grid, Avatar, Button, TextField, Typography, Divider, createTheme, ThemeProvider } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';

import Footer from "./../../components/Footer/Footer";
import AlfaLogo from "./../../resources/images/ALFA.svg";
import customLogin from "../../apis/login/customLogin";

import "./LoginPage.css";
import { avatarStyles, logoWrapStyles, loginFormStyles, bannerStyle, emailStyle, passwordStyle, formTopTextStyle, loginButtonStyles, bannerTopText, bannerBottomText, loginFormHead, textFieldStyle, logingcon } from "./LoginPageStyles";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import updateTemporaryPasswordResponse from "../../apis/login/updateTemporyPassword";
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';






const theme = createTheme({
  breakpoints: {
    values: {
      // xs: 0,
      // sm: 600,
      md: 900,
      lg: 1366,
      xl: 1921,
    },
  },
});


export default function LoginPage(props) {
  const navigate = useNavigate();
  const [loginData, setloginData] = useState({
    password: "",
    email: ""
  });
  const [isResetRequired, setisResetRequired] = useState(false);
  const [resetPasswordData, setresetPasswordData] = useState({
    password: '',
    confirmPassword: ""
  })
  const [userInfoAfterLogin, setuserInfoAfterLogin] = useState()

  const validateEmail = (email) => {
    const regX =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regX.test(email)) {
      if (
        email.includes("gmail") ||
        email.includes("outlook") ||
        email.includes("yahoo") ||
        email.includes("hotmail")
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const validatePassword = (password) => {
    const regX =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    if (regX.test(password)) {
      return true;
    } else {
      return false;
    }
  };
  const passwordChangeHandler = (e) => {
    setloginData((prev) => ({
      ...prev,
      password: e.target.value

    }))
  }
  const emailChangeHandler = (e) => {
    if (validateEmail(e.target.value)) {
      setloginData((prev) => ({
        ...prev,
        email: e.target.value

      }))
    }

  }
  // login button handler
  const handleSubmit = async (event) => {

    event.preventDefault();

    if (loginData.email !== "" && loginData.password !== "") {
      try {

        const customLoginResponse = await customLogin(loginData);

        if (!customLoginResponse.data.token) {
          setisResetRequired(true)
        }
        else {
          localStorage.setItem("alfaToken", customLoginResponse.data.token)
          props.pareentCallback(customLoginResponse.data.token)
          let userInfo = jwt_decode(customLoginResponse.data.token)
          console.log("userInfo", userInfo)
          setuserInfoAfterLogin(userInfo)
          navigate('/landing');
        }


      } catch (error) {
        return NotificationManager.error('Please enter correct email or password', "Error", 3000);
      }
    }
    else {
      return NotificationManager.error('Enter all the fields', "Error", 3000);
    }
  };

  // this function is for password validataion
  const validatePasswordHandler = (e) => {
    if (!validatePassword(resetPasswordData.password)) {
      return NotificationManager.error('Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character', "Error", 3000);
    }
  }
  const passwordResetChangeHandler = (e) => {
    setresetPasswordData((prev) => ({
      ...prev,
      password: e.target.value
    }))
  }
  const confirmPasswordResetChangeHandler = (e) => {
    setresetPasswordData((prev) => ({
      ...prev,
      confirmPassword: e.target.value
    }))
  }
  // this function is for update temp password
  const changePasswordHandler = async (e) => {
    if (resetPasswordData.password === resetPasswordData.confirmPassword) {
      let payload = {
        email: loginData.email,
        password: resetPasswordData.password
      }
      let updatetemporaypasswordResponse = await updateTemporaryPasswordResponse(payload)

      if (updatetemporaypasswordResponse.data.data.length > 0) {
        setloginData({
          password: "",
          email: ""
        })
        setisResetRequired(!isResetRequired)
        return NotificationManager.success("Password updated sucessesfully", "Success", 3000)
      }
    }
    else {
      return NotificationManager.error("Password and confirm password does not match", "Error", 3000)
    }
  }

  useEffect(() => {
    let token = localStorage.getItem("alfaToken");
    if (token) {
      props.pareentCallback(token)
      navigate('/landing');
    }
  }, [])






  return (
    <div>
      <div className="BodyWithFooterLogin">
        <div className="topExtra"></div>

        <div className="ContentBodyLogin">


          {/* Grid Box for Main Section */}
          <ThemeProvider theme={theme}>
            <Grid container>

              {/* Grid Box For Left Side - Alfa Logo */}
              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={false}
                md={6.5}
                lg={6.5}
                xl={8}

                className="cushei"
              >
                {/* color backgroundColor 2E3037 */}

                <Grid item Container direction="row"
                  justifyContent="flex-end"
                  alignItems="center" sx={bannerStyle} >
                  <Grid item justifyContent="flex-start" sx={logoWrapStyles}>
                    <img src={AlfaLogo} className="AlfaLogoBanner" alt="Alfa Logo"></img>
                  </Grid>
                  <Grid container item direction="column"
                    justifyContent="center"
                    alignItems="flex-start">
                    <Grid item sx={bannerTopText}>Automated Learning <span id="alertsText">for Financial Alerts</span></Grid>
                    <Grid item sx={bannerBottomText}>Powering the next gen of KYC AML screening with <span id="AIText">AI</span></Grid>

                  </Grid>
                </Grid>
              </Grid>


              <Divider orientation="vertical" variant="middle" flexItem />
              <Grid md={.5}>
              </Grid>




              {/* Grid Box For Right Side - Login  */}
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="right"
                md={4.9}
                lg={4.9}
                xl={3}
              >

                {/* Grid Box for Login Form */}
                < Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  sx={loginFormStyles}
                  className="logingcon"

                >
                  {
                    !isResetRequired ?
                      <Container>
                        {/* Grid Box - Login Form Header */}
                        <Grid
                          container
                          item
                          justifyContent="flex-start"
                          alignItems="center"
                          sx={loginFormHead}
                        >
                          <Avatar sx={avatarStyles}>
                            <LockIcon />
                          </Avatar>
                          Login
                        </Grid>

                        {/* Grid Box - Login Form Main Section */}
                        <Grid
                          component="form"
                          noValidate
                          onSubmit={handleSubmit}
                          sx={formTopTextStyle}
                        >

                          <Typography component="h6" variant="h6" sx={emailStyle}>
                            E-mail
                          </Typography>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            defaultValue=""
                            autoComplete="email"
                            autoFocus
                            onChange={emailChangeHandler}
                          />
                          <Typography component="h6" variant="h6" sx={passwordStyle}>
                            Password
                          </Typography>
                          <TextField

                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            value={loginData.password}
                            autoComplete="current-password"
                            onChange={passwordChangeHandler}
                          />

                          <Grid
                            container
                            item
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              fullWidth
                              size="large"
                              sx={loginButtonStyles}

                            >
                              Login
                            </Button>


                          </Grid>

                        </Grid>
                      </Container>
                      : <Container>
                        {/* Grid Box - Login Form Header */}
                        <Grid
                          container
                          item
                          justifyContent="flex-start"
                          alignItems="center"
                          sx={loginFormHead}
                        >
                          <Avatar sx={avatarStyles}>
                            <LockIcon />
                          </Avatar>

                          Change password

                        </Grid>

                        {/* Grid Box - Login Form Main Section */}
                        <Grid

                          onSubmit={handleSubmit}
                          sx={formTopTextStyle}
                        >

                          <Typography component="h6" variant="h6" sx={emailStyle}>
                            Password
                          </Typography>
                          <TextField
                            margin="normal"
                            value={resetPasswordData.password}
                            fullWidth
                            type="password"
                            onBlur={validatePasswordHandler}
                            onChange={passwordResetChangeHandler}
                          />
                          <Typography component="h6" variant="h6" sx={passwordStyle}>
                            Confirm Password
                          </Typography>
                          <TextField
                            margin="normal"

                            fullWidth
                            type="password"
                            value={resetPasswordData.confirmPassword}
                            onChange={confirmPasswordResetChangeHandler}
                          />

                          <Grid
                            container
                            item
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Button
                              variant="contained"
                              fullWidth
                              size="large"
                              sx={loginButtonStyles}
                              onClick={changePasswordHandler}
                            >
                              Change Password
                            </Button>


                          </Grid>

                        </Grid>
                      </Container>
                  }

                </Grid>
              </Grid>
            </Grid >
          </ThemeProvider>
        </div>

        <Footer />
        <NotificationContainer />

      </div >
    </div>
  );
}

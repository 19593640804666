import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, OutlinedInput } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications';
import supportMailResponse from '../../apis/supports/support';
import "./ContactUsModal.css";
const useStyles = makeStyles({
    heading: {
        fontFamily: "Source Sans Pro",
        fontSize: "45px",
        fontWeight: 600
    },
    write: {
        fontFamily: "Source Sans Pro",
        fontSize: "24px",
        color: "#519AF8",
        cursor: "pointer",
    },
    buttonForSupport: {
        justifyContent: "flex-end !important",
        marginRight:"42px",
        paddingRight: "80px",
        paddingBottom: "8px",
        paddingTop: "8px",
        
    },
    
    btnCancel: {
        
      backgroundColor: "#FFFFFF ",
      border: "none",
      border:" 1px solid #1CBDCD",
      color: "#0f1215",
      padding: "8px 20px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "16px",
      borderRadius: "3px",
      "&:hover": {
        opacity: "0.8"
      }
      },
      btnSave: {
        backgroundColor: "#1CBDCD ",
        border: "none",
        border:" 2px solid #1CBDCD",
        color: "#0f1215",
        padding: "8px 20px",
        
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "16px",
        borderRadius: "3px",
        "&:hover": {
          opacity: "0.8"
        }
      }
})

const ContactUsModal = (props) => {
    const {isModalOpen,onModalClose}=props;
 
    const [name,setName]=useState("")
    const [email,setEmail]=useState("");
    const [supportMsg,setSupportMsg]=useState("")
    const classes = useStyles();

 
    // onModalClose  = () => {
    //   this.props=""
    // }

 const mailSend=async(e)=>{
  e.preventDefault();

var i = name.indexOf(' ');
var firstName = name.slice(0, i+1).trim();
var lastName = name.slice(i + 1, name.length).trim();
if (name===""  || email==="" || supportMsg==="" ){
 return NotificationManager.error("Please enter the required fields", "Error", 3000)
}
const payload={
    "firstName":firstName,
    "lastName":lastName,
    "email":email,
    "description":supportMsg
}
const sendMailResponse=await supportMailResponse(payload);
if(sendMailResponse.status==200){

    onModalClose();
    setName('');
    setEmail('');
    setSupportMsg('');

    NotificationManager.success("Thank you for reaching out! We'll contact you shortly.", "Success", 3000)
    onModalClose()


}else{
    NotificationManager.error("Something Went Wrong", "Error", 3000)
}
      }
  return (
    <> 
    <Dialog
    open={isModalOpen}
    onClose={onModalClose}
    aria-labelledby="form-dialog-title"
    maxWidth="md"
    fullWidth={true}
  >
    <DialogTitle className='support_dialog_title'>
      <div className='support_line'>Contact Us</div>
      
    </DialogTitle>
    <DialogContent className='support_con'>
      <div className='support_header'>
        <Grid container spacing={2} className='support_grid'>
          <Grid item xs={6}><div className='support_form_lebel'>Name*</div></Grid>
          <Grid item xs={6}><div className='support_form_lebel'>Email*</div></Grid>
          <Grid item xs={6}>
            <OutlinedInput
              placeholder="Your Name"
              className="Support_textbox"
              onChange={(e)=>{setName(e.target.value)}}
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              placeholder="Your Email"
              className="Support_textbox"
              onChange={(e)=>{setEmail(e.target.value)}}
            />
          </Grid>
          <Grid item xs={12} className="support_grid_message">
            <div className='support_form_lebel'>Your Message*</div>
          </Grid>
          <Grid item xs={12}>
            <textarea
              type="text"
              placeholder='How can we help you?'
              className="support_message"
              rows={4}
              onChange={(event) => setSupportMsg(event.target.value)}
            />
          </Grid>
        </Grid>
      </div>
    </DialogContent>
    <DialogActions className={classes.buttonForSupport}>
      <button className={classes.btnCancel} onClick={onModalClose}>Cancel</button>
      <button className={classes.btnSave} onClick={mailSend}>Send</button>
    </DialogActions>
  </Dialog>
  </>
  )
}

export default ContactUsModal
import axios from "axios";

export default async function customLogin(payload) {

    const baseURL = "https://alfa-api-demo.azurewebsites.net/api/customlogin";

    let allResponse = {};
    console.log("payload", payload);

    try {

        const response = await axios.post(
            baseURL,
            payload,
            {
                headers: {
                    "x-functions-key": "5s5mA7X9pOYdt4rAdBZpCBJ0Y4vnFLsH_H70bHV4b7mzAzFuu7kjQA==",
                }
            }
        );

        allResponse = response;

        return Promise.resolve(allResponse);

    } catch (error) {

        console.log("error!!!", error)
        return Promise.reject(error)

    }



}

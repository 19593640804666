import React, { useEffect, useState } from 'react'
import getallapprovedsupressalertbymanagerid from "../../apis/ApproveSuppression/Getallapprovedsupressalertbymanagerid";
import { Grid as GridDiv, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { useNavigate } from 'react-router-dom';
import { DateTime } from "luxon";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, TextareaAutosize, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import attach_pdf from "../../components/alerts/images/attach_pdf_icon.png"
import attach_doc from "../../components/alerts/images/kindpng_1338383.png"
import attach_xlsx from "../../components/alerts/images/clipart2414601.png"
import { Attachment, Close } from '@mui/icons-material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import getAllAiDecition from '../../apis/alerts/getAllAiDecition';
import { NotificationContainer, NotificationManager, } from "react-notifications";
import unSuppressCustomer from '../../apis/unsuppress/unSuppressCustomer';
import updateAlertResponse from '../../apis/alerts/updateAlert';
import attach_close_icon from "../../components/alerts/images/attach_close_icon.png";
import { BlobServiceClient, StorageSharedKeyCredential } from "@azure/storage-blob";
import jsonConfig from '../../config.json';
import { TextArea } from '@progress/kendo-react-inputs';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    unsuppressButton: {
        textTransform: 'none !important'
    },
    alertIds: {
        display: 'inline-block',
        marginLeft: '5px',
        cursor: 'pointer',
        color: 'blue',
        '&:hover': {
            textDecoration: 'underline',
            color: 'red'
        }
    },
    dialogTitle_Unsuppress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'linear-gradient(325deg, rgba(18, 18, 18, 1) 0%, rgba(57, 57, 57, 1) 95%, rgba(100, 100, 100, 1) 100%)',
        color: 'white'
    },
    dialogKendo_unsuppress: {
        marginTop: '50px',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',

    },
    confirmationBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    attachmentsCell: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        columnGap: '4px'
    },
    unsuppressHeading: {
        color: ' #121212',
        fontSize: '25px',
        fontFamily: "Source Sans Pro",
        fontWeight: 700,
    }
})

const AlertSuppression = (props) => {
    const { userContext } = props;
    const [approvedSuppressedAlert, setApprovedSuppressedAlert] = useState([]);
    const navigate = useNavigate();
    const [unsuppressDialogOpen, setUnsuppressDialogOpen] = useState(false)
    const [unsuppressConfirmDialogOpen, setUnsuppressConfirmDialogOpen] = useState(false)
    const [suppressedAlertList, setSuppressAlertList] = useState([])
    const [customerFirstName, setCustomerFirstName] = useState("")
    const [customerLastName, setCustomerLastName] = useState("")
    const [customerName, setCustomerName] = useState("")
    const [customerId,setCustomerId] = useState("")
    const [ssnNo, setssnNo] = useState("")
    const [ssnNoChk, setssnNoChk] = useState("")
    const [aiDecision, setAIDecision] = useState([])
    const [alertDecisions, setAlertDecisions] = useState([]);
    const [customerAccountNo, setCustomerAccountNo] = useState('')
    const [selectedDocsUnsuppress, setSelectedDocUnsuppress] = useState([])
    const [commentForUnsuppress, setCommentForUnsuppress] = useState('')
    const [unsuppressDocumentUrl, setUnsuppressDocumentUrl] = useState([]);
    const [selectedAlertId, setSelectedAlertId] = useState([])
    const [notes, setNotes] = useState("");
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 25,
        filter: {
            logic: "and",
            filters: [
            ],
        },
        sort: [],
    });
    const [dataResult, setDataResult] = useState(
        process(approvedSuppressedAlert, dataState)
    );
    const [dataResult1, setDataResult1] = useState(
        process(suppressedAlertList, dataState)
    );
    const classes = useStyles();


    const getSuppressedAlertData = async () => {
        try {
            let ApprovedsupressedAlertData = await getallapprovedsupressalertbymanagerid(props.userContext.id);
            setApprovedSuppressedAlert(ApprovedsupressedAlertData.data.data)
        } catch (error) {
            console.log("Error", error)
        }

    }
    const getAllAiDecison = async () => {
        try {
            const res = await getAllAiDecition();
            setAIDecision(res.data.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getSuppressedAlertData();
        getAllAiDecison();
    }, [])

    const dataStateChange = (event) => {
        setDataResult(process(approvedSuppressedAlert, event.dataState));
        setDataState(event.dataState);
    };
    const dataStateChange1 = (event) => {
        setDataResult1(process(suppressedAlertList, event.dataState));
        setDataState(event.dataState);
    };

    useEffect(() => {
        setDataResult(process(approvedSuppressedAlert, dataState));
        let customerName = "customerName";
        let analystName = "analystName";
        let analystJSON = "analystJSON"
        let managerJSON = "managerJSON"
        let approvedBy = "approvedBy"
        let maskedAccNo = "maskedAccNo"
        let maskedSSN = "maskedSSN"
        let status = "status"
        for (let i = 0; i < approvedSuppressedAlert.length; i++) {
            let firstName_customer =  approvedSuppressedAlert[i].suppressedUserInfo.FIRST_NAME ?  approvedSuppressedAlert[i].suppressedUserInfo.FIRST_NAME : "";
            let middleName_customer =  approvedSuppressedAlert[i].suppressedUserInfo.MIDDLE_NAMES ?  approvedSuppressedAlert[i].suppressedUserInfo.MIDDLE_NAMES + " " : "";
            let lastName_customer =  approvedSuppressedAlert[i].suppressedUserInfo.LAST_NAME ?  approvedSuppressedAlert[i].suppressedUserInfo.LAST_NAME : "";
            // let firstName_customer = approvedSuppressedAlert[i].customerinformation.firstName ? approvedSuppressedAlert[i].customerinformation.firstName : "";
            // let middleName_customer = approvedSuppressedAlert[i].customerinformation.middleName ? approvedSuppressedAlert[i].customerinformation.middleName + " " : "";
            // let lastName_customer = approvedSuppressedAlert[i].customerinformation.lastName ? approvedSuppressedAlert[i].customerinformation.lastName : "";

            let firstName_analyst = approvedSuppressedAlert[i].analystdata[0].firstName ? approvedSuppressedAlert[i].analystdata[0].firstName : "";
            let middleName_analyst = approvedSuppressedAlert[i].analystdata[0].middleName ? approvedSuppressedAlert[i].analystdata[0].middleName + " " : "";
            let lastName_analyst = approvedSuppressedAlert[i].analystdata[0].lastName ? approvedSuppressedAlert[i].analystdata[0].lastName : "";

            let firstName_manager = approvedSuppressedAlert[i].managerdata[0].firstName ? approvedSuppressedAlert[i].managerdata[0].firstName : "";
            let middleName_manager = approvedSuppressedAlert[i].managerdata[0].middleName ? approvedSuppressedAlert[i].managerdata[0].middleName + " " : "";
            let lastName_manager = approvedSuppressedAlert[i].managerdata[0].lastName ? approvedSuppressedAlert[i].managerdata[0].lastName : "";

            approvedSuppressedAlert[i][customerName] = firstName_customer + " " + middleName_customer + lastName_customer;
            approvedSuppressedAlert[i][analystName] = firstName_analyst + " " + middleName_analyst + lastName_analyst;
            approvedSuppressedAlert[i][approvedBy] = firstName_manager + " " + middleName_manager + lastName_manager;
            approvedSuppressedAlert[i].toDate = DateTime.fromISO(approvedSuppressedAlert[i].toDate).toFormat('MM-dd-yyyy')
            approvedSuppressedAlert[i].fromDate = DateTime.fromISO(approvedSuppressedAlert[i].fromDate).toFormat('MM-dd-yyyy')

            let accNum = approvedSuppressedAlert[i]?.customers?.primaryaccount?.ACCOUNT_ID ? approvedSuppressedAlert[i]?.customers?.primaryaccount?.ACCOUNT_ID : "N/A";
            // let lastTwoDigits = accNum.substring((accNum.length - 2), accNum.length)
            approvedSuppressedAlert[i][maskedAccNo] = accNum;

            
            let actionsjson = approvedSuppressedAlert[i].alert.filter((item) => item.id === approvedSuppressedAlert[i].alertId)
            actionsjson = JSON.parse(actionsjson[0]?.actionsjson)

            let managerJSONdata = actionsjson.manager
            let analystJSONdata = actionsjson.analyst

            approvedSuppressedAlert[i][managerJSON] = managerJSONdata[managerJSONdata.length - 1]
            approvedSuppressedAlert[i][analystJSON] = analystJSONdata[analystJSONdata.length - 1]

            // setssnNoChk(approvedSuppressedAlert[i].customerinformation.ssnNumber)
            // let SSNNumb = approvedSuppressedAlert[i].customerinformation.ssnNumber;
            // let lastDigits = SSNNumb.substring((SSNNumb.length - 4), SSNNumb.length)
            // let mask = "XXX XX "
            // approvedSuppressedAlert[i][maskedSSN] = mask + lastDigits;

            approvedSuppressedAlert[i][status] = approvedSuppressedAlert[i].isActive === 1 ? "Suppressed" : "Unsuppressed"
        }
    }, [approvedSuppressedAlert]);

    useEffect(() => {
        let customerFullName = 'customerFullName'
        let customerSSN = 'customerSSN'
        let alertID = 'alertID'
        let finalDecision = 'finalDecision'

        setDataResult1(process(suppressedAlertList, dataState))
        for (let i = 0; i < suppressedAlertList.length; i++) {
            suppressedAlertList[i][customerFullName] = customerName;
            // suppressedAlertList[i][customerSSN] = ssnNo;
            suppressedAlertList[i][alertID] = alertIdhandler(suppressedAlertList[i].id)
            suppressedAlertList[i][finalDecision] = getFinalDecision(suppressedAlertList[i].finaldecisionID)
        }
    }, [suppressedAlertList]);

    const viewAlertHandler = (e, alertId) => {
        navigate('/alerts', {
            state: {
                id: alertId
            }
        });
    }

    const getAttachment = (dataItem, role) => {
        let attachments = [];
        if (role === 'analyst') {
            attachments = dataItem.analystJSON.attachmentUrl;
        }
        else {
            attachments = dataItem.managerJSON.attachmentUrl;
        }

        attachments = JSON.parse(attachments);
        if (attachments.length > 0) {
            return (
                <div className={classes.attachmentsCell}>
                    {attachments.map((attachmentUrl) => {
                        let attachmentUrl_ext = attachmentUrl.substring(attachmentUrl.length - 6, attachmentUrl.length)
                        attachmentUrl_ext = attachmentUrl_ext.split('.')[1]

                        return (
                            <>
                                {attachmentUrl_ext === 'pdf' ? <>
                                    <img className="attachment_icon" src={attach_pdf}
                                        onClick={(el) => viewDoc(el, attachmentUrl)}
                                    />
                                </> : <>
                                    {attachmentUrl_ext === 'docx' || attachmentUrl_ext === 'doc' ? <>
                                        <img className="attachment_icon" src={attach_doc}
                                            onClick={(el) => viewDoc(el, attachmentUrl)}
                                        />
                                    </> : <>
                                        <img className="attachment_icon" src={attach_xlsx}
                                            onClick={(el) => viewDoc(el, attachmentUrl)}
                                        />
                                    </>}
                                </>}
                            </>
                        );

                    })}
                </div>
            )
        }
        else
            return ("N/A")
    }

    const viewDoc = (event, url) => {
        window.open(url, "_blank");
    }

    const alertIdhandler = (alertID) => {
        return (alertID.substring(0, 4))
    }

    const getAlertIds = (event) => {

        let suppressedAlerts = event.dataItem.alert;
        return (<>
            <td>
                {suppressedAlerts.map((item, index) => {
                    return (<div className={classes.alertIds} onClick={(e) => viewAlertHandler(e, item.id)}>{alertIdhandler(item.id)}{index + 1 === suppressedAlerts.length ? '' : ','}</div>)
                })}
            </td>
        </>)
    }

    const UnsuppressCustomer = () => {
        setUnsuppressConfirmDialogOpen(true);
    }

    const unSuppressDialogOpen = (e, event) => {
        let customerData = event.dataItem;
        console.log("custoDta",customerData)
        setSuppressAlertList(customerData.alert)
        let firstName_customer =  customerData.suppressedUserInfo.FIRST_NAME ?  customerData.suppressedUserInfo.FIRST_NAME : "";
        let middleName_customer =  customerData.suppressedUserInfo.MIDDLE_NAMES ?  customerData.suppressedUserInfo.MIDDLE_NAMES + " " : "";
        let lastName_customer =  customerData.suppressedUserInfo.LAST_NAME ?  customerData.suppressedUserInfo.LAST_NAME : ''
        // let firstName_customer = customerData.customerinformation.firstName ? customerData.customerinformation.firstName : "";
        // let middleName_customer = customerData.customerinformation.middleName ? customerData.customerinformation.middleName + " " : "";
        // let lastName_customer = customerData.customerinformation.lastName ? customerData.customerinformation.lastName : "";
        setCustomerFirstName(firstName_customer)
        setCustomerLastName(lastName_customer)
        setCustomerName(firstName_customer + " " + middleName_customer + lastName_customer)
        setCustomerAccountNo(customerData?.customers?.primaryaccount?.ACCOUNT_ID ? customerData?.customers?.primaryaccount?.ACCOUNT_ID : "N/A")
        setCustomerId(customerData.customerId)

        // let SSNNumb = customerData.customerinformation.ssnNumber;
        // let lastDigits = SSNNumb.substring((SSNNumb.length - 4), SSNNumb.length)
        // let mask = "XXX XX "
        // setssnNo(mask + lastDigits)

        setSelectedAlertId(customerData.alertId)

        let alertWithDecisions = customerData.alert;
        let decisionArr = [];
        alertWithDecisions.map((item) => {
            let actionjsonData = JSON.parse(item.actionsjson);
            let comments = "";
            let arr = {
                alertId: item.id,
                finaldecisionID:item.finaldecisionID,
                comments: "",
                attachmentUrl: []
            }
            decisionArr.push(arr);
        })
        setAlertDecisions(decisionArr);
        setUnsuppressDialogOpen(true)
    }

    const getFinalDecision = (finaldecisionID) => {
        let finalDecision = aiDecision.filter((item) => item.id === finaldecisionID)
        return (finalDecision[0].name)
    }

    const finalDecisionChangeHandler = (e, value, alertID) => {
        let decisionArr = alertDecisions.map((item) => {
            if (item.alertId === alertID) {
                return ({ ...item, finaldecisionID: value.id })
            }
            else return item;
        })
        setAlertDecisions(decisionArr)

    }

    const getAlertAttachments = (event) => {
        let attachments = alertDecisions.filter((item) => item.alertId === event.dataItem.id)
        attachments = attachments[0].attachmentUrl
        return (<>
            <td>
                <input accept=".pdf,.docx,.doc" multiple="multiple" id={`decisionChange+${event.dataItem.id}`}
                    type="file" style={{ display: 'none' }} onChange={(e) => fileSelectHandler_decisionChange(e, event.dataItem.id, attachments)} />
                <div className="supress_attach_section">
                    <label htmlFor={`decisionChange+${event.dataItem.id}`} className="labelattach">
                        <Attachment className="attachmentIcon" />
                    </label>
                    {
                        attachments?.map((e) => e.name?.split(".")[1] === "docx" ?
                            <div className="attach_image_wrapper">
                                <button className="attach_close_icon" type="button" onClick={(el) => removeHandler_decisionChange(e, event.dataItem.id, attachments)}>
                                    <img className="close_icon" src={attach_close_icon} />
                                </button>
                                <img className="attach_icon" src={attach_doc}
                                    onClick={(el) => viewDoc(el, e.url)} />
                            </div>

                            :
                            e.name?.split(".")[1] === "pdf" ?
                                <div className="attach_image_wrapper">
                                    <button className="attach_close_icon" type="button" onClick={(el) => removeHandler_decisionChange(e, event.dataItem.id, attachments)}>
                                        <img className="close_icon" src={attach_close_icon} />
                                    </button>
                                    <img className="attach_icon" src={attach_pdf} onClick={(el) => viewDoc(el, e.url)} />
                                </div>
                                :
                                <div className="attach_image_wrapper">
                                    <button className="attach_close_icon" type="button" onClick={(el) => removeHandler_decisionChange(e, event.dataItem.id, attachments)}>
                                        <img className="close_icon" src={attach_close_icon} />
                                    </button>
                                    <img className="attach_icon" src={attach_xlsx} onClick={(el) => viewDoc(e, e.url)} />
                                </div>
                        )
                    }
                </div>
            </td>
        </>)
    }
    const fileSelectHandler_decisionChange = (e, alertId, attachments) => {
        if (attachments.length < 5) {
            let arr = []
            const selectedFiles = e.target.files;
            for (let i = 0; i < selectedFiles.length; i++) {

                let obj = {}
                const downloadedUrl = URL.createObjectURL(selectedFiles[i]);
                obj.url = downloadedUrl
                obj.name = selectedFiles[i].name
                obj.file = selectedFiles[i]
                attachments.push(obj)

            }
            let decisionArr = alertDecisions.map((item) => {
                if (item.alertId === alertId) {
                    return ({ ...item, attachmentUrl: attachments })
                }
                else return item;
            })
            setAlertDecisions(decisionArr)
        }
        else {
            NotificationManager.error("You can add a maximum of 5 supporting documents", "Error", 3000)
        }
    }
    const removeHandler_decisionChange = (e, alertId, attachments) => {
        attachments = attachments.filter((item) => item.url !== e.url)
        let decisionArr = alertDecisions.map((item) => {
            if (item.alertId === alertId) {
                return ({ ...item, attachmentUrl: attachments })
            }
            else return item;
        })
        setAlertDecisions(decisionArr)
    }

    const getAlertID_unsuppress = (event) => {
        let alertId = event.dataItem.id
        return (<td>
            <span className={classes.alertIds} onClick={(e) => viewAlertHandler(e, alertId)}>{alertIdhandler(alertId)}</span>
        </td>)
    }

    const UnsuppressCustomerConfirm = async () => {
        if (commentForUnsuppress === '') {
            NotificationManager.error("Description cannot be blank", "error", 3000);
        }
        else if (selectedDocsUnsuppress.length === 0) {
            NotificationManager.error("Attachments are mandatory", "error", 3000);
        }
        else {
            try {
                for (let i = 0; i < selectedDocsUnsuppress.length; i++) {
                    await uploadToBlobStorage(selectedDocsUnsuppress[i])
                }
                let payload = {
                    customerId: customerId,
                    customerFirstName: customerFirstName,
                    customerLastName: customerLastName,
                    // ssnNo: ssnNoChk,
                    managerAttachmentUrl: JSON.stringify(unsuppressDocumentUrl),
                    managerComments: commentForUnsuppress,
                    updatedBy:userContext.id
                }
                let res = await unSuppressCustomer(selectedAlertId, payload);
                setUnsuppressConfirmDialogOpen(false)
                setUnsuppressDialogOpen(false)
                NotificationManager.success("Unsuppressed Successfully", "Success", 3000);
                getSuppressedAlertData();
            } catch (error) {
                console.log("error");
            }

        }

    }

    const alertDecisionsUpdateHandler = async (event) => {

        try {
            let alertIdforUpdate = event.dataItem.id;
            let finaldecisionsforUpdate = alertDecisions.filter((item) => item.alertId === alertIdforUpdate)
            let attachments = finaldecisionsforUpdate[0].attachmentUrl
            if(finaldecisionsforUpdate[0].comments == ""){
                NotificationManager.error("Description cannot be blank", "Error", 3000);
            }
            else if (attachments.length > 0) {
                for (let i = 0; i < attachments.length; i++) {
                    await uploadToBlobStorage(attachments[i])
                }
                let payload = {
                    finaldecisionID: finaldecisionsforUpdate[0].finaldecisionID,
                    managerComments: finaldecisionsforUpdate[0].comments,
                    managerAttachmentUrl: JSON.stringify(unsuppressDocumentUrl),
                    description: null,
                    updatedBy:userContext.id
                }
                let res = await updateAlertResponse(alertIdforUpdate, payload)
                setUnsuppressDialogOpen(false);
                getSuppressedAlertData();
                NotificationManager.success("Alert Updated Successfully", "Success", 3000);
            }
            else {
                NotificationManager.error("Attachments are mandatory", "Error", 3000);
            }

        } catch (error) {
            console.log(error)
        }
    }

    const fileSelectHandler = (e, role) => {
        let arr = []
        const selectedFiles = e.target.files;
        for (let i = 0; i < selectedFiles.length; i++) {

            let obj = {}
            const downloadedUrl = URL.createObjectURL(selectedFiles[i]);
            obj.url = downloadedUrl
            obj.name = selectedFiles[i].name
            obj.file = selectedFiles[i]
            arr.push(obj)
        }

        setSelectedDocUnsuppress((prevValue) => {
            if (prevValue.length >= 5) {
                NotificationManager.error("You can add a maximum of 5 supporting documents", "Error", 3000)
                return [...prevValue];
            }
            return [...prevValue, ...arr]
        })
        e.target.value = null;
    }

    const removeHandler = (e) => {
        let filterArr = selectedDocsUnsuppress.filter((item) => item.url !== e.url)
        setSelectedDocUnsuppress(filterArr)
    }

    const unsuppressTextChange = (e) => {
        setCommentForUnsuppress(e.target.value)
    }

    const unsuppressDialogClose = () => {
        setSelectedDocUnsuppress([])
        setCommentForUnsuppress('')
        setUnsuppressConfirmDialogOpen(false)
    }

    const uploadToBlobStorage = async (blob) => {
        const blobStorageAccount = jsonConfig.blobStorageAccount;//azure storage account name
        const sas = jsonConfig.sasToken  //sas token for authentication
        const bsClient = new BlobServiceClient(
            `https://${blobStorageAccount}.blob.core.windows.net/?${sas}`
        );
        const containerClient = bsClient.getContainerClient("attachment");//container
        const blobName = userContext.id + '/' + blob?.name;
        const url = 'https://' + blobStorageAccount + '.blob.core.windows.net' + '/attachment/' + blobName;


        const blobClient = containerClient.getBlockBlobClient(blobName);
        const data = await blobClient.uploadBrowserData(blob.file);
        unsuppressDocumentUrl.push(url)


    }

    const getAlertComment = (event) => {
        return (
            <td>
                <textarea
                    id={event.dataItem.id}
                    rows={5}
                    cols={34}
                    defaultValue={getManagerComment(event)}
                    placeholder="Enter comment here"
                    onBlur={(e)=>setCommentValue(e,event.dataItem)}
                />
            </td>
        )
    }

    const getManagerComment = (event) => {
        let commentValue = alertDecisions.filter((item) => item.alertId === event.dataItem.id)
        return (commentValue[0].comments)
    }

    const setCommentValue = (e,data) => {
        let value = e.target.value
     let decisionArr = alertDecisions.map((item) => {
            if (item.alertId === data.id) {
                return ({ ...item, comments: value })
            }
            else return item;
        })
        setAlertDecisions(decisionArr)
    }

    return (
        <>
            <GridDiv
                filter={dataState.filter}
                sort={dataState.sort}
                sortable={true}
                filterable={true}
                pageable={{
                    pageSizes: [5, 10, 20, 25, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount: 10
                }}
                resizable={true}
                skip={dataState.skip}
                take={dataState.take}
                data={dataResult}
                onDataStateChange={dataStateChange}
            >
                <GridColumn
                    field=""
                    title="Analyst Attachement"
                    filterable={false}
                    cell={(event) => {
                        return (<td>{getAttachment(event.dataItem, 'analyst')}</td>)
                    }}
                    width="160px"
                />
                <GridColumn
                    field=""
                    title="Manager Attachement"
                    filterable={false}
                    cell={(event) => {
                        return (<td>{getAttachment(event.dataItem, 'manager')}</td>)
                    }}
                    width="160px"
                />
                <GridColumn
                    field="maskedAccNo"
                    title="Account No"
                    width="150px"
                    filterable={true}
                />
                {/* <GridColumn
                    field="maskedSSN"
                    title="SSN No"
                    width="150px"
                    filterable={true}
                /> */}
                <GridColumn
                    field="customerName"
                    title="Customer Name"
                    width="150px"
                    filterable={true}
                />
                <GridColumn
                    field="alertID"
                    title="Alert IDs"
                    width="200px"
                    cell={(event) => {
                        return (getAlertIds(event))
                    }}
                    filterable={true}
                />
                <GridColumn
                    field="analystName"
                    title="Analyst Name"
                    width="150px"
                    filterable={true}
                />
                <GridColumn
                    field="approvedBy"
                    title="Approved By"
                    width="150px"
                    filterable={true}
                />
                <GridColumn
                    field="fromDate"
                    title="From Date"
                    filterable={true}
                    width="150px"
                />

                <GridColumn
                    field="toDate"
                    title="To Date"
                    filterable={true}
                    width="150px"
                />
                <GridColumn
                    field="analystJSON.comment"
                    title="Analyst Comments"
                    filterable={true}
                    width="200px"
                />
                <GridColumn
                    field="managerJSON.comment"
                    title="Manager Comments"
                    filterable={true}
                    width="200px"
                />
                <GridColumn
                    field="status"
                    title="Status"
                    filterable={true}
                    width="200px"
                />

                <GridColumn
                    field="managerComments"
                    title="Action"
                    filterable={false}
                    cell={(event) => {
                        return (
                            <>
                                <td>
                                    <Button className={classes.unsuppressButton} disabled={event.dataItem.status === 'Suppressed' ? false : true} variant="contained" size="small" color='error' onClick={(e) => unSuppressDialogOpen(e, event)} >Unsuppress </Button>
                                </td>
                            </>
                        );
                    }}
                    width="150px"
                />
            </GridDiv>
            <Dialog
                fullScreen
                open={unsuppressDialogOpen}
                onClose={(e) => { setUnsuppressDialogOpen(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}

            >
                <DialogTitle className={classes.dialogTitle_Unsuppress}>
                    <Typography>Unsuppress User</Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={(e) => { setUnsuppressDialogOpen(false) }}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <GridDiv
                        filter={dataState.filter}
                        sort={dataState.sort}
                        sortable={true}
                        filterable={true}
                        pageable={{
                            pageSizes: [5, 10, 20, 25, 50, 100],
                            info: true,
                            previousNext: true,
                            buttonCount: 10
                        }}
                        resizable={true}
                        skip={dataState.skip}
                        take={dataState.take}
                        data={dataResult1}
                        onDataStateChange={dataStateChange1}
                        className='dialogKendo_unsuppress'
                    >
                        <GridColumn
                            title='Customer Name'
                            field='customerFullName'
                            filterable={true}
                            width='250px'
                        />
                        {/* <GridColumn
                            title='SSN No'
                            field='customerSSN'
                            filterable={true}
                            width='180px'
                        /> */}
                        <GridColumn
                            title='Alert ID'
                            filterable={true}
                            width='180px'
                            cell={(event) => {
                                return (getAlertID_unsuppress(event))
                            }}
                        />
                        <GridColumn
                            title='Final Decision'
                            field='finalDecision'
                            // width='180px'
                            filterable={true}
                        />
                        <GridColumn
                            title='Notes'
                            width='320px'
                            filterable={false}
                            editable={true}
                            cell={(event) => {
                                return (getAlertComment(event))
                            }}
                        />
                        <GridColumn
                            title='Attachments'
                            filterable={false}
                            width='300px'
                            cell={(event) => {
                                return (getAlertAttachments(event))
                            }}
                        />
                        <GridColumn
                            title='Actions'
                            filterable={false}
                            width='140px'
                            cell={(event) => {
                                return (
                                    <>
                                        <td>
                                            <Button variant="contained" size="small" color='primary' onClick={(e) => { alertDecisionsUpdateHandler(event) }}> Save </Button>
                                        </td>
                                    </>
                                );
                            }}
                        />
                    </GridDiv>
                </DialogContent>

                <DialogActions>
                    <Button variant='contained' color='error' onClick={UnsuppressCustomer}>Unsuppress</Button>
                    <Button variant='contained' color='primary' onClick={(e) => { setUnsuppressDialogOpen(false) }}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={unsuppressConfirmDialogOpen}
                onClose={unsuppressDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        width: "500px",
                        height: "350px"
                    }
                }}
                className={classes.confirmationBox}
            >
                <DialogTitle>
                    <span className={classes.unsuppressHeading}>Unsuppress the user ?</span>
                </DialogTitle>
                <DialogContent>
                    <div className="textContainer supress_text_area">
                        <div className="supress_document_section">
                            <div className="textHeader_supress">DOCUMENTATION</div>
                            <div className="file_attach">
                                <div className="attach_text">Attachments</div>
                                <input accept=".pdf,.docx,.doc" multiple="multiple" id="unsuppressDoc"
                                    type="file" style={{ display: 'none' }} onChange={(e) => fileSelectHandler(e, 'a')} />
                                <div className="supress_attach_section">
                                    <label htmlFor="unsuppressDoc" className="labelattach">
                                        <Attachment className="attachmentIcon" />
                                    </label>
                                    {
                                        selectedDocsUnsuppress?.map((e) => e.name?.split(".")[1] === "docx" ?
                                            <div className="attach_image_wrapper">
                                                <button className="attach_close_icon" type="button" onClick={(el) => removeHandler(e)}>
                                                    <img className="close_icon" src={attach_close_icon} />
                                                </button>
                                                <img className="attach_icon" src={attach_doc}
                                                    onClick={(el) => viewDoc(el, e.url)} />
                                            </div>

                                            :
                                            e.name?.split(".")[1] === "pdf" ?
                                                <div className="attach_image_wrapper">
                                                    <button className="attach_close_icon" type="button" onClick={(el) => removeHandler(e)}>
                                                        <img className="close_icon" src={attach_close_icon} />
                                                    </button>
                                                    <img className="attach_icon" src={attach_pdf} onClick={(el) => viewDoc(el, e.url)} />
                                                </div>
                                                :
                                                <div className="attach_image_wrapper">
                                                    <button className="attach_close_icon" type="button" onClick={(el) => removeHandler(e)}>
                                                        <img className="close_icon" src={attach_close_icon} />
                                                    </button>
                                                    <img className="attach_icon" src={attach_xlsx} onClick={(el) => viewDoc(e, e.url)} />
                                                </div>
                                        )
                                    }


                                </div>
                            </div>
                        </div>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            onChange={(e) => unsuppressTextChange(e)}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='error' onClick={UnsuppressCustomerConfirm}>Yes</Button>
                    <Button variant='contained' color='primary' onClick={unsuppressDialogClose}>NO</Button>
                </DialogActions>
            </Dialog>
            <NotificationContainer />
        </>
    )
}

export default AlertSuppression
import axios from "axios";
const jsonConfig = require("../../config.json");

const updateRoleByIdResponse =async (editRoleData) => {
   
    const updatePayload={
        roleName:editRoleData.name,
        code:editRoleData.code
    }
    let allResponse = {}
    console.log(jsonConfig.apiUrl)
    try {
        const response = await axios.put(
            jsonConfig.apiUrl + "roles/"+editRoleData.id,
           JSON.stringify(updatePayload),
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              }
            }

          );
        
        allResponse = response;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr",error)
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default updateRoleByIdResponse;
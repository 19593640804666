import React, { createContext, useEffect, useState } from "react";
import getBarChartData from "../apis/chartApi/getBarChartData";
import getModelChartData from "../apis/chartApi/getModelChartData";
import getPieChartData from "../apis/chartApi/getPieChartData";
import { dayChartOptions } from '../resources/dummyData/dayChartOptions';


const getBarChartDataFunction = async () => {
    try {
        const allbarchardataResponse = await getBarChartData();
        const allbarchardata = allbarchardataResponse.data.data;

        let falsePositiveArray = []
        let trueMatchArray = []
        let quarterCategories = []
        let pendingReviewsArray = []

        for (let i = 0; i < allbarchardata.length; i++) {
            quarterCategories.push(`Q${allbarchardata[i].QUARTER} (${allbarchardata[i].year})`)
            falsePositiveArray.push(allbarchardata[i].data.find((item) => item.alertstatus.code == 'FalsePositive').alertnumber);
            trueMatchArray.push(allbarchardata[i].data.find((item) => item.alertstatus.code == 'TrueMatch').alertnumber);
            pendingReviewsArray.push(allbarchardata[i].data.find((item) => item.alertstatus.code == 'PendingManualReview').alertnumber);
        }


        //formating according to barchart options props

        const dayChartOptionsNew = dayChartOptions;
        dayChartOptionsNew.xaxis.categories = quarterCategories;


        //formating the data according to barchar series props
        let fpData = {};
        fpData.name = "False Positive";
        fpData.data = falsePositiveArray;
        fpData.color = "#02bc77"
        let tmData = {};
        tmData.name = "True Match";
        tmData.data = trueMatchArray;
        tmData.color = "#ff2366"
        let pmrData = {}
        pmrData.name = "Pending Review";
        pmrData.data = pendingReviewsArray;
        pmrData.color = "#ffd950"

        let barChartData = [];
        barChartData.push(pmrData);
        barChartData.push(fpData);
        barChartData.push(tmData);

        return { barChartData, dayChartOptionsNew };
    } catch (error) {
        console.log("Error", error) 
    }

}
const getPieChartDataFunction = async (payload) => {
    try {
        const getPieChartReponse = await getPieChartData(payload);
        const getPieChartDataValue = getPieChartReponse.data.data;
        let pieChartData = []
        if (getPieChartDataValue.length > 0) {
            const falsePostiveData = getPieChartDataValue.find((item) => item.alertstatus[0].code === 'FalsePositive');
            const trueMatchData = getPieChartDataValue.find((item) => item.alertstatus[0].code === 'TrueMatch');
            const pendingReviewData = getPieChartDataValue.find((item) => item.alertstatus[0].code === 'PendingManualReview');
            falsePostiveData ? pieChartData.push(falsePostiveData.totalalertindate) : pieChartData.push(0);
            trueMatchData ? pieChartData.push(trueMatchData.totalalertindate) : pieChartData.push(0);
            pendingReviewData ? pieChartData.push(pendingReviewData.totalalertindate) : pieChartData.push(0);
        } else {
            pieChartData.push(0)
            pieChartData.push(0)
        }
        return pieChartData;
    } catch (error) {
        console.log("Error", error)
    }
}
const getModelChartDataFunction = async (payload) => {
    try {
        const getModelChartReponse = await getModelChartData(payload);
        const ModelChartDataValue = getModelChartReponse.data.data;
        let modelPieChartData = []
        modelPieChartData.push(ModelChartDataValue)
        modelPieChartData.push(100 - ModelChartDataValue)
        return modelPieChartData;
    } catch (error) {
        console.log("Error", error)
    }

}
export { getBarChartDataFunction, getPieChartDataFunction, getModelChartDataFunction }

import axios from "axios";
const jsonConfig = require("../../config.json");

const  getPieChartData = async (payload) =>{
    try{
    const getAllResponse = await axios.post(jsonConfig.apiUrl + "alertpiechat",payload, {
           headers: {
             "x-functions-key": jsonConfig.key,
          },
        });
  

        return Promise.resolve(getAllResponse)

    }catch (error) {
       return Promise.reject(error)
    }
  }
export default  getPieChartData;
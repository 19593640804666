import axios from "axios";
const jsonConfig = require("../../config.json");


const getAllAlertByFinalStatusId = async (id) => {
    console.log("iddddd",id)
    let allResponse = {}
    try {
        const Response = await  axios.get(
            jsonConfig.apiUrl + "alertbyfinalstatusid/"+id, 
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );
        
        allResponse = Response;
        console.log("allResponse",allResponse)
        return Promise.resolve(allResponse)
    }
    catch (error) {
        return Promise.reject(error)
    }
}


export default getAllAlertByFinalStatusId;
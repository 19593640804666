import axios from "axios";
const jsonConfig = require("../../config.json");

const getAllRulesResponse =async () => {
    // alert("ok")
    let allResponse = {}
    console.log(jsonConfig.apiUrl)
    try {
        const rulesresponse = await axios.get(
            jsonConfig.apiUrl + "rules",
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              }
            }
          );
        
        allResponse = rulesresponse;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr",error)
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default getAllRulesResponse;
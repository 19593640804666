import React, { useState, useEffect } from "react";
import {
    Button,
    Container,
    FormControl,
    OutlinedInput,
    Dialog,
    DialogTitle,
    IconButton,
    Grid,
    DialogContent,
    TextField,
    Typography,
    DialogActions,
    Autocomplete
} from '@mui/material';
import {
    Edit as EditIcon,
    View as ViewIcon,
    Close as CloseIcon,
    Delete,
    Edit,
} from '@mui/icons-material';
import getAllsupressalertbymanagerId from "../../apis/ApproveSuppression/ApproveSuppression";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '@progress/kendo-theme-default/dist/all.css';
import { process } from "@progress/kendo-data-query";
import { Grid as GridDiv, GridColumn } from "@progress/kendo-react-grid";
import { useNavigate } from "react-router-dom";
import Header from "./../../components/Header/Header";
import "./ApproveSuppression.css"
import backgroundPic from "./../../resources/images/Background.svg"
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";
import AlertSuppression from "./AlertSuppression";
import Allqueues from "./Allqueues";

const useStyles = makeStyles({
    pageContainer: {
        backgroundImage: `url(${backgroundPic})`,
        backgroundSize: "cover",
        height: '100vh'
    },
})

const Approvesuppression = (props) => {

    const { userContext } = props;
    const navigate = useNavigate();
    const [allsupressalertData, setallsupressalertData] = useState([]);
    const [activeTab, setActiveTab] = useState("Approve Suppression Request")
    const [approvedSuppressedAlert, setApprovedSuppressedAlert] = useState([]);
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 25,
        filter: {
            logic: "and",
            filters: [
            ],
        },
        sort: [],
    });

    const classes = useStyles();
    const [dataResult, setDataResult] = useState(
        process(allsupressalertData, dataState)
    );

    const getsupressalertData = async () => {
        try {
            let AllsupressalertData = await getAllsupressalertbymanagerId(props.userContext.id);
            const result = AllsupressalertData.data.data.filter(obj => {
                return obj.isApproved === null && obj.isSuppressed
            })
            setallsupressalertData(result);

        } catch (error) {
            console.log(error)
        }

    };

    const dataStateChange = (event) => {
        setDataResult(process(allsupressalertData, event.dataState));
        setDataState(event.dataState);
    };
    useEffect(() => {
        setDataResult(process(allsupressalertData, dataState));
        let customerName = "customerName";
        let analystName = "analystName";
        for (let i = 0; i < allsupressalertData.length; i++) {
            let firstName_customer = allsupressalertData[i].suppressedUserInfo.FIRST_NAME ? allsupressalertData[i].suppressedUserInfo.FIRST_NAME : "";
            let middleName_customer = allsupressalertData[i].suppressedUserInfo.MIDDLE_NAMES ? allsupressalertData[i].suppressedUserInfo.MIDDLE_NAMES + " " : "";
            let lastName_customer = allsupressalertData[i].suppressedUserInfo.LAST_NAME ? allsupressalertData[i].suppressedUserInfo.LAST_NAME : "";

            //     let firstName_analyst = allsupressalertData[i].analystdata.firstName ? allsupressalertData[i].analystdata.firstName : "";
            //     let middleName_analyst = allsupressalertData[i].analystdata.middleName ? allsupressalertData[i].analystdata.middleName + " " : "";
            //     let lastName_analyst = allsupressalertData[i].analystdata.lastName ? allsupressalertData[i].analystdata.lastName : "";
            let actionsjson = allsupressalertData[i].alert.actionsjson;
            actionsjson = JSON.parse(actionsjson)
            let actionsjson_analyst = actionsjson.analyst;
            let latest_analystComment = actionsjson_analyst[actionsjson_analyst.length - 1]


            allsupressalertData[i][customerName] = firstName_customer + " " + middleName_customer + lastName_customer;

            allsupressalertData[i][analystName] = latest_analystComment.updatedBy
            allsupressalertData[i].toDate = DateTime.fromISO(allsupressalertData[i].toDate).toFormat('MM-dd-yyyy')
            allsupressalertData[i].fromDate = DateTime.fromISO(allsupressalertData[i].fromDate).toFormat('MM-dd-yyyy')
        }
    }, [allsupressalertData]);



    useEffect(() => {
        getsupressalertData();
    }, []);

    const viewAlertHandler = (e, event) => {
        navigate('/alerts', {
            state: {
                id: event.dataItem.alertId
            }
        });
    }

    const tabClicked = (name) => {
        setActiveTab(name);
    };

    const getAnalystComment = (event) => {
        let actionsjson = event.dataItem.alert.actionsjson;
        actionsjson = JSON.parse(actionsjson)
        let actionsjson_analyst = actionsjson.analyst;
        let latest_analystComment = actionsjson_analyst[actionsjson_analyst.length - 1]
        return (latest_analystComment.comment)
    }
    const goback = () => {
    navigate('/dashboard', {})
    };
    return (

        <div className={classes.pageContainer}>
            <Header />
            <Container className="kendo_wrapper">
                <div className='user_header'>
                    <p className=" kendo_title">Approve Suppression Alert</p>
                </div>
                <div className="mb5p" >
                    <span className="cp" onClick={goback} ><ArrowBackIcon /></span>
                </div>
                <div className="tabHeader_container">
                    <div className={`${activeTab === 'All Queues' ? 'tabOptions_active' : 'tabOptions'}`} onClick={() => tabClicked("All Queues")}>
                        All Queues
                    </div>
                    <div className={`${activeTab === 'Approve Suppression Request' ? 'tabOptions_active' : 'tabOptions'}`} onClick={() => tabClicked("Approve Suppression Request")}>
                        Approve Suppression Request
                    </div>
                    <div className={`${activeTab === 'Suppressed Alerts' ? 'tabOptions_active' : 'tabOptions'}`} onClick={() => tabClicked("Suppressed Alerts")}>
                        Customer Suppression
                    </div>
                </div>
                <div className='user_table'>
                    {activeTab === 'Approve Suppression Request' ?
                        <GridDiv
                            filter={dataState.filter}
                            sort={dataState.sort}
                            sortable={true}
                            filterable={true}
                            pageable={{
                                pageSizes: [5, 10, 20, 25, 50, 100],
                                info: true,
                                previousNext: true,
                                buttonCount: 10
                            }}
                            resizable={true}
                            skip={dataState.skip}
                            take={dataState.take}
                            data={dataResult}
                            onDataStateChange={dataStateChange}
                        >
                            <GridColumn
                                field=""
                                title="Action"
                                filterable={false}
                                cell={(event) => {
                                    return (
                                        <>
                                            <td>
                                                <Button className='tab_inner_box_button_kendo' label="View Rule" variant="contained" size="small" onClick={(e) => viewAlertHandler(e, event)} >View </Button>
                                            </td>
                                        </>
                                    );
                                }}
                                width="100"
                            />
                            <GridColumn
                                field="customerName"
                                title="Customer Name"
                                filterable={true}
                            // width="150"
                            />
                            <GridColumn
                                field="analystName"
                                title="Analyst Name"
                                filterable={true}
                            // width="150"
                            />

                            <GridColumn
                                field="fromDate"
                                title="From Date"
                                filterable={true}
                            // width="150"
                            />

                            <GridColumn
                                field="toDate"
                                title="To Date"
                                filterable={true}
                            // width="150"
                            />

                            <GridColumn
                                title="Analyst Comment"
                                field="analystComments"
                                filterable={true}
                                // width="200"
                                cell={(event) => {
                                    return (
                                        <td>
                                            {getAnalystComment(event)}
                                        </td>
                                    );
                                }}
                            />

                            <GridColumn
                                title="Is Approved"
                                field="isApproved"
                                width="150"
                                filterable={true}
                                filter={"boolean"}
                                cell={(event) => {
                                    return (
                                        <td>
                                            {event.dataItem?.isApproved ? "True" : "False"}
                                        </td>
                                    );
                                }}
                            />
                        </GridDiv>
                        :
                        activeTab === "All Queues" ? <Allqueues userContext={userContext} />
                            : <AlertSuppression userContext={userContext} />
                    }

                </div>

            </Container>

        </div>
    )
}
export default Approvesuppression;

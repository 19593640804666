import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Header from "./../../components/Header/Header";
import Alertkendo from "../../components/alertkendo/alertkendo";
import { useNavigate,useLocation } from 'react-router-dom';
import backgroundPic from "./../../resources/images/Background.svg"

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  
  pageContainer: {
      backgroundImage: `url(${backgroundPic})`
  }
})

const Alertkendos = (props) => {
  const location=useLocation();
  const classes = useStyles();

  return (

    <>
<div className={classes.pageContainer}>
  <div className="BodyWithFooter">
                <div className="ContentBody">
      <Header />

      <Container maxWidth="lg">
        <Box sx={{ bgcolor: '#eef1f3' }} >
          <Alertkendo props={{statusId:location?.state?.id, description:location?.state?.description}}/>
        </Box>
      </Container>
      </div>
      </div>
      </div>

    </>
  )


};

export default Alertkendos;

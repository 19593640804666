import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Users from "./pages/users/Users";
import Roles from "./pages/roles/Roles";
import Adminlanding from "./pages/admin-landing/adminlanding";
import LoginPage from "./../src/pages/LoginPage/LoginPage";
import Dashboard from "./../src/pages/Dashboard/Dashboard";
import DashboardAnalyst from "./../src/pages/DashboardAnalyst/DashboardAnalyst"
import RulesPage from "./../src/pages/RulesPage/RulesPage";
import AdminAlerts from "./../src/pages/AdminAlerts/AdminAlerts";
import SearchPage from "./../src/pages/SearchPage/SearchPage";
import { DateTime } from "luxon";
import jwt_decode from "jwt-decode";
import Landing from "./pages/landing/landing";
import ProtectedRoute from "./protectedRoute";
import Alerts from "./pages/alerts/alerts";
import Alertkendo from "./pages/alertkendo/alertkendo";
import Approvesuppression from "./pages/ApproveSuppression/ApproveSuppression";

const AppRoute = () => {
  const [userContext, setuserContext] = useState(null);
  const token = localStorage.getItem("alfaToken")


  const tokenExpireVerification = async () => {
    try {
      const decodedJwt = await jwt_decode(localStorage.getItem("alfaToken"));

      if (decodedJwt) {
        if (decodedJwt.exp < DateTime.now().toUnixInteger()) {
          localStorage.clear();
          window.location.href = "/";

        } else {
          setuserContext(decodedJwt);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const pareentCallback = (data) => {
    if (data) {
      let decoded = jwt_decode(data);
      setuserContext(decoded);
    }

  };
  useEffect(() => {
    tokenExpireVerification()
  }, [token]);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage pareentCallback={pareentCallback} />}></Route>
        {
          userContext && (
            <Route element={<ProtectedRoute userContext={userContext} />}>
              <Route path="/landing" element={<Landing userContext={userContext} />}></Route>
              <Route path="/dashboard" element={<Dashboard userContext={userContext} />}></Route>
              <Route path="/*" element={<Navigate to="/landing" />}></Route>
              <Route path="/dashboard/analyst" element={<DashboardAnalyst />}></Route>
              <Route path="/rules" element={<RulesPage />}></Route>
              <Route path="/search" element={<SearchPage />}></Route>
              <Route path="/users" element={<Users />} />
              <Route path="/roles" element={<Roles />} />
              <Route path="/alerts" element={<Alerts userContext={userContext} />} />
              <Route path="/adminlanding" element={<Adminlanding />} />
              <Route path="/adminalerts" element={<AdminAlerts />} />
              <Route path="/allalerts" element={<Alertkendo />} />
              <Route path="/suppressionalerts" element={<Approvesuppression userContext={userContext} />} />
            </Route>
          )
        }
      </Routes>
    </BrowserRouter>
  );
};
export default AppRoute;

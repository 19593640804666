import React, { useState, useEffect } from "react";
import {
    Button,
    Container,
    FormControl,
    OutlinedInput,
    Dialog,
    DialogTitle,
    IconButton,
    Grid,
    DialogContent,
    TextField,
    Typography,
    DialogActions,
    Autocomplete
} from '@mui/material';
import {
    Edit as EditIcon,
    Close as CloseIcon,
    Delete,
    Edit,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import getAllUsersResponse from "../../apis/users/getAllUser";
import getAllrolesResponse from "../../apis/users/getAllrolesResponse"
import getAllmanagersResponse from "../../apis/users/getAllmanagersResponse"
import getUserByIdResponse from '../../apis/users/getUserByIdResponse';
import updateUserByIdResponse from '../../apis/users/updateUserByIdResponse';
import './users.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '@progress/kendo-theme-default/dist/all.css';
import { process } from "@progress/kendo-data-query";
import { Grid as GridDiv, GridColumn } from "@progress/kendo-react-grid";
import deleteUserByIdResponse from "../../apis/users/deleteUsersResponse";
import createUserResponse from "../../apis/users/createUserResponse";



const Users = () => {
    const jsonConfig = require('../../config.json');
    const [alluser, setalluser] = useState([]);
    const [allrole, setallrole] = useState([]);
    const [allmanager, setallmanager] = useState([]);
    const [allmanagershowhide, setallmanagershowhide] = useState(false);
    const [editUserData, setEditUserData] = useState({})
    const [deleteUserData, setDeleteUserData] = useState({})
    const [openCreateModel, setIsOpenCreateModel] = useState(false);
    const [openEditModel, setIsOpenEditModel] = useState(false);
    const [openDeleteModel, setIsOpenDeleteModel] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [roleId, setRoleId] = useState('');
    const [managerId, setmanagerId] = useState('');
    const [editroleId, setEditRoleId] = useState('');
    const [editmanagerId, setEditmanagerId] = useState('');
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 25,
        filter: {
            logic: "and",
            filters: [
                {
                    field: "isActive",
                    operator: "eq",
                    value: true,
                },
            ],
        },
        sort: [],
    });


    const [dataResult, setDataResult] = useState(
        process(alluser, dataState)
    );
    useEffect(() => {
        setDataResult(process(alluser, dataState));
    }, [alluser]);

    const dataStateChange = (event) => {
        setDataResult(process(alluser, event.dataState));
        setDataState(event.dataState);
    };

    const createModalOpenHandler = () => {
        setIsOpenCreateModel(true)
    }
    const createModalCloseHandler = () => {
        setIsOpenCreateModel(false);
    }

    const handleSelectRole = (e, value) => {
        if (value) {
            if (value.name == "Analyst") {
                setallmanagershowhide(true)
            }
            else {
                setallmanagershowhide(false)
            }
            setRoleId(value.id)
        }
    }

    const handleSelectManager = (e, value) => {
        if (value) {
            setmanagerId(value.id)
        }
    }

    const handleSaveUser = async () => {
        const userPayload = {
            firstName,
            middleName,
            lastName,
            email,
            password,
            roleId,
            managerId
        };
        console.log('userPayload', userPayload)
        try {
            if (userPayload.firstName == '' || userPayload.lastName == '' || userPayload.email == '' || userPayload.password == '' || userPayload.roleId == '' || userPayload.managerId == '') {
                setIsOpenCreateModel(true);
                NotificationManager.error('All fields are required', "Error", 3000);

            } else {

                const response = await createUserResponse(userPayload)

                if (response.status == 201) {

                    await getAllUserData();
                    setFirstName()
                    setMiddleName()
                    setLastName()
                    setEmail()
                    setPassword()
                    setIsOpenCreateModel(false);
                    return NotificationManager.success(
                        "User Added successfully",
                        "Success",
                        3000
                    );

                } else {
                    NotificationManager.error("User Addition faild", 'Error', 3000);
                }
            }
        } catch (error) {
            NotificationManager.error("User Addition faild", 'Error', 3000);
        }
        //setIsOpenCreateModel(false);
    };

    const editModalOpenHandler = async (id) => {

        const editUserDataResponse = await getUserByIdResponse(id);
        if (editUserDataResponse.data.data[0].role.name == "Analyst") {
            setallmanagershowhide(true)
        }
        else {
            setallmanagershowhide(false)
        }
        setEditUserData(editUserDataResponse.data.data[0])
        setIsOpenEditModel(true)
    }
    const editModalCloseHandler = () => {
        setIsOpenEditModel(false);
    };

    const handleEditSelectRole = (e, value) => {
        console.log('valuehandleEditSelectRole', value.name)
        if (value) {

            if (value.name === "Analyst") {
                setallmanagershowhide(true)
            }
            else {
                setallmanagershowhide(false)
            }
            setEditRoleId(value.id)
            setEditUserData((item) => ({
                ...item,
                roleId: value.id,

            }));
        }
    }

    const handleEditSelectManager = (e, value) => {
        if (value) {
            console.log("ID", value)
            setEditmanagerId(value.id)
            setEditUserData((item) => ({
                ...item,
                managerId: value.id

            }));
        }

    }
    const handleUserUpdate = async () => {

        if (editUserData.firstName == '' || editUserData.lastName == '' || editUserData.email == '' || editUserData.password == '' || editUserData.roleId == '' || editUserData.managerId == '') {
            setIsOpenEditModel(true);
            NotificationManager.error('All fields are required', "Error", 3000);

        } else {

            const updateResponse = await updateUserByIdResponse(editUserData);
            if (updateResponse.status == 200) {
                await getAllUserData();
                setIsOpenEditModel(false);
                return NotificationManager.success(
                    "User Updated successfully",
                    "Success",
                    3000
                );

            }
            else {
                throw new Error('something went wrong');
            }

        }


    };

    const deleteModalCloseHanlder = () => {
        setIsOpenDeleteModel(false)
    }

    const deleteModalOpenHandler = async (id) => {
        const deleteUserDataResponse = await getUserByIdResponse(id);

        setDeleteUserData(deleteUserDataResponse.data.data[0])
        setIsOpenDeleteModel(true)
    }


    const handleDeleteUser = async () => {
        try {
            const resData = await deleteUserByIdResponse(deleteUserData.id)
            if (resData.status == 200) {
                await getAllUserData();
                setIsOpenDeleteModel(false);
                return NotificationManager.success(
                    "User Deleted successfully",
                    "Success",
                    3000
                );


            } else {
                NotificationManager.error("Faild to delete User", 'Error', 3000);
            }
        } catch (error) {
            NotificationManager.error("Faild to delete User", 'Error', 3000);
        }
    };

    useEffect(() => {
        getAllUserData();
        getroles();
        getmanager();

    }, []);


    const getAllUserData = async () => {
        let allUserData = await getAllUsersResponse();
        if (allUserData.data.data) {
            setalluser(allUserData.data.data);
        }
        else {

        }

    };


    const getroles = async () => {
        try {
            const res = await getAllrolesResponse();
            const data = res.data.data;
            console.log("data", data)
            setallrole(data.map(item => (item)))

        } catch (error) {
            console.log(error.response.data);
        }
    };

    const getmanager = async () => {
        try {
            const res = await getAllmanagersResponse();
            const data = res.data.data;
            console.log("data", data)
            setallmanager(data.map(item => (item)))

        } catch (error) {
            console.log(error.response.data);
        }
    };
    return (
        <>

            <Container>
                <div className='user_header'>
                    <h2 className="page_heading">User Management</h2>
                </div>

                <div className='user_table'>
                    <div className='user_create_btn'>
                        <Button
                            variant='contained'
                            onClick={createModalOpenHandler}>
                            Create User
                        </Button>
                    </div>
                    {/* kendo data grid starts here */}
                    <GridDiv
                        filter={dataState.filter}
                        sort={dataState.sort}
                        sortable={true}
                        filterable={true}
                        pageable={{
                            pageSizes: [5, 10, 20, 25, 50, 100],
                            info: true,
                            previousNext: true,
                            buttonCount: 10
                        }}
                        resizable={true}
                        skip={dataState.skip}
                        take={dataState.take}
                        data={dataResult}
                        onDataStateChange={dataStateChange}
                    >

                        <GridColumn
                            field=""
                            title="Action"
                            filterable={false}
                            cell={(event) => {
                                return (
                                    <>
                                        <td>
                                            <EditIcon
                                            className='cp'
                                                onClick={() => { editModalOpenHandler(event.dataItem.id) }}
                                            />
                                            {'     '}
                                            <Delete
                                            className='cp'
                                                onClick={() => {
                                                    
                                                    deleteModalOpenHandler(event.dataItem.id);
                                                }}
                                            />
                                        </td>
                                    </>
                                );
                            }}
                        />
                        <GridColumn
                            field="firstName"
                            title="First Name"
                            filterable={true}
                        />
                        <GridColumn
                            field="middleName"
                            title="Middle Name"
                            filterable={true}
                        />
                        <GridColumn
                            field="lastName"
                            title="Last Name"
                            filterable={true}
                        />

                        <GridColumn
                            field="email"
                            title="Email"
                            filterable={true}
                        />
                        <GridColumn
                            field="role.name"
                            title="Role"
                            filterable={true}
                        />

                    </GridDiv>

                </div>

            </Container>

            {/* create New users modal starts*/}
            <Dialog
                onClose={createModalCloseHandler}
                aria-labelledby='customized-dialog-title'
                open={openCreateModel}
                PaperProps={{
                    style: {
                        width: '600px',
                    },
                }}>
                <Grid item xs={12}>
                    <div className='creatTest_title'>
                        <DialogTitle
                            id='customized-dialog-title'
                            className='admindailog_title_modal'
                            onClose={createModalCloseHandler}>
                            Create User
                            <IconButton
                                aria-label='close'
                                style={{
                                    position: 'absolute',
                                    right: '7px',
                                    top: '12px',
                                    color: 'grey',
                                }}
                                onClick={createModalCloseHandler}>
                                <CloseIcon onClick={createModalCloseHandler} />
                            </IconButton>
                        </DialogTitle>
                    </div>
                </Grid>
                <DialogContent className='userlist_dailog_content'>
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            First Name*
                        </Typography>
                        <FormControl fullWidth variant='outlined'>
                            <OutlinedInput
                                placeholder='Enter First Name'
                                className='admin_textbox'
                                required
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            Middle Name
                        </Typography>
                        <FormControl fullWidth variant='outlined'>
                            <OutlinedInput
                                placeholder='Enter Role'
                                className='admin_textbox'
                                value={middleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            Last Name
                        </Typography>
                        <FormControl fullWidth variant='outlined'>
                            <OutlinedInput
                                placeholder='Enter Lastname'
                                className='admin_textbox'
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            Email
                        </Typography>
                        <FormControl fullWidth variant='outlined'>
                            <OutlinedInput
                                placeholder='Enter Email'
                                className='admin_textbox'
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            Password
                        </Typography>
                        <FormControl fullWidth variant='outlined'>
                            <OutlinedInput
                                placeholder='Enter Password'
                                className='admin_textbox'
                                type='password'
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            Role
                        </Typography>
                        <Autocomplete
                            id="combo-box-demo"
                            className="mt_8 selectfocus"
                            options={allrole}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            onChange={handleSelectRole}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required={true}
                                    placeholder="Select Role"
                                />
                            )}
                        />
                    </Grid>
                    &nbsp;
                    {allmanagershowhide ? <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            Manager
                        </Typography>
                        <Autocomplete
                            id="combo-box-demo"
                            className="mt_8 selectfocus"
                            options={allmanager}
                            autoHighlight
                            getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                            onChange={handleSelectManager}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required={true}
                                    placeholder="Select Manager"
                                />
                            )}
                        />
                    </Grid> : ""}

                    <Grid
                        item
                        xs={12}
                        md={12}
                        className='user_pop_button content-scrollable'>
                        <DialogActions>
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={createModalCloseHandler}>
                                Cancel
                            </Button>

                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleSaveUser}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Grid>
                </DialogContent>


            </Dialog>
            {/* create new users modal end */}


            {/* Edit  users modal starts */}
            <Dialog
                onClose={editModalCloseHandler}
                aria-labelledby='customized-dialog-title'
                open={openEditModel}
                PaperProps={{
                    style: {
                        width: '600px',
                    },
                }}>
                <Grid item xs={12}>
                    <div className='creatTest_title'>
                        <DialogTitle
                            id='customized-dialog-title'
                            className='admindailog_title_modal'
                            onClose={editModalCloseHandler}>
                            Edit Role
                            <IconButton
                                aria-label='close'
                                style={{
                                    position: 'absolute',
                                    right: '7px',
                                    top: '12px',
                                    color: 'grey',
                                }}
                                onClick={editModalCloseHandler}>
                                <CloseIcon onClick={editModalCloseHandler} />
                            </IconButton>
                        </DialogTitle>
                    </div>
                </Grid>
                <DialogContent className='userlist_dailog_content'>
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            First Name*
                        </Typography>
                        <FormControl fullWidth variant='outlined'>
                            <OutlinedInput
                                placeholder='Enter First Name'
                                className='admin_textbox'
                                required
                                value={editUserData.firstName}
                                onChange={(e) => { setEditUserData((preValue) => ({ ...preValue, firstName: e.target.value })) }}

                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            Middle Name
                        </Typography>
                        <FormControl fullWidth variant='outlined'>
                            <OutlinedInput
                                placeholder='Enter Middle Name'
                                className='admin_textbox'

                                value={editUserData.middleName}
                                onChange={(e) => { setEditUserData((preValue) => ({ ...preValue, middleName: e.target.value })) }}

                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            Last Name*
                        </Typography>
                        <FormControl fullWidth variant='outlined'>
                            <OutlinedInput
                                placeholder='Enter Last Name'
                                className='admin_textbox'
                                required
                                value={editUserData.lastName}
                                onChange={(e) => { setEditUserData((preValue) => ({ ...preValue, lastName: e.target.value })) }}

                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            Email*
                        </Typography>
                        <FormControl fullWidth variant='outlined'>
                            <OutlinedInput
                                placeholder='Enter Email'
                                className='admin_textbox'
                                required
                                value={editUserData.email}
                                onChange={(e) => { setEditUserData((preValue) => ({ ...preValue, email: e.target.value })) }}

                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='h2'>
                            Role
                        </Typography>
                        <Autocomplete
                            id="combo-box-demo"
                            className="mt_8 selectfocus"
                            options={allrole}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            onChange={handleEditSelectRole}
                            defaultValue={{ name: editUserData?.role?.name }}
                            getOptionSelected={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required={true}
                                    placeholder="Select Role"
                                />
                            )}
                        />
                    </Grid>
                    &nbsp;
                    {allmanagershowhide ?
                        <Grid item xs={12} md={12}>
                            <Typography variant='body1' component='h2'>
                                Manager
                            </Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                className="mt_8 selectfocus"
                                options={allmanager}
                                autoHighlight
                                getOptionLabel={(option) => `${option.firstName}  ${option.lastName}`}
                                onChange={handleEditSelectManager}
                                defaultValue={{ firstName: editUserData?.managerdata?.map((e) => e.firstName), lastName: editUserData?.managerdata?.map((e) => e.lastName) }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        required={true}
                                        placeholder="Select Manager"
                                    />
                                )}
                            />
                        </Grid> : ''}


                </DialogContent>
                <Grid
                    item
                    xs={12}
                    md={12}
                    className='user_pop_button content-scrollable'>
                    <DialogActions>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={editModalCloseHandler}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleUserUpdate}
                        >
                            Update
                        </Button>
                    </DialogActions>
                </Grid>


            </Dialog>
            {/* Edit  users modal end */}


            {/* Delete users modal starts */}
            <Dialog
                onClose={deleteModalCloseHanlder}
                aria-labelledby='customized-dialog-title'
                open={openDeleteModel}
                PaperProps={{
                    style: {
                        width: '600px',
                    },
                }}>
                <Grid item xs={12}>
                    <div className='creatTest_title'>
                        <DialogTitle
                            id='customized-dialog-title'
                            className='admindailog_title_modal'
                            onClose={deleteModalCloseHanlder}>
                            Confirm Deletion
                            <IconButton
                                aria-label='close'
                                style={{
                                    position: 'absolute',
                                    right: '7px',
                                    top: '12px',
                                    color: 'grey',
                                }}
                                onClick={() => { deleteModalCloseHanlder() }}>
                                <CloseIcon onClick={() => { deleteModalCloseHanlder() }} />
                            </IconButton>
                        </DialogTitle>
                    </div>
                </Grid>
                <DialogContent className='userlist_dailog_content'>
                    <Grid item xs={12} md={12}>
                        <Typography variant='body1' component='p'>
                            are you sure, want to delete?
                        </Typography>
                    </Grid>
                    &nbsp;
                </DialogContent>

                <Grid
                    item
                    xs={12}
                    md={12}
                    className='user_pop_button content-scrollable'>
                    <DialogActions>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={deleteModalCloseHanlder}>
                            Cancel
                        </Button>

                        <Button variant="contained" color="primary" onClick={() => { handleDeleteUser() }}>
                            Delete
                        </Button>
                    </DialogActions>
                </Grid>
            </Dialog>
            {/* Delete users modal end */}
            <NotificationContainer />

        </>
    );

};

export default Users;
import React, { useContext, useEffect, useState } from 'react'
import { Container, Button, Typography, Grid, Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chart from "react-apexcharts";
import FalsePositiveIcon from "./../../resources/images/FalsePositiveIcon.svg";
import { ReactComponent as PendingReviewIcon } from "./../../resources/images/PendingReviewIcon.svg";
import TrueMatchIcon from "./../../resources/images/TrueMatchIcon.svg";
import Footer from "./../../components/Footer/Footer";
import Header from "./../../components/Header/Header";
import {
    firstRowStyles,
    buttonStyles,
    firstReviewBoardStyles,
    pendingReviewStyles,
    headerGridStyles,
    suppressionBtnGrid,
    otherReviewBoardStyles,
    barchartCardStyles,
    piechartCardStyles,
    secondRowStyles,
    thirdRowStyles,
    dropDownStyle,
    reviewBtnStyles,
    selectStyles,
    reviewGridStyles,
    formControlStyles,
    dashboardHeadStyles,
    piechartHeadStyles,
    headingStyle,
    headingStyles,
    headingStylePrimary,
    headingStyleSecondary,
    dropDownStyleInAnalyst,
    reviewBtnStyles_pending
} from "./../Dashboard/DashboardStyles";
import "./../Dashboard/Dashboard.css";
import getAllAlertStatusResponse from "../../apis/alertStatus/getAllAlertStatus";
import getAlertsByDescription from "../../apis/alertStatus/getAlertsByDescription";
import getAllAlertResponse from "../../apis/alerts/getAllAlert";
import { makeStyles } from "@mui/styles";
import backgroundPic from "./../../resources/images/Background.svg"

import { getBarChartDataFunction, getModelChartDataFunction, getPieChartDataFunction } from '../../utilities/chartData'



const useStyles = makeStyles({
    reviewButton: {
        color: "#000",
        backgroundColor: "#F8A87E",
        textTransform: "none",
        height: "2rem",
        fontWeight: 800,
        fontFamily: "Montserrat",
        fontSize: "1rem",
        boxShadow: 2,
        padding: 1,
    },
    chartDropdown: {
        margin: "1px",
        minWidth: "100px",
    },
    recentAlert: {
        fontFamily: "Merriweather",
        fontWeight: 800,
    },
    chartHeading: {
        fontFamily: "Merriweather",
        fontWeight: 400,
    },
    dropdownArea: {
        borderRadius: "16px",
        border: "2px solid #4D4F5C",
        fontWeight: 600,
        fontSize: "0.6rem",
        lineHeight: "1em",
        backgroundColor: "#F1F9FF",
        padding: "9px 9px",
    },
    pageContainer: {
        backgroundImage: `url(${backgroundPic})`,
    },
    pendingReviewLeft: {
        display: 'flex',
        flexDirection: 'column !important',
        alignItems: 'center',
        margin: '5px !important',
        gap: '6px',
    },
    pendingReviewBoxRight: {
        paddingLeft: '15px'
    },
    casesContainer: {
        border: ' 1px solid rgb(28, 189, 205)',
        backgroundColor: 'rgb(18, 18, 18)',
        borderRadius: '16px'
    },
    headingStyle: {
        fontWeight: '700 !important',
        fontFamily: "Montserrat !important",
        fontSize: "16px !important",
        color: 'white'
    },
    graphsContainer: {
        marginTop: '20px !important',
        marginBottom: '5px !important'
    }
});


export default function Dashboard() {


    const navigate = useNavigate();
    const [days1, setDays1] = React.useState('7 Days');
    const [days2, setDays2] = React.useState('7 Days');
    const [allStatus, setallStatus] = useState([]);
    const [allAlerts, setallAlerts] = useState([]);
    const [barChartValue, setBarCharValue] = useState([]);
    const [barChartOptions, setBarCharOptions] = useState({});
    const [pieChartValue, setPieChartValue] = useState([0, 0]);
    const [modelChartValue, setmodelChartValue] = useState([0, 0]);
    const [pieChartValueAvialable, setPieChartValueAvialable] = useState(false);
    const [modelChartValueAvialable, setModelChartValueAvialable] = useState(false);
    const [allAlertAvailable, setAllAlertAvailable] = useState(false);
    const [barChartValueAvailable, setBarChartValueAvailable] = useState(false);

    // Alfa process and Level II Review values
    const [alfaProcessedCount, setAlfaProcessedCount] = useState(0);
    const [l2Review, setL2Review] = useState(0);

    const classes = useStyles();

    const handleChange1 = (event) => {
        setDays1(event.target.value);
    };
    const handleChange2 = (event) => {
        setDays2(event.target.value);
    };

    const goToAlertKendo = (e, code) => {
        if (allStatus.length > 0) {
            const matchedStatus = allStatus.find((e) => e.finalstatus?.code === code);
            if (matchedStatus && matchedStatus.finalstatus && matchedStatus.finalstatus.id) {
                const id = matchedStatus.finalstatus.id;
                navigate('/allalerts', {
                    state: {
                        id: id
                    }
                });
            } else {
                console.error("No alert status found with code: ", code);
            }
        } else {
            navigate('/allalerts');
        }
    }

    // Alfa alerts
    const goToAlfaAlertKendo = (e, descType) => {
        if (descType) {
            navigate('/allalerts', {
                state: {
                    description: descType
                }
            });
        } else {
            navigate('/allalerts');
        }
    }

    const getAllAlertStatus = async () => {
        try {
            const response = await getAllAlertStatusResponse();
            let responseNew = await getAlertsByDescription();
            responseNew = responseNew?.data?.data
            let l1ReviewCount = 0;
            let l2ReviewCount = 0;
            if (responseNew.length > 0) {
                l1ReviewCount = responseNew.filter((item) => item.subdecision === "Level I Review")[0]?.countData
                l2ReviewCount = responseNew.filter((item) => item.subdecision === "Level II Review")[0]?.countData
            }
            setAlfaProcessedCount(l1ReviewCount)
            setL2Review(l2ReviewCount)
            setallStatus(response.data.data);
            setAllAlertAvailable(true);
        } catch (error) {
            console.error("Error while getting alert status: ", error);
        }
    };

    const getAlertCount = (code) => {
        if (allStatus.length > 0) {
            let countData = allStatus.filter((item) => item.finalstatus.code === code)
            if (countData.length > 0) {
                return countData[0].totalalertcount
            }
            else return 0
        }
        else return 0
    }

    useEffect(() => {
        const dataFetch = async () => {
            try {
                const barchartdata = await getBarChartDataFunction();
                setBarCharValue(barchartdata.barChartData);
                setBarCharOptions(barchartdata.dayChartOptionsNew)
                setBarChartValueAvailable(true)

            } catch (error) {
                console.error("Error while getting bar chart data: ", error);
            }
        }
        dataFetch()
    }, [])
    useEffect(() => {
        const dataFetch = async () => {
            try {
                const piemodeldata = await getPieChartDataFunction({ "piedate": days2 })
                setPieChartValue(piemodeldata)
                setPieChartValueAvialable(true)
            } catch (error) {
                console.error("Error while getting pie chart data: ", error);
            }
        }
        dataFetch()
    }, [days2])

    useEffect(() => {
        const dataFetch = async () => {
            try {
                const modeldata = await getModelChartDataFunction({ "piedate": days1 })
                setmodelChartValue(modeldata)
                setModelChartValueAvialable(true)
            } catch (error) {
                console.error("Error while getting pie chart data: ", error);
            }
        }
        dataFetch()

    }, [days1])

    useEffect(() => {
        getAllAlertStatus()
    }, [])


    return (
        <div className={classes.pageContainer}>

            <div className="BodyWithFooter">
                <div className="ContentBody">

                    <Header />

                    {/* Container for main section */}
                    {allAlertAvailable && barChartValueAvailable && pieChartValueAvialable && modelChartValueAvialable ?
                        <Container>
                            {/* First Row */}
                            <Grid container spacing={1} sx={firstRowStyles}>
                                <Grid item md={8}>
                                    <Typography variant="h5" sx={headingStylePrimary}>Recent Alerts (Last 24 hours)</Typography>
                                </Grid>
                            </Grid>

                            {/* Second Row */}
                            <Grid container justifyContent='space-between'>
                                <Grid item md={6} xs={12}>
                                    <div className='pendingReviewBox'>
                                        <div className='leftSideContent'>
                                            <Typography variant="subtitle1" className={classes.headingStyle} style={{ textAlign: 'center' }}>Pending Reviews</Typography>
                                            <PendingReviewIcon />
                                        </div>
                                        <div className='rightSideContent'>
                                            <div className='pendingReviewSubDiv'>
                                                <span className={classes.headingStyle}>Level I Review</span>
                                                <span variant="h4" className='alertCount' style={{ fontSize: '34px' }}>{alfaProcessedCount}</span>
                                                <Button variant="contained" sx={reviewBtnStyles_pending} onClick={(e) => goToAlfaAlertKendo(e, "Level I Review")}>Review</Button>
                                            </div>
                                            <div className='pendingReviewSubDiv'>
                                                <span className={classes.headingStyle}>Level II Review</span>
                                                <span variant="h4" className='alertCount' style={{ fontSize: '34px' }}>{l2Review}</span>
                                                <Button variant="contained" sx={reviewBtnStyles_pending} onClick={(e) => goToAlfaAlertKendo(e, "Level II Review")}>Review</Button>
                                            </div>
                                        </div>

                                    </div>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <div className='rightDiv_secondRow'>
                                        <div className='trueMatchBoxcontent'>
                                            <img src={TrueMatchIcon} alt="False Positive Icon" />
                                            <div className='pendingReviewSubDiv'>
                                                <span className={classes.headingStyle}>False Positives</span>
                                                <span variant="h4" className='alertCount' style={{ fontSize: '34px' }}>{getAlertCount("FalsePositive")}</span>
                                                <Button variant="contained" sx={reviewBtnStyles_pending} onClick={(e) => goToAlertKendo(e, "FalsePositive")}>Review</Button>
                                            </div>
                                        </div>
                                        <div className='falsePositiveBoxcontent'>
                                            <img src={FalsePositiveIcon} alt="False Positive Icon" />
                                            <div className='pendingReviewSubDiv'>
                                                <span className={classes.headingStyle}>True Matches</span>
                                                <span variant="h4" className='alertCount' style={{ fontSize: '34px' }}> {getAlertCount("TrueMatch")}</span>
                                                <Button variant="contained" sx={reviewBtnStyles_pending} onClick={(e) => goToAlertKendo(e, "TrueMatch")}>Review</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            {/* Third Row */}
                            <Grid container sx={thirdRowStyles}>
                                <Grid item container xs={12} justifyContent='center'>
                                    <Typography variant="h5" sx={headingStylePrimary}>Alert Dashboard</Typography>
                                </Grid>
                                <Grid container className={classes.graphsContainer}>
                                    <Grid item xs={12} md={8}>
                                        <div className='barGraph'>
                                            <Chart
                                                type="bar"
                                                series={barChartValue}
                                                options={barChartOptions}
                                            ></Chart>
                                        </div>
                                    </Grid>
                                    <Grid item container xs={12} md={4}>
                                        <div className='piecharts'>
                                            <div className='modelEfficiency'>
                                                <Grid container direction="row" sx={{ paddingRight: "12px" }}>
                                                    <Grid item container xs={7}>
                                                        <Typography variant="subtitle1" sx={headingStyleSecondary} >Model Efficiency</Typography>
                                                    </Grid>
                                                    <Grid item container xs={5} className="mt-12">
                                                        <FormControl>
                                                            <InputLabel id="demo-select-small"></InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={days1}
                                                                displayEmpty
                                                                onChange={handleChange1}
                                                                sx={dropDownStyle}
                                                            >
                                                                <MenuItem value='7 Days'>7 Days</MenuItem>
                                                                <MenuItem value='30 Days'>30 Days</MenuItem>
                                                                <MenuItem value='90 Days'>90 Days</MenuItem>
                                                                <MenuItem value='180 Days'>180 Days</MenuItem>
                                                                <MenuItem value='1 Year'>1 Year</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Chart
                                                    type="pie"
                                                    series={modelChartValue}
                                                    options={{
                                                        labels: ["AI Processed", "Manual Review"],
                                                        colors: ['#1CBDCD', '#F7A87E'],
                                                        chart: {
                                                            chart: {
                                                                fontFamily: 'Montserrat',
                                                                fontWeight: 600,
                                                            },
                                                        },
                                                        legend: {
                                                            position: "bottom",
                                                            fontFamily: 'Montserrat',
                                                            fontWeight: 600,
                                                            fontSize: "11.8px",
                                                            markers: {
                                                                width: 24,
                                                                height: 12,
                                                                radius: 12,
                                                            },
                                                            itemMargin: {
                                                                horizontal: 2,
                                                                vertical: 0
                                                            },
                                                        },
                                                        plotOptions: {
                                                            pie: {
                                                                startAngle: 90,
                                                                endAngle: 450,
                                                                dataLabels: {
                                                                    offset: -14
                                                                },
                                                            }
                                                        },
                                                        dataLabels: {
                                                            enabled: true,
                                                            style: {
                                                                fontSize: '14px',
                                                                fontFamily: 'Montserrat',
                                                                fontWeight: 400,
                                                                colors: ['#0F1012', '#0F1012']
                                                            }
                                                        }
                                                    }
                                                    }
                                                ></Chart>
                                            </div>
                                            <div className='alerts_pieChart'>
                                                <Grid item container direction="row" sx={{ paddingRight: "12px" }}>
                                                    <Grid item container xs={7}>
                                                        <Typography variant="subtitle1" sx={headingStyleSecondary}>Alerts</Typography>
                                                    </Grid>
                                                    <Grid item container xs={5} className="mt-12">
                                                        <FormControl>
                                                            <InputLabel id="demo-select-small"></InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={days2}
                                                                displayEmpty
                                                                onChange={handleChange2}
                                                                sx={dropDownStyle}
                                                            >
                                                                <MenuItem value='7 Days'>7 Days</MenuItem>
                                                                <MenuItem value='30 Days'>30 Days</MenuItem>
                                                                <MenuItem value='90 Days'>90 Days</MenuItem>
                                                                <MenuItem value='180 Days'>180 Days</MenuItem>
                                                                <MenuItem value='1 Year'>1 Year</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Chart
                                                    type="pie"
                                                    series={pieChartValue}
                                                    options={{
                                                        labels: ["False Positive", "True Match", "Pending Review"],
                                                        colors: ['#02bc77', '#ff2366', '#ffd950'],
                                                        chart: {
                                                            chart: {
                                                                fontFamily: 'Montserrat',
                                                                fontWeight: 600,
                                                            },
                                                        },
                                                        legend: {
                                                            position: "bottom",
                                                            fontFamily: 'Montserrat',
                                                            fontWeight: 600,
                                                            fontSize: "12px",
                                                            markers: {
                                                                width: 24,
                                                                height: 12,
                                                                radius: 12,
                                                            },
                                                            itemMargin: {
                                                                horizontal: 6,
                                                                vertical: 0
                                                            },
                                                        },
                                                        plotOptions: {
                                                            pie: {
                                                                startAngle: 90,
                                                                endAngle: 450,
                                                                dataLabels: {
                                                                    offset: -16
                                                                },
                                                            }
                                                        },
                                                        dataLabels: {
                                                            enabled: true,
                                                            style: {
                                                                fontSize: '14px',
                                                                fontFamily: 'Montserrat',
                                                                fontWeight: 400,
                                                                colors: ['#0F1012', '#0F1012']
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                        :
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={true}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    }
                </div>
                <Footer />
            </div>
        </div >
    );
}

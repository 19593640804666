import axios from "axios";
const jsonConfig = require("../../config.json");


const getAllUsersResponse = async () => {
    let allResponse = {}
    try {
        const Response = await  axios.get(
            jsonConfig.apiUrl + "users", 
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );
        
        allResponse = Response;
        return Promise.resolve(allResponse)
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export default getAllUsersResponse;
import React, { useEffect, useState } from 'react';

import {
  Button,
  Container,
  FormControl,
  OutlinedInput,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  DialogContent,
  Typography,
  DialogActions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';

import { Edit as EditIcon, Close as CloseIcon, Delete } from '@mui/icons-material';
import { Grid as GridDiv, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import getAllRolesResponse from '../../apis/roles/getAllRolesResponse';

import './roles.css';
import getRoleByIdResponse from '../../apis/roles/getRoleByIdResponse';
import updateRoleByIdResponse from '../../apis/roles/updateRoleByIdResponse';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const useStyles = makeStyles({
  fontRoboto: {
    fontFamily: 'Roboto,sans-serif',
  },
  button: {
    marginBottom: '10px',
  },
});

function Roles() {
  const [openCreateModel, setIsOpenCreateModel] = useState(false);
  const [openEditModel, setIsOpenEditModel] = useState(false);
  const [openDeleteModel, setIsOpenDeleteModel] = useState(false);

  const jsonConfig = require('../../config.json');
  const classes = useStyles();
  const [rolesList, setRolesList] = useState([]);

  const [code, setCode] = useState('');
  const [roleName, setRole] = useState('');

  const [editRoleData, setEditRoleData] = useState({});

  const getAllRoles = async () => {
    const allRoles = await getAllRolesResponse();
    console.log(allRoles.data.data);
    setRolesList(allRoles.data.data);
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: '',
        dir: 'desc',
      },
    ],
  });

  //initialising result for kendo filter

  const [rolesResult, setResult] = React.useState(rolesList, dataState);
  const onDataStateChange = (event) => {
    setDataState(event.dataState);
    setResult(process(rolesList, event.dataState));
  };
  useEffect(() => {
    setResult(process(rolesList, dataState));
  }, [rolesList]);

  const createModalOpenHandler = () => {
    setIsOpenCreateModel(true);
  };
  const createModalCloseHandler = () => {
    setEditRoleData({});
    setIsOpenCreateModel(false);
  };
  const editModalOpenHandler = async (id) => {
    const editRoleDataResponse = await getRoleByIdResponse(id);
    console.log(editRoleDataResponse);
    setEditRoleData(editRoleDataResponse.data.data[0]);
    setIsOpenEditModel(true);
  };
  const editModalCloseHandler = () => {
    setEditRoleData({});
    setIsOpenEditModel(false);
  };

  const deleteModalOpenHandler = async (id) => {
    const editRoleDataResponse = await getRoleByIdResponse(id);

    setEditRoleData(editRoleDataResponse.data.data[0]);
    setIsOpenDeleteModel(true);
  };
  const deleteModalCloseHanlder = () => {
    setEditRoleData({});
    setIsOpenDeleteModel(false);
  };

  const handleSaveRole = async () => {
    const rolesPayload = {
      code,
      roleName,
    };
    try {
      console.log(rolesPayload.code, rolesPayload.roleName);
      if (rolesPayload.code == '' || rolesPayload.roleName == '') {
        NotificationManager.error('all fields are required', "Error", 3000);
      } else if (
        rolesPayload.code == null ||
        rolesPayload.code == undefined ||
        rolesPayload.roleName == null ||
        rolesPayload.roleName == undefined
      ) {
        NotificationManager.error('please enter valid value', "Error", 3000);
      } else {
        const response = await axios.post(
          `${jsonConfig.apiUrl}roles`,
          JSON.stringify(rolesPayload),
          {
            headers: {
              'x-functions-key': jsonConfig.key,
            },
          }
        );
        const responseData = await response;

        if (responseData.status == 200) {
          await getAllRoles();
          NotificationManager.success('Data created succesfully', 'Success',3000);
					setCode('');
					setRole('')
          setIsOpenCreateModel(false);
        } else {
          throw new Error('something went wrong!');
        }
      }
    } catch (error) {
      NotificationManager.error(error, 'Error',3000);
    }
  };

  const handleDeleteRole = async () => {
    try {
      const response = await axios.delete(`${jsonConfig.apiUrl}roles/${editRoleData.id}`, {
        headers: {
          'x-functions-key': jsonConfig.key,
        },
      });
      const resData = await response;
      if (resData.status == 200) {
        await getAllRoles();
        setEditRoleData({});
        NotificationManager.success('role deleted successfully', 'Success',3000);
        console.log('roles deleted succesfully');
        setIsOpenDeleteModel(false);
      } else {
        throw new Error('something went wrong');
      }
    } catch (error) {
      NotificationManager.error(error, 'Error',3000);
    }
  };
  const handleRoleUpdate = async () => {
		try {
			console.log(editRoleData);
      if (editRoleData.code == '' || editRoleData.name == '') {
        NotificationManager.error('all fields are required', "Error", 3000);
			} 
			else {
        const updateResponse = await updateRoleByIdResponse(editRoleData);
        if (updateResponse.status == 200) {
          await getAllRoles();
          setIsOpenEditModel(false);
          NotificationManager.success('Role updated successfully', 'Success',3000);
        } else {
          throw new Error('something went wrong');
        }
      }
    } catch (error) {
      NotificationManager.error(error, 'Error',3000);
    }
  };

  return (
    <>
    
      <Container>
        <div className='role_header'>
          <h2 className='page_heading'>Roles Managment</h2>
        </div>

        <div className='role_table'>
          <div className='role_create_btn'>
            {/* <Button variant='contained' onClick={createModalOpenHandler}>
              Add Role
            </Button> */}
          </div>

          <GridDiv
            data={rolesResult}
            sortable={true}
            reorderable={true}
            filterable={true}
            total={rolesList.length}
            pageable={{
              buttonCount: 10,
              info: true,
              previousNext: true,
              pageSizes: true,
              serverPaging: true,
              serverFiltering: true,
            }}
            onDataStateChange={onDataStateChange}
            {...dataState}
            resizable={true}
          >
            <GridColumn
              className={classes.fontRoboto}
              title='Action'
              filterable={false}
              cell={(event) => {
                return (
                  <>
                    <td >
                      <EditIcon
                      className='cp'
                        onClick={() => {
                          editModalOpenHandler(event.dataItem.id);
                        }}
                      />
                      {'   '}
                      <Delete
                      className='cp'
                        onClick={() => {
                          deleteModalOpenHandler(event.dataItem.id);
                        }}
                      />
                    </td>
                  </>
                );
              }}
            />
            <GridColumn
              className={classes.fontRoboto}
              title='Roles'
              field='name'
              filterable={true}
              sortable={true}
            />
          </GridDiv>
        </div>
      </Container>
        {/* modal for create new role */}
        <Dialog
        onClose={createModalCloseHandler}
        aria-labelledby='customized-dialog-title'
        open={openCreateModel}
        PaperProps={{
          style: {
            width: '600px',
          },
        }}
      >
        <Grid item xs={12}>
          <div className='creatTest_title'>
            <DialogTitle
              id='customized-dialog-title'
              className='admindailog_title_modal'
              onClose={createModalCloseHandler}
            >
              Create Role
              <IconButton
                aria-label='close'
                style={{
                  position: 'absolute',
                  right: '7px',
                  top: '12px',
                  color: 'grey',
                }}
                onClick={createModalCloseHandler}
              >
                <CloseIcon onClick={createModalCloseHandler} />
              </IconButton>
            </DialogTitle>
          </div>
        </Grid>
        <DialogContent className='userlist_dailog_content'>
          <Grid item xs={12} md={12}>
            <Typography variant='body1' component='h2'>
              Code*
            </Typography>
            <FormControl fullWidth variant='outlined'>
              <OutlinedInput
                placeholder='Enter Code'
                className='admin_textbox'
                required
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </FormControl>
          </Grid>
          &nbsp;
          <Grid item xs={12} md={12}>
            <Typography variant='body1' component='h2'>
              Role*
            </Typography>
            <FormControl fullWidth variant='outlined'>
              <OutlinedInput
                placeholder='Enter Role'
                className='admin_textbox'
                required
                value={roleName}
                onChange={(e) => setRole(e.target.value)}
              />
            </FormControl>
          </Grid>
        </DialogContent>

        <Grid item xs={12} md={12} className='user_pop_button content-scrollable'>
          <DialogActions>
            <Button variant='outlined' color='primary' onClick={createModalCloseHandler}>
              Cancel
            </Button>

            <Button variant='contained' color='primary' onClick={handleSaveRole}>
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* modal for Edit Role */}
      <Dialog
        onClose={editModalCloseHandler}
        aria-labelledby='customized-dialog-title'
        open={openEditModel}
        PaperProps={{
          style: {
            width: '600px',
          },
        }}
      >
        <Grid item xs={12}>
          <div className='creatTest_title'>
            <DialogTitle
              id='customized-dialog-title'
              className='admindailog_title_modal'
              onClose={editModalCloseHandler}
            >
              Edit Role
              <IconButton
                aria-label='close'
                style={{
                  position: 'absolute',
                  right: '7px',
                  top: '12px',
                  color: 'grey',
                }}
                onClick={editModalCloseHandler}
              >
                <CloseIcon onClick={editModalCloseHandler} />
              </IconButton>
            </DialogTitle>
          </div>
        </Grid>
        <DialogContent className='userlist_dailog_content'>
        
          &nbsp;
          <Grid item xs={12} md={12}>
            <Typography variant='body1' component='h2'>
              Role*
            </Typography>
            <FormControl fullWidth variant='outlined'>
              <OutlinedInput
                placeholder='Enter Role'
                className='admin_textbox'
                required
                value={editRoleData.name}
                onChange={(e) => {
                  setEditRoleData((preValue) => ({
                    ...preValue,
                    name: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Grid>
        </DialogContent>

        <Grid item xs={12} md={12} className='user_pop_button content-scrollable'>
          <DialogActions>
            <Button variant='outlined' color='primary' onClick={editModalCloseHandler}>
              Cancel
            </Button>

            <Button variant='contained' color='primary' onClick={handleRoleUpdate}>
              Update
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* modal for delelte confirmation  */}

      <Dialog
        onClose={deleteModalCloseHanlder}
        aria-labelledby='customized-dialog-title'
        open={openDeleteModel}
        PaperProps={{
          style: {
            width: '600px',
          },
        }}
      >
        <Grid item xs={12}>
          <div className='creatTest_title'>
            <DialogTitle
              id='customized-dialog-title'
              className='admindailog_title_modal'
              onClose={deleteModalCloseHanlder}
            >
              Confirm Deletion
              <IconButton
                aria-label='close'
                style={{
                  position: 'absolute',
                  right: '7px',
                  top: '12px',
                  color: 'grey',
                }}
                onClick={() => {
                  deleteModalCloseHanlder();
                }}
              >
                <CloseIcon
                  onClick={() => {
                    deleteModalCloseHanlder();
                  }}
                />
              </IconButton>
            </DialogTitle>
          </div>
        </Grid>
        <DialogContent className='userlist_dailog_content'>
          <Grid item xs={12} md={12}>
            <Typography variant='body1' component='p'>
              are you sure, want to delete?
            </Typography>
          </Grid>
          &nbsp;
        </DialogContent>

        <Grid item xs={12} md={12} className='user_pop_button content-scrollable'>
          <DialogActions>
            <Button variant='outlined' color='primary' onClick={deleteModalCloseHanlder}>
              Cancel
            </Button>

            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                handleDeleteRole();
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
      <NotificationContainer />
    </>
  );
}

export default Roles;

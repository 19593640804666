import axios from "axios"
const jsonConfig = require("../../config.json")

const getsuppressAlertResponse = async (id) => {
	let allResponse = {}
	try {
		const Response = await axios.get(
			jsonConfig.apiUrl + "suppressalertbyalertid/"+id,
			
			{
				headers: {
					"x-functions-key": jsonConfig.key,
				},
			}
		)

		allResponse = Response
		return Promise.resolve(allResponse)
	} catch (error) {
		return Promise.reject(error)
	}
}

export default getsuppressAlertResponse;

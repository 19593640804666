import React, { useState, useEffect } from "react";
import countries from "country-list";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Button,
    Container,
    FormControl,
    OutlinedInput,
    Dialog,
    DialogTitle,
    IconButton,
    Grid,
    DialogContent,
    TextField,
    Typography,
    DialogActions,
    Autocomplete,
    Skeleton
} from '@mui/material';
import {
    Edit as EditIcon,
    View as ViewIcon,
    Close as CloseIcon,
    Delete,
    Edit,
} from '@mui/icons-material';
import getAllAlertByStatusId from "../../apis/alerts/getalertbystatus";
import getAllAlertByFinalStatusId from "../../apis/alerts/getalertbyfinalstatus";
import getalertbydescriptiontype from "../../apis/alerts/getalertbydescriptiontype";

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '@progress/kendo-theme-default/dist/all.css';
import { process } from "@progress/kendo-data-query";
import { Grid as GridDiv, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import jwt_decode from "jwt-decode";
import { makeStyles } from "@mui/styles";
import usStates from 'us-state-codes';

const useStyles = makeStyles({
    odd: {
        background: '#F6F6F6 !important'
    },
    even: {

        background: '#fffff !important'
    }
})



const Alertkendo = (props) => {
    // console.log("props on kendo", props?.props?.statusId)
    const navigate = useNavigate();
    const [allalert, setallalert] = useState([]);
    const [allCountryData, setAllCountryData] = useState(countries.getData());
    const [usercontext, setusercontext] = useState("")
    const [alertResponseAvailable, setAlertResponseAvailable] = useState(false);
    const classes = useStyles();

    const [dataState, setDataState] = useState({
        skip: 0,
        take: 25,
        filter: {
            logic: "and",
            filters: [
            ],
        },
        sort: [],
    });
    const [dataResult, setDataResult] = useState(
        process(allalert, dataState)
    );

    useEffect(() => {
        setDataResult(process(allalert, dataState));
    }, [allalert]);
    useEffect(() => {
        const dataStateChange = async () => {
            const decodedJwt = await jwt_decode(localStorage.getItem("alfaToken"));
            setusercontext(decodedJwt)
        }
        dataStateChange();
        let allCountriesData = allCountryData
        allCountriesData.push({
            "code": "USA",
            "name": "United States of America"
        })
        setAllCountryData(allCountriesData);
    }, []);

    const dataStateChange = (event) => {
        setDataResult(process(allalert, event.dataState));
        setDataState(event.dataState);
    };


    const viewAlertHandler = (e, event) => {
        navigate('/alerts', {
            state: {
                id: event.dataItem.id
            }
        });
    }
    const goback = () => {
        if (usercontext?.rolename == "Analyst")
            navigate('/dashboard/analyst', {})
        else
            navigate('/dashboard', {})
    };
    const getstatename = (statecode) => {
        let statename = statecode;
        let matchingStates =  usStates.getStateNameByStateCode(statecode);
        if (matchingStates!==null) {
            statename = matchingStates
        } else {
            statename = statecode
        }
        return (statename);
    }

    const getcountryname = (countrycode) => {
        let countryname = countrycode;
        let matchingCountry = allCountryData.filter(countries => countries.code === countrycode);
        if (matchingCountry.length > 0) {
            countryname = matchingCountry[0].name
        } else {
            countryname = countrycode
        }
        return (countryname)
        // locationCountry = matchingCountry ? matchingCountry.name : locationCountry;
    }
    const getAllAlertData = async () => {
        try {

            let allAlertData;
            if (props?.props?.statusId) {
                allAlertData = await getAllAlertByFinalStatusId(props?.props?.statusId);
            }
            else {
                allAlertData = await getalertbydescriptiontype(props?.props?.description);
            }
            setAlertResponseAvailable(true);
            if (allAlertData.data.data.length > 0) {
                let data = allAlertData.data.data

                data.forEach(dataObj => {
                    dataObj.alertID = dataObj.id.substring(0, 4)
                    let customerData = dataObj.customers
                    let streetAddress = " "
                    streetAddress += customerData?.ADDRESS ? customerData?.ADDRESS : "";
                    let city = customerData?.CITY ? customerData?.CITY : "";
                    let zoneCode = customerData?.ZONE ? (" " + customerData?.ZONE) : "";
                    let postalCode = customerData?.POSTAL_CODE ? (" " + customerData?.POSTAL_CODE) : "";
                    let COUNTRY_OF_RESIDENCE = customerData?.COUNTRY_OF_RESIDENCE ? (" " + customerData?.COUNTRY_OF_RESIDENCE) : "";
                    let combinedAddress = city + zoneCode + postalCode + COUNTRY_OF_RESIDENCE;
                    streetAddress = streetAddress.replace(new RegExp(combinedAddress, 'g'), '');
                    if (streetAddress === " " + customerData?.ADDRESS) {
                        postalCode = customerData?.POSTAL_CODE ? (" 0" + customerData?.POSTAL_CODE) : "";
                        combinedAddress = city + zoneCode + postalCode + COUNTRY_OF_RESIDENCE;
                        streetAddress = streetAddress.replace(new RegExp(combinedAddress, 'g'), '');
                    }
                    streetAddress = streetAddress.trim();
                    zoneCode = zoneCode.trim();
                    postalCode = postalCode.trim();
                    COUNTRY_OF_RESIDENCE = COUNTRY_OF_RESIDENCE.trim();
                    if (COUNTRY_OF_RESIDENCE && COUNTRY_OF_RESIDENCE.length <= 3) {
                        COUNTRY_OF_RESIDENCE = getcountryname(COUNTRY_OF_RESIDENCE)
                    }
                    if (zoneCode && zoneCode.length <= 3) {
                        zoneCode = getstatename(zoneCode)
                    }
                    let fullAddress = " " + (streetAddress ? streetAddress + ", " : '') + (city ? city + ", " : '') + (zoneCode ? zoneCode + ", " : '') + (COUNTRY_OF_RESIDENCE ? COUNTRY_OF_RESIDENCE : '')

                    let locationdata = "locationdata"
                    dataObj.locationdata = fullAddress
                    dataObj.dateOfBirth = DateTime.fromISO(dataObj.customers.DATE_OF_BIRTH).toFormat('MM-dd-yyyy')


                });
                setallalert(data);
            } else {
                setallalert([]);
            }
        } catch (error) {
            console.log(error)
        }

    };


    useEffect(() => {
        getAllAlertData();
    }, []);
    const getSkeletons = () => {
        let arr = []
        for (let i = 0; i < 10; i++) {
            arr.push(<Skeleton className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="6vh" />)
        }
        return arr;
    }

    return (
        <>

            <Container className="kendo_wrapper">
                <div className=''>
                    <p className=" kendo_title">Alerts</p>
                </div>
                <div className="mb5p">
                    <span className="cp" onClick={goback} ><ArrowBackIcon /></span>
                </div>

                <div className='user_table'>
                    {/* {allalert.length > 0 ? ( */}
                    <GridDiv
                        filter={dataState.filter}
                        sort={dataState.sort}
                        sortable={true}
                        filterable={true}
                        pageable={{
                            pageSizes: [5, 10, 20, 25, 50, 100],
                            info: true,
                            previousNext: true,
                            buttonCount: 10
                        }}
                        resizable={true}
                        skip={dataState.skip}
                        take={dataState.take}
                        data={dataResult}
                        onDataStateChange={dataStateChange}
                    >
                        <GridNoRecords>
                            {alertResponseAvailable ? '' : getSkeletons()}
                        </GridNoRecords>
                        <GridColumn
                            field=""
                            title="Action"
                            filterable={false}
                            cell={(event) => {
                                return (
                                    <>
                                        <td>
                                            {/* <button onClick={(e) => viewAlertHandler(e)}>View</button> */}
                                            <Button className='tab_inner_box_button_kendo' onClick={(e) => viewAlertHandler(e, event)} label="View Rule" variant="contained" size="small"  >View </Button>
                                        </td>
                                    </>
                                );
                            }}
                            width="100"
                        />
                        <GridColumn
                            field="alertID"
                            title="Alert ID"
                            filterable={true}
                            width="200"
                        />
                        <GridColumn
                            field="customers.FIRST_NAME"
                            title="First Name"
                            filterable={true}
                            width="200"
                        />
                        <GridColumn
                            field="customers.MIDDLE_NAMES"
                            title=" Middle Name"
                            filterable={true}
                            width="200"
                        />
                        <GridColumn
                            field="customers.LAST_NAME"
                            title="Last Name"
                            filterable={true}
                            width="200"
                        />
                        <GridColumn
                            field="dateOfBirth"
                            title="Date of Birth"
                            filterable={true}
                            width="200"
                        />
                        <GridColumn
                            field="customers.GENDER_CODE"
                            title="Gender"
                            filterable={true}
                            width="200"
                        />

                        <GridColumn
                            field="locationdata"
                            title="Location"
                            filterable={true}
                            width="200"
                        />
                        <GridColumn
                            field="aidecisionstatus.name"
                            title="AI Status"
                            filterable={true}
                            width="200"
                        />
                        <GridColumn
                            field="finalstatus.name"
                            title="Final Status"
                            filterable={true}
                            width="200"
                        />
                    </GridDiv>
                    {/* ) : (
                        ""
                    )

                    } */}
                    {/* kendo data grid starts here */}


                </div>

            </Container>

        </>
    );

};

export default Alertkendo;
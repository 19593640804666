export const bodyContainer = { paddingTop: "5vh" };

export const widgetContainer = { backgroundColor: "white", height: "70vh" }
export const buttonContainer = { width: "250px", marginBottom: "10px", marginTop: "45px", paddingLeft: "5px", }

export const leftWidgetContainer = { borderRadius: "0px 15px", boxShadow: "0px 5px 8px #E1E1E1" }

export const leftWidgetHead = { fontFamily: "Manrope", fontSize: "17px", padding: "10px 10px 10px 20px", backgroundColor: "black", color: "white", borderRadius: "10px" }
export const PopperContainer = { paddingTop: "10px" }
export const moreHoriStyles = { color: "#1CCD80" }
export const emptyGridStyles = { padding: "10px 0px 5px 10px", backgroundColor: "black", color: "white", marginTop: "-10px" }
export const formContainer = { backgroundColor: "white", paddingLeft: "10px", paddingBottom: "20px", borderRadius: "0px 15px" }
export const fieldContainer = { width: "250px", marginBottom: "10px", marginTop: "10px", borderLeft: "5px solid #F8AD85", paddingLeft: "5px" }
export const formLabelStyles = { color: "black", FontWeight: "bold", fontSize: "15", fontFamily: "Segoe UI", marginBottom: "5px", marginTop: "5px" }
export const textFieldStyles = { borderBottom: "1px solid #2699FB" }
export const skeletonStyles = { maxHeight: "11vh", minHeight: "11vh", marginBottom: "10px" }
export const dateRangeLabelContainer = { color: "black", fontSize: "12", fontFamily: "Segoe UI" }
export const rightWidgetContainer = { boxShadow: "0px 5px 8px #E1E1E1", backgroundColor: "white", borderRadius: "15px" }
export const rightWidgetHead = { borderBottom: "1px solid #707070", padding: "15px 0px 5px 0px", fontWeight: "600", fontSize: "17px", margin: "0px 20px 2vh 10px", fontFamily: "Manrope" }

export const rightWidgetBody = {
    overflowY: 'scroll', maxHeight: "66vh", minHeight: "66vh", padding: "0px 20px ",
    "&::-webkit-scrollbar": {
        width: "10px"
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "F5F5F5",
        borderRadius: "10px",
        boxShadow: "inset 0 0 6px #c8c8c8"
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "white",
        border: "1px solid #c8c8c8",
        borderRadius: "20px",
        boxShadow: " 0 0 6px #c8c8c8)"

    }
};

export const rightWidgetCards = {
    boxShadow: "0px 5px 8px #E1E1E1", borderRadius: "15px", padding: "5px 10px", marginBottom: "20px", display: 'grid',
    gridTemplateColumns: "1fr 2fr 2fr", gap: 5, cursor: "pointer"
};
export const cardDetails = { fontFamily: "Manrope", fontSize: "16px", fontWeight: "100", lineHeight: "25px", gridRow: '1', }
export const cardTitle = { padding: "20px 0px", fontSize: "17px", fontWeight: "bold", fontFamily: "Manrope", gridRow: '1', gridColumn: '1' }
export const rightFooter = { borderTop: "1px solid #707070", padding: "10px", margin: "2vh 20px 0px 10px" }

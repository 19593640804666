import { Container,Grid,Skeleton } from '@mui/material'
import React from 'react'
import { firstRowStyles, firstReviewBoardStyles, pendingReviewStyles, suppressionBtnGrid, otherReviewBoardStyles, barchartCardStyles, piechartCardStyles, secondRowStyles, thirdRowStyles, reviewGridStyles } from "../../pages/Dashboard/DashboardStyles"


const DashboardSkelton = (props) => {
    const {userContext}=props;
   
    return (
        <Container>
            {/* First Row */}
            <Grid container spacing={3} sx={firstRowStyles}>
                <Grid container item xs={7}>
                    <Grid item>
                       
                        <Skeleton variant='text' animation="wave" width={120} sx={{ fontSize: '2rem' }} />
                    </Grid>
                </Grid>
                <Grid container item xs={5} justifyContent="flex-end" >
                    <Grid container item xs={7} justifyContent="flex-end" sx={suppressionBtnGrid}>
                        <div className='test'>
                       {userContext.rolename=="Manager"?<Skeleton variant='rounded' animation="wave" width={120} height={25} />:""}
                        </div>
                    </Grid>

                    <Grid container item xs={4} justifyContent="flex-end"  >
                    {userContext.rolename=="Manager"?<Skeleton variant='rounded' animation="wave" width={120} height={25} />:""}

                    </Grid>
                </Grid>
            </Grid>


            {/* Second Row */}
            <Grid container justifyContent="space-between">
                <Grid container item xs={3.8} >
                    {/* Review Board 1*/}
                    <Grid container item sx={firstReviewBoardStyles} >

                        <Grid container item direction="column"
                            justifyContent="center"
                            alignItems="center" xs={4}>
                            <Grid item>
                                <Skeleton variant='rounded' animation="wave" width={64} height={64} />
                            </Grid>
                            <Grid item sx={{ marginTop: '5px' }}>
                                <Skeleton variant='rounded' animation="wave" width={60} height={25} />
                              
                            </Grid>
                        </Grid>
                        <Grid container item xs={7.8} direction="column">
                            <Grid Item>
                                <Skeleton variant='text' animation="wave" width={60} />
                                
                            </Grid>
                           
                            <Grid item>
                                <Skeleton variant='text' animation="wave" sx={{ fontSize: '1.5rem' }} width={20} />
                               
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container item xs={8} sx={otherReviewBoardStyles} justifyContent="space-between">

                    <Grid item container xs={6}>

                        <Grid container item  >

                            <Grid container item direction="column"
                                justifyContent="center"
                                alignItems="center" xs={4}>
                                <Grid item>
                                   
                                    <Skeleton variant='rounded' animation="wave" width={64} height={64} />
                                </Grid>
                                <Grid item sx={{ marginTop: '5px' }}>
                                    <Skeleton variant='rounded' animation="wave" width={60} height={25} />
                                   

                                </Grid>
                            </Grid>
                            <Grid container item xs={8} direction="column">
                                <Grid item>
                                    <Skeleton variant='text' animation="wave" width={60} />
                                </Grid>
                                <Grid item>
                                    <Skeleton variant='text' animation="wave" sx={{ fontSize: '1.5rem' }} width={20} />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container item xs={6} >

                        <Grid container item >

                            <Grid container item direction="column"
                                justifyContent="center"
                                alignItems="center" xs={4}>
                                <Grid Item sx={pendingReviewStyles}>

                                    <Skeleton variant='rounded' animation="wave" width={64} height={64} />
                                </Grid>
                                <Grid item >
                                    {/* <Button variant="contained" sx={reviewBtnStyles} onClick={(e) => goToAlertKendo(e, "TrueMatch")}>Review</Button> */}
                                    <Skeleton variant='rounded' animation="wave" width={60} height={25} />
                                </Grid>
                            </Grid>
                            <Grid container item xs={8} direction="column">
                                <Grid item>
                                    <Skeleton variant='text' animation="wave" width={60} />
                                    {/* <Typography variant="subtitle1" className={classes.recentAlert}>True Matches</Typography> */}
                                </Grid>
                                <Grid item>
                                    <Skeleton variant='text' animation="wave" sx={{ fontSize: '1.5rem' }} width={20} />
                                    {/* <Typography variant="h4">{allStatus.map((item) => item.alertstatus.code === "TrueMatch" ? item?.totalalertcount : '')}</Typography> */}
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>

            </Grid>




            {/* Third Row --chart section*/}
            <Grid container direction="column" sx={thirdRowStyles} justifyContent="space-between" alignItems="center">

                {/* first row -chart heading*/}

                <Grid item>
                    
                    <Skeleton variant='text' animation="wave" sx={{ fontSize: '2.5rem' }} width={200} />
                </Grid>

                {/* second row- chart row */}

                <Grid item sx={secondRowStyles} >
                    <Grid container>
                        {/* Barchart  */}
                       
                        <Grid item xs={8} style={{paddingLeft:"50px"}} >
                            <div style={{width:"90%", marginLeft:"100px",paddingTop:"100px"}}>
                            <Grid container spacing={15}>
                                <Grid item xs={2} >
                                    <Skeleton variant='rounded' animation="wave"  width={60} height={400} />
                                </Grid>
                                <Grid item xs={2}>
                                    <Skeleton variant='rounded' animation="wave"  width={60} height={400} />
                                </Grid>
                                <Grid item xs={2}>
                                    <Skeleton variant='rounded' animation="wave"  width={60} height={400} />
                                </Grid>
                                <Grid item xs={2}>
                                    <Skeleton variant='rounded' animation="wave"  width={60} height={400} />
                                </Grid>
                            </Grid>
                            </div>
                            
                        </Grid>
                        
                        

                        {/* Pie Charts */}
                        <Grid item xs={4}  direction="column" justifyContent="space-between" >
                            <Grid container style={{    width: "80%" ,marginLeft: "50px"}}>
                                <Grid item sx={piechartCardStyles} >
                                    <Grid container direction="row">
                                        <Grid item xs={6} >
                                            <Skeleton variant='rounded' animation="wave" width={100} />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Skeleton variant='rounded' animation="wave" width={100} />
                                        </Grid>
                                        <Grid item xs={12} m={2} ml={6}>
                                            <Skeleton variant='circular' animation="wave" width={160} height={160} />
                                        </Grid>
                                        <Grid item xs={12} ml={3}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Skeleton variant='text' animation="wave" width={80} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Skeleton variant='text' animation="wave" width={80} />

                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item sx={piechartCardStyles} style={{marginTop:"15px"}}>
                                    <Grid container direction="row">
                                        <Grid item xs={6} >
                                            <Skeleton variant='rounded' animation="wave" width={100} />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Skeleton variant='rounded' animation="wave" width={100} />
                                        </Grid>
                                        <Grid item xs={12} m={2} ml={6}>
                                            <Skeleton variant='circular' animation="wave" width={160} height={160} />
                                        </Grid>
                                        <Grid item xs={12} ml={3}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Skeleton variant='text' animation="wave" width={80} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Skeleton variant='text' animation="wave" width={80} />

                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



        </Container>
    )
}

export default DashboardSkelton
import axios from "axios";
const jsonConfig = require("../../config.json");

const getAllAlertData = async (payload) => {
  try {
    const getAllResponse = await axios.post(jsonConfig.apiUrl + "allalertdata",payload, {
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default getAllAlertData;
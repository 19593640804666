export  let dayChartOptions={   
    "dataLabels": {
        "enabled": true,
        "offsetY": 4,
        "style": {
            "fontSize": "12px",
            "fontFamily": "Montserrat",
            "fontWeight": 600,
            "colors": [
                "#0F1012",
                "#0F1012",
                "#0F1012",
            ]
        }
    },
    "annotations": {
        "position": "front"
    },
    "chart": {
        "stacked": true,
        "background": "#fff",
        "toolbar": {
            "show": false
        },
        "fontFamily": "Montserrat",
        "fontWeight": 800
    },
    "fill": {
        "colors": "#000",
        "opacity": 1,
        "type": "solid"
    },
    "plotOptions": {
        "bar": {
            "borderRadius": 4,
            "columnWidth": 35,
            "color": "#000"
        },
        "dataLabels": {
            "position": "center",            
            "orientation": "horizontal",
            "maxItems": 100,
            "hideOverflowingLabels": true
        }
    },
    "tooltip": {
        "followCursor": true
    },
    "xaxis": {       
        
        "categories": []
    },   
    "legend": {
        "show": true,
        "position": "top",
        "horizontalAlign": "right",
        "fontSize": "15px",
        "fontFamily": "Montserrat",
        "fontWeight": 500,
        "markers": {
            "width": 24,
            "height": 12,
            "radius": 12
        },
        "itemMargin": {
            "horizontal": 10,
            "vertical": 0
        }
    },
    "title": {
        "text": "Quarterly Reviews",
        "style": {
            "color": "#0F1012",
            "fontSize": "18px",
            "fontWeight": 700,
            "fontFamily": "Montserrat"
        }
    },
    "grid": {
        "borderColor": "#0F1012"
    }
}
import React, { useState, useEffect, useRef } from "react";
import getAllAlertByIdResponse from "../../apis/alerts/getalertById";
import getAllAiDecition from "../../apis/alerts/getAllAiDecition";
import getAllAiDecisionReasons from "../../apis/alerts/getAllAiDecisionReasons";
import { Container, FormControl, Grid, OutlinedInput, Typography, IconButton, Autocomplete, TextField, Dialog, Button, DialogActions, DialogContent, DialogTitle, TextareaAutosize } from '@mui/material';
import './alerts.css';
import Badge from '@mui/material/Badge';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import { NotificationContainer, NotificationManager, } from "react-notifications";
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CloseIconAlert from "../alerts/images/close.svg";
import RefreshIconAlert from "../alerts/images/refresh.svg";
import attachExcel from "../alerts/images/kindpng_1338383.png";
import attachxlsx from "../alerts/images/clipart2414601.png";
import Header from "../Header/Header"
import updateAlertResponse from "../../apis/alerts/updateAlert";
import { ArrowRight, Attachment, Circle, Close, DisabledByDefault, PhotoCamera, SkipNext, SkipPrevious } from "@mui/icons-material";
import getAllDecision from "./../../apis/getAllDecision/getAllDecision"
import StepsIcon from "./../../resources/images/StepIcon.svg";
import { DateTime } from "luxon";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from 'react-router-dom';
import { height } from "@mui/system";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { blue } from "@mui/material/colors";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/lab";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { BlobServiceClient, StorageSharedKeyCredential } from "@azure/storage-blob";
import jsonConfig from '../../config.json';
import Skeleton from '@mui/material/Skeleton';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import getsuppressAlertResponse from "../../apis/alerts/suppressAlertByAlertId";
import suppressAlertResponse from "../../apis/alerts/suppressAlert";
import updateSupressAlert from "../../apis/alerts/updateSupressAlertById";
import attach_close_icon from "../alerts/images/attach_close_icon.png";
import attach_pdf from "../alerts/images/attach_pdf_icon.png";
import backgroundPic from "./../../resources/images/Background.svg"
import getaidecisionreasonsbycategory from "../../apis/alerts/getaidecisionreasonsbycategory";
import getAllsupressalertbymanagerId from "../../apis/ApproveSuppression/ApproveSuppression";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { tabStyles, dropdownStyles, tabHeadStyles } from "./alertStyles";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WcIcon from '@mui/icons-material/Wc';
import BadgeIcon from '@mui/icons-material/Badge';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import PublicIcon from '@mui/icons-material/Public';
import countries from "country-list";
import usStates from 'us-state-codes';


const useStyles = makeStyles({
    marginPaddingFix: {
        margin: "0px !important",
        padding: "0px !important",
    },
    marginPaddingFixMiddle: {
        margin: "0px",
        padding: "0px 20px 0px 0px",
    },
    marginPaddingFixBottom: {
        margin: "0px",
        padding: "0px 20px 20px 0px",
    },
    labelHeading: {
        fontSize: "13px",
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: "bold",
        marginBottom: '3px'
    },
    cardContent: {
        paddingLeft: "20px",
        marginTop: "20px"
    },
    dialogBoxAction: {
        backgroundColor: "#000000",
        "&:hover": {
            opacity: "0.8",
            backgroundColor: "#000000",
        }
    },
    dialogBoxAction2: {
        backgroundColor: "#000000",
        marginRight: "14px",
        "&:hover": {
            opacity: "0.8",
            backgroundColor: "#000000",
        }
    },
    labelDesc: {
        fontFamily: "'Source Sans Pro', sans-serif",
        fontSize: "11px",
        fontWeight: 500,
        opacity: "0.75",
    },
    pageContainer: {
        backgroundImage: `url(${backgroundPic})`,
        backgroundSize: "cover"
    },
    dropdownStyle: {
        borderRadius: "10px"
    },
    clipboard: {
        display: "inline-block",
        paddingLeft: "0.5em",

    },
    clipIcon: {
        color: "grey"
    }
})


const Alerts = ({ userContext }) => {
    const navigate = useNavigate();

    const [value, setValue] = React.useState('1');
    const location = useLocation()
    const [valueDate, setValueDate] = React.useState(dayjs());
    const [allsupressalert, setallsupressalert] = useState([]);
    const [isWarn, setIsWarn] = useState(false);

    const [show, setShow] = useState(false);
    const onClick = () => setShow(true);

    const finaldecesion = [
        { label: 'True Match' },
        { label: 'False Positive' },

    ];
    const [copied, setCopied] = useState("Click to copy the Alert ID")

    const [aidecitionList, setAiDecitionList] = useState([]);
    const [aidecitionresonsList, setAiDecitionReasonsList] = useState([]);
    const [dataForUpdate, setdataForUpdate] = useState({})
    const [finalDecisionObj, setfinalDecisionObj] = useState({});
    const [finalReasonObj, setfinalReasonObj] = useState({})
    const [open, setOpen] = useState(false)
    const [allDecision, setAllDecision] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [suppressAlertBox, setSuppressAlertBox] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [dataForUpdateOfAnalyst, setdataForUpdateOfAnalyst] = useState({})
    const [selectedDocForAnalyst, setselectedDocForAnalyst] = useState([]);
    const [selectedDocUrlForAnalyst, setselectedDocUrlForAnalyst] = useState([])
    const [selectedDocForManeger, setselectedDocForManeger] = useState([])
    const [selectedDocUrlForManerger, setselectedDocUrlForManerger] = useState([])
    const [analystName, setanalystName] = useState("");
    const [analystSuppresedDate, setanalystSuppresedDate] = useState("");
    const [isAlertSuppresed, setisAlertSuppresed] = useState(false);
    const [analystDataExists, setanalystDataExists] = useState(false);
    const [manegerDescription, setmanegerDescription] = useState("");
    const [managerName, setmanagerName] = useState("");
    const [manegerSuppressAlertDate, setmanegerSuppressAlertDate] = useState(DateTime.now().toFormat(''));
    const [supressAlertId, setsupressAlertId] = useState("");
    const [isalertApproved, setisalertApproved] = useState(null);
    const [analystComments, setAnalystComments] = useState("")
    const [customerId, setCustomerId] = useState("");
    const upload = useRef(null)
    const [aidecisionreasonsbycategorydata, setaidecisionreasonsbycategory] = useState([]);

    const classes = useStyles();
    const [reasonDisabled, setReasonDisabled] = useState(true)
    const [actionjson, setActionJson] = useState([])
    const [managerDataAvailable, setManagerDataAvailable] = useState(false)
    const [watchlistArr, setWatchlistArr] = useState([])
    const [currentWatchlistData, setCurrentWatchlistData] = useState([])


    const [currentWatchlistIndex, setCurrentWatchlistIndex] = useState(0)
    const [watchlistreasonObj, setwatchlistReasonObj] = useState([])
    const [watchlistaiDecisionObj, setwatchlistaiDecisionObj] = useState([])
    const [currentwatchlistreason, setcurrenrwatchlistreason] = useState('')
    const [currentwatchlistaiDecision, setcurrentwatchlistaiDecision] = useState('')
    const [allCountryData, setAllCountryData] = useState(countries.getData());


    const [customerData, setCustomerData] = useState([])
    const [subdecision,setSubdecision] = useState('')

    const getaidecition = async () => {
        try {
            const res = await getAllAiDecition();
            const data = res.data.data;
            setAiDecitionList(data)

        } catch (error) {
            console.log(error.response.data);
        }
    };


    const handleChange2 = (event, newValue) => {
        setValue(newValue);
    };

    const getaidecitionresons = async () => {
        try {
            const res = await getAllAiDecisionReasons();
            const data = res.data.data;
            setAiDecitionReasonsList(data)

        } catch (error) {
            console.log(error.response.data);
        }
    };
    const suppressConfirm = () => {
        suppressAlertData(location.state.id)
        setSuppressAlertBox(false);
    }

    useEffect(() => {
        getaidecition();
        getaidecitionresons();
        let allCountriesData = allCountryData
        allCountriesData.push({
            "code": "USA",
            "name": "United States of America"
        })
        setAllCountryData(allCountriesData);
    }, []);


    /*----------- Table API ---------------*/

    const [alertData, setAllAlert] = useState([]);

    useEffect(() => {
        getAllAlertByIdData();
    }, []);

    const getAllAlertByIdData = async () => {
        try {
            let alerts = await getAllAlertByIdResponse(location.state.id)
            let alertDataResult = alerts?.data?.data;
            setfinalDecisionObj(alertDataResult[0]?.finalstatus)
            setfinalReasonObj(alertDataResult[0])
            setActionJson(JSON.parse(alertDataResult[0].actionsjson))
            setCustomerData(alertDataResult[0]?.customers)
            setSubdecision(alertDataResult[0]?.subdecision)
            setAllAlert(alertDataResult)
            let alertJ = alertDataResult[0]?.decisiontreejson
            setAlerts(JSON.parse(alertJ));
            setCustomerId(alertDataResult[0]?.customerId)
            let results = await getAllDecision();
            setAllDecision(results?.data?.data);
            setWatchlistArr(alertDataResult[0]?.watchlistjson)
            setCurrentWatchlistData((alertDataResult[0]?.watchlistjson)[0])
            setcurrenrwatchlistreason((alertDataResult[0]?.watchlistjson)[0]?.watchlist_reason)
            setcurrentwatchlistaiDecision((alertDataResult[0]?.watchlistjson)[0]?.watchlist_decision)
        } catch (error) {
            console.log("error", error)
        }

    };


    const getDate = (arr) => {
        return `${arr[1]}-${arr[0]}-${arr[2]}`
    }
    const finalDecisionChangeHandler = async (e, value) => {
        setReasonDisabled(false);
        setfinalReasonObj([]);
        if (value.id) {
            setdataForUpdate((data) => ({
                ...data,
                finaldecisionID: value.id
            }))
            setfinalDecisionObj(value);
            if(value.code === "PendingManualReview" && alertData[0].finalstatus.code === "PendingManualReview"){
                setSubdecision(alertData[0].subdecision)
            }
            else {
                setSubdecision('')
            }
        }
        else {
            setdataForUpdate((data) => ({
                ...data,
                finaldecisionID: value.id
            }))
            setfinalDecisionObj({})
        }
    }
    const suppressAlertBoxOpen = async () => {
        await getSupressAlertById()
        setSuppressAlertBox(true)
    }
    const descriptionChangeHandler = (e, value) => {

        if (value.id) {
            setdataForUpdate((data) => ({
                ...data,
                description: value.reason
            }))
            setfinalReasonObj({ "description": value.reason });
            setSubmitDisabled(false)

        }
        else {
            setdataForUpdate((data) => ({
                ...data,
                description: ""
            }))
            setfinalReasonObj({})

        }

        if (value.reason === "See Custom Notes in 'Actions'") {
            suppressAlertBoxOpen();
        }

    }
    const updateAlert = async (id) => {
        const updateAlert = await updateAlertResponse(id, dataForUpdate)
        await getAllAlertByIdData()
        if (updateAlert.data.data.id) {
            await NotificationManager.success("Alert updated Successfully", "Success", 3000);
        }
        else {
            await NotificationManager.error("Something Went Wrong", "Error", 3000);
        }
        setSubmitDisabled(true);
        setReasonDisabled(true);


    }
    const suppressAlertData = async (id) => {
        setSubmitDisabled(true);
    }
    const getSubheadings = (subHeadingsCode) => {
        let values = [];
        for (let j = 0; j < subHeadingsCode.length; j++) {
            values.push({
                label: <div>
                    <span className="subHeadings">For Watchlist {subHeadingsCode.length === 1 ? "" : (j + 1)}</span>

                    {/* <span>Hello</span>
                    <span>Hello</span> */}
                    <div className="watchlistDecisions_text_div">
                        {subHeadingsCode[j].map((items) => {
                            return (<span className="watchlistDecisions_text">{(allDecision.filter((item) => item.code === items.code))[0].description}</span>)
                        })}
                    </div>
                </div>

            })
        }
        return (values);
    }
    const getDecisionStep = () => {
        let stepsData = []
        for (let i = 0; i < alerts.length; i++) {
            let filteredDecision = allDecision.filter((item) => item.code === alerts[i].code);
            if (filteredDecision.length > 0) {
                let tstamp = (alerts[i].timeStamp) * 1000
                let timeStamp = DateTime.fromMillis(tstamp)
                let zoneName = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4)
                let currentDateTime = DateTime.now();
                let diff = currentDateTime.diff(timeStamp, ["hours"]).toObject();
                diff = diff.hours.toString().split(".")[0];
                const formattedDate = DateTime
                    .fromMillis(tstamp)
                    .toFormat('T MM-dd-yyyy')
                if (alerts[i].subheading === undefined) {
                    stepsData.push(
                        {
                            label: <><div className="mainStepContainer">
                                <div><img src={StepsIcon} className="iconStep" /></div>
                                <div className="stepsData">
                                    <span className={classes.labelHeading}>{filteredDecision[0].description}</span>
                                    <span className={classes.labelDesc}>{formattedDate}, {zoneName} ({diff} hours ago)</span>
                                </div>

                            </div>
                                {i !== (alerts.length - 1) ? <><div className="stepConnector"></div></> : ""}

                            </>
                            ,
                        }
                    )
                }
                else {
                    let subHeadingsCode = alerts[i].subheading;
                    stepsData.push(
                        {
                            label: <><div className="mainStepContainer">
                                <div><img src={StepsIcon} className="iconStep" /></div>
                                <div className="stepsData">
                                    <span className={classes.labelHeading}>{filteredDecision[0].description}</span>
                                    <span className={classes.labelDesc}>{formattedDate}, {zoneName} ({diff} hours ago)</span>
                                </div>

                            </div>
                                {i !== (alerts.length - 1) ? <><div className="stepConnector">
                                    <div className="subHeadingContent">
                                        {getSubheadings(subHeadingsCode).map((values, index) => (
                                            <ul className="subHeadingGap">
                                                <li>{values.label}</li>
                                            </ul>
                                        ))}
                                    </div>
                                </div></> : ""}

                            </>
                            ,
                        }
                    )
                }
            }
        }
        return (stepsData)
    }
    const analystTextChange = (e) => {

        setAnalystComments(e.target.value);
    }
    const uploadToBlobStorage = async (blob, role) => {
        const blobStorageAccount = jsonConfig.blobStorageAccount;//azure storage account name
        const sas = jsonConfig.sasToken  //sas token for authentication
        const bsClient = new BlobServiceClient(
            `https://${blobStorageAccount}.blob.core.windows.net/?${sas}`
        );
        const containerClient = bsClient.getContainerClient("attachment");//container
        const blobName = userContext.id + '/' + blob?.name;
        const url = 'https://' + blobStorageAccount + '.blob.core.windows.net' + '/attachment/' + blobName;


        const blobClient = containerClient.getBlockBlobClient(blobName);
        const data = await blobClient.uploadBrowserData(blob.file);
        if (role === "Analyst") {
            selectedDocUrlForAnalyst.push(url)
        }
        else {
            selectedDocUrlForManerger.push(url)
        }


    }
    const fileSelectHandler = async (e, role) => {
        let arr = []
        const selectedFiles = e.target.files;

        for (let i = 0; i < selectedFiles.length; i++) {
            let obj = {}
            const downloadedUrl = URL.createObjectURL(selectedFiles[i]);
            let nameSplit = selectedFiles[i].name.split('.');
            obj.url = downloadedUrl
            obj.ext = nameSplit[nameSplit.length-1] 
            obj.name = selectedFiles[i].name
            obj.file = selectedFiles[i]
            arr.push(obj)
        }
        if (role === "Analyst") {

            setselectedDocForAnalyst((prevValue) => {
                if (prevValue.length >= 5) {
                    NotificationManager.error("You can add a maximum of 5 supporting documents", "Error", 3000)
                    return [...prevValue];
                }
                return [...prevValue, ...arr]
            })

        }
        else {
            setselectedDocForManeger((prevValue) => {
                if (prevValue.length >= 5) {
                    NotificationManager.error("You can add a maximum of 5 supporting documents", "Error", 3000)
                    return [...prevValue];;
                }
                return [...prevValue, ...arr]
            })
        }

        e.target.value = null;

    }
    const IsJsonString = (str) => {
        try {
            var locationjson = JSON.parse(str);

            if (Object.keys(locationjson).length > 0) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    const getSupressAlertById = async () => {
        try {
            let supressAlertResponse = await getsuppressAlertResponse(location.state.id)
            if (actionjson.analyst.length > 0) {
                setanalystDataExists(true)
                let analystData = actionjson.analyst
                analystData = analystData[analystData.length - 1]
                setAnalystComments(analystData.comment)
                const formattedDate_analyst = DateTime
                    .fromMillis((analystData.timestamp) * 1000)
                    .toFormat('MM-dd-yyyy hh:mm:ss')
                setanalystSuppresedDate(formattedDate_analyst)

                let analystAttachments = JSON.parse(analystData.attachmentUrl)
                if (analystAttachments.length > 0) {
                    let arr = analystAttachments
                    let suppressAlertDoc = []
                    for (let index = 0; index < arr.length; index++) {
                        let nameSplit = (arr[index].split("/")[5]).split('.')
                        let obj = {}
                        obj.url = arr[index]
                        obj.name = arr[index].split("/")[5]
                        obj.ext = nameSplit[nameSplit.length - 1]
                        suppressAlertDoc.push(obj)
                    }
                    setselectedDocForAnalyst(suppressAlertDoc)
                }
                setanalystName(analystData.updatedBy)
            }

            if (actionjson.manager.length > 0) {
                let managerData = actionjson.manager
                managerData = managerData[managerData.length - 1]
                let managerAttachments = JSON.parse(managerData.attachmentUrl)
                if (managerAttachments.length > 0) {
                    let arr = managerAttachments
                    let suppressAlertDoc = []
                    for (let index = 0; index < arr.length; index++) {
                        let nameSplit = (arr[index].split("/")[5]).split('.')
                        let obj = {}
                        obj.url = arr[index]
                        obj.name = arr[index].split("/")[5]
                        obj.ext = nameSplit[nameSplit.length - 1]
                        suppressAlertDoc.push(obj)
                    }
                    setselectedDocForManeger(suppressAlertDoc)
                }
                setmanegerDescription(managerData.comment)
                const formattedDate_manager = DateTime
                    .fromMillis((managerData.timestamp) * 1000)
                    .toFormat('MM-dd-yyyy hh:mm:ss')
                setmanegerSuppressAlertDate(formattedDate_manager)
                setmanagerName(managerData.updatedBy)
                setManagerDataAvailable(true)
            }
            if (supressAlertResponse.data.data.length > 0) {

                setValueDate(supressAlertResponse.data.data[0].toDate)
                setisAlertSuppresed(supressAlertResponse.data.data[0].isSuppressed)

                setsupressAlertId(supressAlertResponse.data.data[0].id)
                setisalertApproved(supressAlertResponse.data.data[0].isApproved)
            }
            else {
                setisalertApproved(null)
                setsupressAlertId("")
                setisAlertSuppresed(false)
                setdataForUpdateOfAnalyst({})
            }

        } catch (error) {
            console.log(error)
        }


    }
    const addsuppressConfirm = async (e) => {


        let payload = {

            "toDate": isAlertSuppresed ? valueDate.toString() : null,
            "customerId": customerId,
            "analystComments": analystComments,
            "suppressedBy": userContext.id,

            "managerAttachmentUrl": "",
            "isSuppressed": isAlertSuppresed,
            "analystId": userContext.id,
            "alertId": location.state.id,
            "createdBy": userContext.id,
            "updatedBy": userContext.id
        }


        if (payload.analystComments) {
            for (let index = 0; index < selectedDocForAnalyst.length; index++) {
                await uploadToBlobStorage(selectedDocForAnalyst[index], "Analyst")

            }
            payload.analystAttachmentUrl = JSON.stringify(selectedDocUrlForAnalyst)
            let suppressResponse = await suppressAlertResponse(payload)
            if (suppressResponse.data.data) {
                isAlertSuppresed ? NotificationManager.success("Manager has been notified and suppression request is pending", "Success", 3000) :
                    NotificationManager.success("Alert is Successfully Updated", "Success", 3000)
                setSuppressAlertBox(false)
            }
            else {
                NotificationManager.error("Alert already added!", "Error", 3000)
            }
        }
        else {
            NotificationManager.error("Description is required", "Error", 3000)
        }


    }
    const manegerdesOnchangeHandler = (e) => {
        setmanegerDescription(e.target.value)
    }

    const getstatename = (statecode) => {
        let statename = statecode;
        let matchingStates = usStates.getStateNameByStateCode(statecode)
        if (matchingStates!==null) {
            statename = matchingStates
        } else {
            statename = statecode
        }
        return (statename);

    }

    const getcountryname = (countrycode) => {
        let countryname = countrycode;

        let matchingCountry = allCountryData.filter(countries => countries.code === countrycode);
        if (matchingCountry.length > 0) {
            countryname = matchingCountry[0].name
        } else {
            countryname = countrycode
        }
        return (countryname)
    }

    const managerCommentHandler = async (e) => {
        for (let index = 0; index < selectedDocForManeger.length; index++) {
            await uploadToBlobStorage(selectedDocForManeger[index], "Maneger")
        }
        let payload = {
            finaldecisionID: finalDecisionObj.id,
            managerComments: manegerDescription,
            managerAttachmentUrl: JSON.stringify(selectedDocUrlForManerger),
            description: null,
            updatedBy: userContext.id,
            subdecision:subdecision,
        }
        let res = await updateAlertResponse(location.state.id, payload)
        await getAllAlertByIdData()
        setSuppressAlertBox(false)
        NotificationManager.success("Alert Updated Successfully", "Success", 3000);
    }

    const managerSubmitHandler = async (e, type) => {

        for (let index = 0; index < selectedDocForManeger.length; index++) {
            await uploadToBlobStorage(selectedDocForManeger[index], "Maneger")

        }
        let payload = {
            "managerComments": manegerDescription,
            "isApproved": type,

            "managerAttachmentUrl": JSON.stringify(selectedDocUrlForManerger),
            "updatedBy": userContext.id
        }
        if (!isAlertSuppresed) {
            payload.isSuppressed = false
        }
        if (!payload.isApproved) {
            const updatesupressAlert = await updateSupressAlert(supressAlertId, payload)

            if (updatesupressAlert.data.data) {
                NotificationManager.success("Alert has been reviewed by the manager", "Success", 3000)
                setIsWarn(false);
                setSuppressAlertBox(false)
                await getAllAlertByIdData()
                return;
            }
            return;
        }
        if (payload.managerComments) {
            const updatesupressAlert = await updateSupressAlert(supressAlertId, payload)

            if (updatesupressAlert.data.data) {
                NotificationManager.success("Alert suppresed", "Success", 3000)
                setIsWarn(false);

                await getAllAlertByIdData()
                setSuppressAlertBox(false)
                return;
            }
            return;
        }
        else {
            NotificationManager.error("Description is required", "Error", 3000)
        }


    }
    const viewDoc = (event, url) => {
        window.open(url, "_blank");
    }
    const removeHandler = (e, role) => {
        if (role == "Analyst") {
            let filterArr = selectedDocForAnalyst.filter((el) => el.url != e.url)
            setselectedDocForAnalyst(filterArr)
        }
        else {
            let filterArr = selectedDocForManeger.filter((el) => el.url != e.url)
            setselectedDocForManeger(filterArr)
        }
    }


    const tableLabels = ["Customer Name", "Date of Birth", "Gender", "Address", "Location", "Nationality", "SSN Number / TIN Number", "A / c Number", "Social Media / Linked Accounts", "Known Associates", "Known Violations", "Media Information"]
    const tabLabels = ["firstname", "dob", "gender", "streetAddress", "location", "nationality", "ssnNumber", "accountNo", "socialMedia", "", "", ""];


    const alertSuppressRadioButtonHandler = (e) => {
        if (alertData[0]?.aidecisionstatus.name === "Pending Manual Review" && finalDecisionObj?.name === "True Match") {
            NotificationManager.error("This alert can't be suppressed", "Error", 4000)
        }
        else {
            setisAlertSuppresed(!isAlertSuppresed);
        }
    }


    const goToAlertKendo = (e) => {
        navigate(-1)
    }


    const watchlistChangeHandler = (func) => {
        if (func === "next") {
            setCurrentWatchlistData(watchlistArr[currentWatchlistIndex + 1])
            setCurrentWatchlistIndex(currentWatchlistIndex + 1)
            setcurrentwatchlistaiDecision(watchlistArr[currentWatchlistIndex + 1]?.watchlist_decision)
            setcurrenrwatchlistreason(watchlistArr[currentWatchlistIndex + 1]?.watchlist_reason)
        }
        else if (func === "prev") {
            setCurrentWatchlistData(watchlistArr[currentWatchlistIndex - 1])
            setCurrentWatchlistIndex(currentWatchlistIndex - 1)
            setcurrentwatchlistaiDecision(watchlistArr[currentWatchlistIndex - 1]?.watchlist_decision)
            setcurrenrwatchlistreason(watchlistArr[currentWatchlistIndex - 1]?.watchlist_reason)
        }
    }

    const getCurrentWatchlistReason = () => {
        let currentWcreason = currentwatchlistreason ? (aidecitionresonsList.filter((item) => item.id === currentwatchlistreason))[0]?.reason.trim() : ''
        let wrappedtxt = '';
        if (currentWcreason && currentWcreason.length > 45) {
            wrappedtxt += currentWcreason.substring(0, 42) + '...'
        }
        else wrappedtxt = currentWcreason
        return wrappedtxt
    }

    const getCustomerAddress = () => {
        let streetAddress = " "
        streetAddress += customerData?.ADDRESS ? customerData?.ADDRESS : "";
        let city = customerData?.CITY ? customerData?.CITY : ""; 
        let zoneCode = customerData?.ZONE ? (" "+customerData?.ZONE) : "";
        let postalCode = customerData?.POSTAL_CODE ? (" "+customerData?.POSTAL_CODE) : "";
        let COUNTRY_OF_RESIDENCE = customerData?.COUNTRY_OF_RESIDENCE ? (" "+customerData?.COUNTRY_OF_RESIDENCE) : "";
        let combinedAddress = city + zoneCode + postalCode + COUNTRY_OF_RESIDENCE;
        streetAddress = streetAddress.replace(new RegExp(combinedAddress, 'g'), '');
        if(streetAddress ===  " "+customerData?.ADDRESS){
            postalCode = customerData?.POSTAL_CODE ? (" 0"+customerData?.POSTAL_CODE) : "";
            combinedAddress = city + zoneCode + postalCode + COUNTRY_OF_RESIDENCE;
            streetAddress = streetAddress.replace(new RegExp(combinedAddress, 'g'), '');
        }
        streetAddress = streetAddress.trim();
        zoneCode = zoneCode.trim();
        postalCode = postalCode.trim();
        COUNTRY_OF_RESIDENCE = COUNTRY_OF_RESIDENCE.trim();
        if(COUNTRY_OF_RESIDENCE && COUNTRY_OF_RESIDENCE.length <=3){
            COUNTRY_OF_RESIDENCE = getcountryname(COUNTRY_OF_RESIDENCE)
        }
        if(zoneCode && zoneCode.length <=3){
            zoneCode = getstatename(zoneCode)
        }
        let fullAddress = " "+ (streetAddress ? streetAddress+", " : '') + (city ? city+", " : '') + (zoneCode ? zoneCode+", " : '') + (COUNTRY_OF_RESIDENCE ? COUNTRY_OF_RESIDENCE : '')
        return fullAddress;
    }


    return (
        <div className={classes.pageContainer}>
            <div className="BodyWithFooter" >
                <div className="ContentBody">
                    <Header />
                    <Container>
                        <div className="alert_wrapper">
                            <div className="alert_top_section">
                                <div className="alert_back" >
                                    <span className="cp" style={{ color: 'white' }} onClick={(e) => goToAlertKendo(e)} ><ArrowBackIcon /></span>
                                </div>
                                {console.log("location", location)}
                                <div className="alert_top_title_section">
                                    <div className="title">
                                        ALERT ID: {location.state.id.split("-")[0].substring(0, 4)}
                                        <CopyToClipboard text={location.state.id}
                                            onCopy={() => setCopied("Alert ID Copied")}                                        >
                                            <Tooltip title={copied} placement="right-end" arrow>
                                                <div className={classes.clipboard}>
                                                    <ContentCopyIcon className={classes.clipIcon} fontSize="small" />
                                                </div>
                                            </Tooltip>
                                        </CopyToClipboard>
                                    </div>
                                    <div className="button_area">
                                        <button className="alert_top_button_blue_border" onClick={() => { setOpen(true) }}>ALFA Decision Tree</button>
                                        {
                                            (isWarn) ? <>
                                                <Badge badgeContent={"!"} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    color='error' className="anchorOriginTopRight" >
                                                    <button className="alert_top_button_warn action_pending_button" onClick={suppressAlertBoxOpen}>Actions</button>
                                                </Badge>
                                            </>
                                                : <button className="alert_top_button_blue_border" onClick={suppressAlertBoxOpen}>Actions</button>

                                        }
                                        {submitDisabled ?
                                            <button className="disabledSubmit" disabled={true}>Submit</button> :
                                            <button className="enabledSubmit" onClick={() => updateAlert(location.state.id)} disabled={submitDisabled}>Submit</button>}

                                    </div>
                                </div>

                                <div className="alert_top_form_section">
                                    <div className="field">
                                        <Grid>
                                            <Typography variant='body1' className="field_title" component='h2'>
                                                AI DECISION
                                            </Typography>
                                            <FormControl fullWidth variant='outlined' className="testtttttttt">
                                                <OutlinedInput
                                                    className='admin_textbox'
                                                    // required
                                                    value={currentwatchlistaiDecision ? (aidecitionList.filter((item) => item.id === currentwatchlistaiDecision))[0]?.name : ""}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Grid>
                                    </div>
                                    <div className="field field_finalDecision">
                                        <Grid>
                                            <Typography variant='body1' className="field_title" component='h2'>
                                                FINAL DECISION
                                            </Typography>
                                            <Autocomplete
                                                className={classes.dropdownStyle}
                                                id="combo-box-demo"
                                                fullWidth
                                                options={aidecitionList}
                                                value={{ name: finalDecisionObj?.name ? finalDecisionObj?.name === 'Pending Manual Review' ? finalDecisionObj?.name + " (" + alertData[0].subdecision + ")" : finalDecisionObj?.name : "" }}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(e, value) => finalDecisionChangeHandler(e, value)}
                                                renderInput={(params) => <TextField {...params} placeholder="Select Final Decition" />}

                                            />

                                        </Grid>
                                    </div>

                                    <div className="field">
                                        <Grid>
                                            <Typography variant='body1' className="field_title" component='h2'>
                                                Reason
                                            </Typography>
                                            <Tooltip
                                                title={currentwatchlistreason ? (aidecitionresonsList.filter((item) => item.id === currentwatchlistreason))[0]?.reason.trim() : ''}
                                            >
                                                <OutlinedInput
                                                    className='admin_textbox'
                                                    id="combo-box-demo"
                                                    fullWidth
                                                    value={getCurrentWatchlistReason()}
                                                    disabled
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </div>


                                </div>
                            </div>

                            <div className="alert_middle_section">
                                <div className="alert_middle_top_header">
                                    <div className="alert_middle_top_header_left">
                                        {/* Empty Column */}
                                    </div>
                                    <div className="alert_middle_top_header_right">
                                        <div className="alert_table_heading">Customer Information </div>
                                    </div>

                                    <div className="alert_middle_top_header_right">
                                        <div className="alert_table_heading">
                                            <span>Watchlist Information {watchlistArr.length > 1 ? "(" + (currentWatchlistIndex + 1) + ")" : ""}</span>
                                            <div className="watchlistCategoryText">Category : {(currentWatchlistData?.watchlist_data?.watchlistInfo?.OCCUPATION) ? currentWatchlistData?.watchlist_data?.watchlistInfo?.OCCUPATION : "N/A"} </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="alert_middle_table">

                                    <table id="actual_table">
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Customer Name</p></div></td>
                                            <td>
                                                <p>

                                                    Not Available
                                                    {/* {
                                                        (customerData.FIRST_NAME ?
                                                            customerData.FIRST_NAME + " " : "")

                                                        + (customerData.MIDDLE_NAMES ?
                                                            customerData.MIDDLE_NAMES + " " : "")
                                                        +
                                                        (customerData.LAST_NAME ?
                                                            customerData.LAST_NAME : "")
                                                    } */}
                                                </p>
                                            </td>
                                            <td>

                                                <p>
                                                    {
                                                        (currentWatchlistData?.watchlist_data?.watchlistInfo?.FIRST_NAME ?
                                                            currentWatchlistData?.watchlist_data?.watchlistInfo?.FIRST_NAME + " " : "")

                                                        +
                                                        (currentWatchlistData?.watchlist_data?.watchlistInfo?.LAST_NAME ?
                                                            currentWatchlistData?.watchlist_data?.watchlistInfo?.LAST_NAME : "")

                                                    }
                                                </p>

                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Date of Birth</p></div></td>
                                            <td>
                                                <p>
                                                    {customerData?.DATE_OF_BIRTH ? DateTime.fromISO(customerData?.DATE_OF_BIRTH).toFormat('MM-dd-yyyy') : ''}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    {(currentWatchlistData?.watchlist_data?.watchlistInfo?.DOB) ? DateTime.fromISO(currentWatchlistData?.watchlist_data?.watchlistInfo?.DOB).toFormat('MM-dd-yyyy') : ''}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Gender</p></div></td>
                                            <td >

                                                <p>
                                                    {customerData?.GENDER_CODE}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    {/* {currentWatchlistData?.gender} */}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Address</p></div></td>
                                            <td >
                                                <p>
                                                {getCustomerAddress()}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    {
                                                        (currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_ADDRESS ?
                                                            currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_ADDRESS + "," : "")
                                                        +
                                                        (currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_CITY ?
                                                            currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_CITY + "," : "")
                                                        +
                                                        (currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_STATE ?
                                                            (
                                                                (currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_STATE).length <= 3 ?
                                                                    getstatename(currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_STATE) + "," : currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_STATE + ","
                                                            )
                                                            : "")
                                                        +
                                                        (currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_STATE ?
                                                            (
                                                                (currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_COUNTRY).length <= 3 ?
                                                                    getcountryname(currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_COUNTRY) : currentWatchlistData?.watchlist_data?.wcaddress?.LOCATION_COUNTRY
                                                            )
                                                            : "")
                                                    }
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">COUNTRY OF RESIDENCE</p></div></td>
                                            <td >
                                                <p>
                                                    {(customerData?.COUNTRY_OF_RESIDENCE ?
                                                        customerData?.COUNTRY_OF_RESIDENCE.length <= 3 ?
                                                            getcountryname(customerData?.COUNTRY_OF_RESIDENCE)
                                                            : customerData?.COUNTRY_OF_RESIDENCE
                                                        : "")
                                                    }
                                                    {/* {customerData?.COUNTRY_OF_RESIDENCE} */}
                                                </p>

                                            </td>
                                            <td>
                                                <p>
                                                    {/* {currentWatchlistData?.nationality} */}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Place of Birth</p></div></td>
                                            <td >
                                                <p>
                                                    {customerData?.PLACE_OF_BIRTH}
                                                </p>

                                            </td>
                                            <td>
                                                <p>
                                                    {currentWatchlistData?.watchlist_data?.watchlistInfo?.POB}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Marital Status</p></div></td>
                                            <td >
                                                <p>
                                                    {customerData?.MARITAL_STATUS}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    {/* {currentWatchlistData.watchlist_data.watchlistInfo.STATUS} */}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Occupation</p></div></td>
                                            <td >
                                                <p>
                                                    {customerData?.OCCUPATION}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    {currentWatchlistData?.watchlist_data?.watchlistInfo?.WC_POSITION}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Employment Status</p></div></td>
                                            <td >
                                                <p>
                                                    {customerData?.EMPLOYMENT_STATUS}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    {/* {currentWatchlistData.watchlist_data.watchlistInfo.STATUS} */}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Deceased Flag</p></div></td>
                                            <td >
                                                <p>
                                                    {customerData?.DECEASED_FLAG}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    {currentWatchlistData?.watchlist_data?.watchlistInfo?.DECEASED}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Age as on date</p></div></td>
                                            <td >
                                                <p>
                                                    {customerData?.AGE}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    {
                                                        ((currentWatchlistData?.watchlist_data?.watchlistInfo?.AGE) != null && (currentWatchlistData?.watchlist_data?.watchlistInfo?.AGE) != '') ?
                                                            ((currentWatchlistData?.watchlist_data?.watchlistInfo?.AGE) +
                                                                " (" + DateTime.fromISO(currentWatchlistData?.watchlist_data?.watchlistInfo?.AGE_DATE).toFormat('MM-dd-yyyy')) + ")"
                                                            : ''
                                                    }
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Account Opening Date</p></div></td>
                                            <td >
                                                <p>{customerData?.primaryaccount ?


                                                    customerData?.primaryaccount[0]?.DATE_OPENED ? DateTime.fromISO(customerData?.primaryaccount[0]?.DATE_OPENED).toFormat('MM-dd-yyyy') : '' : " "}
                                                    {/* {customerData.ACQUISITION_DATE} */}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    {/* {currentWatchlistData.watchlist_data.watchlistInfo.ACQUISITION_DATE} */}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className="alert_table_row" >
                                            <td className="text-left-align"><div className="alert_table_label_wrapper"><p className="alert_table_labels">Media Information</p></div></td>
                                            <td >
                                                <p>
                                                    {/* {customerData.EMPLOYMENT_STATUS} */}
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    {currentWatchlistData?.watchlist_data?.watchlistInfo?.FURTHER_INFORMATION}
                                                </p>
                                            </td>
                                        </tr>
                                    </table>

                                </div>

                            </div>
                            <div className="alert_table_footer">
                                {watchlistArr.length > 1 ?
                                    <div className="watchlistCellMainDiv">
                                        <span style={{ marginRight: '10px', fontSize: '14px', fontWeight: 600 }}>Watchlist hits :</span>
                                        <span className="watchlistCount">{currentWatchlistIndex + 1} of {watchlistArr.length} items</span>
                                        {currentWatchlistIndex === 0 ? <div className="watchlistChangerDiv_disabled"><SkipPrevious style={{ opacity: 0.5 }} /> <span>Prev</span></div>
                                            : <div className="watchlistChangerDiv" onClick={(e) => watchlistChangeHandler("prev")}><SkipPrevious /> <span>Prev</span></div>
                                        }
                                        {
                                            currentWatchlistIndex === watchlistArr.length - 1 ?
                                                <div className="watchlistChangerDiv_disabled"><span>Next</span><SkipNext /></div>
                                                : <div className="watchlistChangerDiv" onClick={(e) => watchlistChangeHandler("next")}><span>Next</span><SkipNext /></div>
                                        }
                                    </div>
                                    : ""}
                            </div>
                        </div>

                    </Container>
                    {/* ALFA Decision Tree Dialog */}
                    <Dialog
                        open={open}
                        onClose={() => { setOpen(false) }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={{
                            style: {
                                width: "747px",
                                height: "713px",
                            }
                        }}
                    >
                        <DialogTitle className={classes.marginPaddingFix}>
                            <div className='cardHeader'>
                                <div className='headerTexts'>
                                    <span className='headerContent'>ALFA Decision Tree</span>
                                    <div className="rightSection_DialogHeader">
                                        <span className='headerContent'>
                                            Alert ID: {location.state.id.split("-")[0].substring(0, 4)}
                                        </span>
                                        <IconButton className="closeBox" onClick={() => { setOpen(false) }}>
                                            <Close className="iconButton" />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className='hrLine'>
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent className={classes.marginPaddingFixMiddle}>
                            <div className={classes.cardContent}>
                                {getDecisionStep().map((step, index) => (
                                    <div>{step.label}</div>
                                ))}

                            </div>
                        </DialogContent>
                        <DialogActions className={classes.marginPaddingFixBottom}>
                            <Button onClick={() => { setOpen(false) }} className={classes.dialogBoxAction} variant="contained">Close</Button>
                        </DialogActions>
                    </Dialog>

                    {/* Supress Alerts Dialog */}

                    <Dialog
                        open={suppressAlertBox}
                        onClose={(e) => { setSuppressAlertBox(false) }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={{
                            style: {
                                maxWidth: "unset",
                                width: "800px",
                                height: "600px"
                            }
                        }}
                    >
                        <DialogTitle className={classes.marginPaddingFix}>
                            <div className='cardHeader_suppress'>
                                <span className='headerContent_supress'>Actions</span>
                                <IconButton className="closeBox" onClick={() => { setSuppressAlertBox(false) }}>
                                    <Close className="iconButton_supress" />
                                </IconButton>
                            </div>
                        </DialogTitle>
                        <DialogContent className="main_content_supress">


                            <Accordion className="accordian_header_wrapper" defaultExpanded={userContext.rolename === "Analyst" ? true : false}>
                                <AccordionSummary
                                    className="accordian_header"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography ><div>Analyst Review</div></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="suppressAlertContent">
                                            <div className="supress_left">
                                                <div className="Suppress_Alert_text">Suppress Alert</div>
                                                <div className="supress_chkbox_section">
                                                    {
                                                        ["true", "false"].map((el, i) =>
                                                            <div className={el === "true" ? "" : "ml_10"} key={i}>
                                                                <input type="radio"
                                                                    id={el}
                                                                    name="radio-group"
                                                                    key={i}
                                                                    value={!isAlertSuppresed ? "false" : "true"}
                                                                    checked={el === isAlertSuppresed.toString()}
                                                                    disabled={analystDataExists || userContext.rolename != "Analyst"}
                                                                    onClick={alertSuppressRadioButtonHandler}
                                                                />
                                                                <label htmlFor={el}>{el === "true" ? "Yes" : "No"}</label>
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                                <div className="supress_other_text">Date: {analystSuppresedDate}</div>
                                                <div className="supress_other_text">Username: {analystName}</div>

                                                <div className="textContainer supress_text_area">
                                                    <div className="supress_document_section">
                                                        <div className="textHeader_supress">Documentation</div>
                                                        <div className="file_attach">
                                                            <div className="attach_text">Attachments</div>
                                                            <input accept=".pdf,.docx,.doc" multiple="multiple" id="icon-button-file"
                                                                type="file" style={{ display: 'none' }} disabled={analystDataExists || userContext.rolename != "Analyst"} onChange={(e) => fileSelectHandler(e, "Analyst")} />
                                                            <div className="supress_attach_section">
                                                                <label htmlFor="icon-button-file" className="labelattach">
                                                                    <Attachment className="attachmentIcon" />
                                                                </label>

                                                                {
                                                                    selectedDocForAnalyst?.map((e) => ((e.ext === "docx" || e.ext === 'doc') ?
                                                                        <div className="attach_image_wrapper">
                                                                            <button className="attach_close_icon"
                                                                                type="button"
                                                                                disabled={analystDataExists || userContext.rolename != "Analyst"}
                                                                                onClick={(el) => removeHandler(e, "Analyst")}
                                                                            >
                                                                                <img className="close_icon" src={attach_close_icon}

                                                                                />
                                                                            </button>
                                                                            <img className="attach_icon" src={attachExcel}
                                                                                onClick={(el) => viewDoc(el, e.url)}
                                                                            />
                                                                        </div>

                                                                        :
                                                                        e.ext === "pdf" ?
                                                                            <div className="attach_image_wrapper">
                                                                                <button className="attach_close_icon" type="button" disabled={analystDataExists || userContext.rolename != "Analyst"} onClick={(el) => removeHandler(e, "Analyst")}>
                                                                                    <img className="close_icon" src={attach_close_icon}

                                                                                    />
                                                                                </button>
                                                                                <img className="attach_icon" src={attach_pdf}
                                                                                    onClick={(el) => viewDoc(el, e.url)}
                                                                                />
                                                                            </div>
                                                                            :
                                                                            <div className="attach_image_wrapper">
                                                                                <button className="attach_close_icon" type="button" disabled={analystDataExists || userContext.rolename != "Analyst"} onClick={(el) => removeHandler(e, "Analyst")}>
                                                                                    <img className="close_icon" src={attach_close_icon}
                                                                                        onClick={(el) => removeHandler(e, "Analyst")}
                                                                                    />
                                                                                </button>
                                                                                <img className="attach_icon" src={attachxlsx}
                                                                                    onClick={(el) => viewDoc(el, e.url)}
                                                                                />
                                                                            </div>
                                                                    ))
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        multiline
                                                        rows={4}
                                                        disabled={analystDataExists || userContext.rolename != "Analyst"}
                                                        defaultValue={analystComments}
                                                        onChange={(e) => analystTextChange(e)}
                                                    />


                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    isAlertSuppresed ?
                                                        <>
                                                            <div className="datePicker">
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <StaticDatePicker
                                                                        value={valueDate}
                                                                        disablePast
                                                                        disabled={analystDataExists}
                                                                        onChange={(newValue) => {
                                                                            setValueDate(newValue);
                                                                        }}
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                    />
                                                                </LocalizationProvider>


                                                            </div>


                                                        </>
                                                        :
                                                        ""
                                                }
                                                <div className="supress_confirm_button_sec">
                                                    <Button disabled={analystDataExists || userContext.rolename != "Analyst"} onClick={(e) => addsuppressConfirm(e)} className="supress_confirm_button" variant="contained">Submit</Button>
                                                </div>


                                            </div>


                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className="accordian_header_wrapper" defaultExpanded={userContext.rolename === "Analyst" ? false : true} >
                                <AccordionSummary
                                    className="accordian_header"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography >
                                        <div>Manager Review</div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="suppressAlertContent">
                                            <div className="supress_left">
                                                <div className="Suppress_Alert_text">{!isAlertSuppresed && !analystDataExists ? "Suppression Request" : "Approve Request"}</div>

                                                <div className="supress_other_text">Date: {manegerSuppressAlertDate}</div>
                                                <div className="supress_other_text">Username: {managerName}</div>

                                                <div className="textContainer supress_text_area">
                                                    <div className="supress_document_section">
                                                        <div className="textHeader_supress"><span>DOCUMENTATION</span></div>
                                                        <div className="file_attach">
                                                            <div className="attach_text">Attachments</div>
                                                            <input accept=".pdf,.docx,.doc"
                                                                type="file" style={{ display: 'none' }}
                                                                id="icon-button-file-1"
                                                                multiple="multiple"
                                                                disabled={isalertApproved != null || userContext.rolename === "Analyst" || managerDataAvailable}
                                                                onChange={(e) => fileSelectHandler(e, "Maneger")}
                                                            />
                                                            <div className="supress_attach_section">
                                                                <label htmlFor="icon-button-file-1" className="labelattach">
                                                                    {(isalertApproved != null || userContext.rolename === "Analyst" || managerDataAvailable) ? "" :
                                                                        <Attachment className="attachmentIcon" />
                                                                    }
                                                                </label>
                                                                {
                                                                    selectedDocForManeger?.map((e) => (e.ext === "docx" || e.ext ==='doc') ?
                                                                        <div className="attach_image_wrapper">
                                                                            {(isalertApproved != null || userContext.rolename === "Analyst" || managerDataAvailable) ? ""
                                                                                : <button className="attach_close_icon" type="button" onClick={(el) => removeHandler(e, "Maneger")}>
                                                                                    <img className="close_icon" src={attach_close_icon} />
                                                                                </button>
                                                                            }

                                                                            <img className="attach_icon" src={attachExcel}
                                                                                onClick={(el) => viewDoc(el, e.url)} />
                                                                        </div>

                                                                        :
                                                                        e.ext === "pdf" ?
                                                                            <div className="attach_image_wrapper">
                                                                                {(isalertApproved != null || userContext.rolename === "Analyst" || managerDataAvailable) ? ""
                                                                                    : <button className="attach_close_icon" type="button" onClick={(el) => removeHandler(e, "Maneger")}>
                                                                                        <img className="close_icon" src={attach_close_icon} />
                                                                                    </button>
                                                                                }
                                                                                <img className="attach_icon" src={attach_pdf} onClick={(el) => viewDoc(el, e.url)} />
                                                                            </div>
                                                                            :
                                                                            <div className="attach_image_wrapper">
                                                                                {(isalertApproved != null || userContext.rolename === "Analyst" || managerDataAvailable) ? ""
                                                                                    : <button className="attach_close_icon" type="button" onClick={(el) => removeHandler(e, "Maneger")}>
                                                                                        <img className="close_icon" src={attach_close_icon} />
                                                                                    </button>
                                                                                }
                                                                                <img className="attach_icon" src={attachxlsx} />
                                                                            </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        multiline
                                                        rows={4}
                                                        value={manegerDescription}
                                                        disabled={isalertApproved != null || userContext.rolename === "Analyst" || managerDataAvailable}
                                                        onChange={(e) => manegerdesOnchangeHandler(e)}
                                                    />

                                                </div>
                                            </div>
                                            <div>
                                                {isAlertSuppresed ? (<div className="supress_confirm_button_sec">
                                                    <Button onClick={(e) => managerSubmitHandler(e, true)} disabled={isalertApproved != null || userContext.rolename === "Analyst"} className="supress_confirm_button" variant="contained">Approve</Button>
                                                    <Button onClick={(e) => managerSubmitHandler(e, false)} disabled={isalertApproved != null || userContext.rolename === "Analyst"} className="supress_decline_button" variant="contained">Decline</Button>
                                                </div>) : (<div className="supress_confirm_button_sec">
                                                    <Button disabled={isalertApproved != null || userContext.rolename === "Analyst" || managerDataAvailable} onClick={(e) => managerCommentHandler(e)} className="supress_confirm_button" variant="contained">Submit</Button>
                                                </div>)}

                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </DialogContent>
                        <DialogActions>

                        </DialogActions>
                    </Dialog>


                    <NotificationContainer />
                </div>
            </div >
        </div >
    );


};

export default Alerts;

import React, { useEffect, useRef, useState } from 'react'
import { Grid as GridDiv, GridColumn, GridNoRecords, GridToolbar } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import getAllAlertData from '../../apis/alerts/getAllAlertData';
import { DateTime } from "luxon";
import { Backdrop, Button, CircularProgress, Skeleton, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { FileDownload } from '@mui/icons-material';

const useStyles = makeStyles({
    odd: {
        background: '#F6F6F6 !important'
    },
    even: {

        background: '#fffff !important'
    },
    exportButton: {
        float: 'right',
        marginTop: '-70px !important',
        backgroundColor: ' #1CBDCD !important',
        width: '170px',
        textTransform: 'none !important',
        fontSize: '14px',
        color: 'black !important',
        fontWeight: 'bold !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 500px)': {
            marginTop: '-85px !important',
        }
    },
    waitingText: {
        marginLeft: '5px'
    }
})

const Allqueues = (props) => {
    const { userContext } = props;
    const [totalAlert, setTotalAlert] = useState('')
    const [availableData, setAvailableData] = useState(0)
    const [alertData, setAlertData] = useState([])
    const [highestSkip, setHighestSkip] = useState(0)
    const [highestTake, setHighestTake] = useState(10)
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true)
    const [blankData, setBlankData] = useState([]);
    const classes = useStyles();
    const _export = useRef(null);
    const _grid = useRef();
    const [open, setOpen] = useState(false)
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 10,
        filter: {
            logic: "and",
            filters: [
            ],
        },
        sort: [],
    });

    const [dataResult, setDataResult] = useState(
        process(alertData, dataState)
    );

    const getData = async (dataSkip, dataCount) => {
        let payload = {
            dataSkip: dataSkip,
            dataCount: dataCount
        }
        try {
            let res = await getAllAlertData(payload);
            setLoader(false)
            setAlertData(res.data.data)
            setAvailableData(availableData + res.data.data.length)
            setTotalAlert(res.data.totalAlert)
        } catch (error) {
            console.log("error", error)
        }
    }



    useEffect(() => {
        getData(0, 10);
    }, [])

    const dataProcessing = (data) => {
        let alertDate = 'alertDate'
        let customerName = 'customerName'
        let maskedAccNo = 'maskedAccNo'
        let alertId = 'alertId'
        let processedBy = 'processedBy'
        setDataResult(process(data, dataState));
        for (let i = 0; i < data.length; i++) {
            let date = DateTime.fromISO(data[i].createdAt).toFormat('MM-dd-yyyy')
            data[i][alertDate] = date;
            console.log("data", data[i])

            let firstName_customer = data[i].customers.FIRST_NAME ? data[i].customers.FIRST_NAME : "";
            let middleName_customer = data[i].customers.MIDDLE_NAMES ? data[i].customers.MIDDLE_NAMES + " " : "";
            let lastName_customer = data[i].customers.LAST_NAME ? data[i].customers.LAST_NAME : "";

            data[i][customerName] = firstName_customer + " " + middleName_customer + lastName_customer;

            data[i][maskedAccNo] = data[i].customers?.primaryaccount[0]?.ACCOUNT_ID ? data[i].customers?.primaryaccount[0].ACCOUNT_ID : "N/A";

            data[i][alertId] = data[i].id.split("-")[0].substring(0, 4)

            let actionsjson = data[i].actionsjson
            actionsjson = JSON.parse(actionsjson)
            if (actionsjson?.analyst.length > 0) {
                let analystData = actionsjson.analyst
                analystData = analystData[analystData.length - 1]
                data[i][processedBy] = analystData.updatedBy
            }
            else {
                data[i][processedBy] = "ALFA"
            }

        }
    }

    useEffect(() => {
        setDataResult(process(alertData, dataState));
        dataProcessing(alertData)
    }, [alertData]);



    const dataStateChange = async (event) => {
        let prevAlertData = alertData
        let apiCall = false
        let dataSkip = event.dataState.skip
        let dataCount = event.dataState.take
        setLoader(true)
        if (availableData !== totalAlert) {
            if (availableData === event.dataState.skip) {
                apiCall = true;
            }
            else if (availableData < event.dataState.skip) {
                dataSkip = availableData
                dataCount = event.dataState.take + event.dataState.skip - availableData
                apiCall = true
            }
            else if (event.dataState.skip + event.dataState.take > availableData) {
                if (event.dataState.skip === 0) {
                    dataSkip = availableData
                    dataCount = event.dataState.take - availableData
                    apiCall = true
                }
                else if ((availableData - event.dataState.skip) < event.dataState.skip) {
                    dataSkip = availableData
                    dataCount = event.dataState.skip - (availableData - event.dataState.skip)
                    apiCall = true
                }
            }
            if (apiCall) {
                let payload = {
                    dataSkip: dataSkip,
                    dataCount: dataCount,
                }
                try {
                    let res = await getAllAlertData(payload);
                    res = res.data.data;
                    setAvailableData(availableData + res.length)

                    res.forEach(element => {
                        prevAlertData.push(element);
                    });
                    dataProcessing(prevAlertData)
                    setAlertData(prevAlertData)

                } catch (error) {
                    console.log("error", error)
                }
            }
        }
        
        setLoader(false)


        setDataResult(process(prevAlertData, event.dataState))
        setDataState(event.dataState);
    }
    const viewAlertHandler = (e, event) => {
        navigate('/alerts', {
            state: {
                id: event.dataItem.id
            }
        });
    }


    const getSkeletons = () => {
        let arr = []
        for (let i = 0; i < highestTake; i++) {
            arr.push(<Skeleton className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="6vh" />)
        }
        return arr;
    }

    const excelExport = async () => {
        let prevAlertData = alertData
        if (dataState.skip + dataState.take <= totalAlert && highestSkip !== totalAlert && highestTake !== totalAlert) {
            setOpen(true)
            let payload = {
                dataSkip: highestSkip + dataState.take,
                dataCount: totalAlert - (highestSkip + dataState.take)
            }
            try {
                let res = await getAllAlertData(payload);
                res = res.data.data;

                res.forEach(element => {
                    prevAlertData.push(element);
                });
                dataProcessing(prevAlertData)
                setAlertData(prevAlertData)
                setHighestTake(totalAlert)
                setHighestSkip(totalAlert)

            } catch (error) {
                console.log("error", error)
            }
        }
        if (_export.current !== null) {
            _export.current.save(process(alertData, { ...dataState, take: null }));
            setOpen(false)
        }


    };

    return (
        <div>
            <Button variant='contained' className={classes.exportButton} onClick={excelExport}>
                <FileDownload />
                <span className='exportText'>Export to Excel</span>
            </Button>
            <ExcelExport ref={_export}>
                <GridDiv
                    filter={dataState.filter}
                    sort={dataState.sort}
                    sortable={true}
                    pageable={{
                        pageSizes: [5, 10, 20, 25, 50, 100],
                        info: true,
                        previousNext: true,
                        buttonCount: 10
                    }}
                    resizable={true}
                    total={totalAlert}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={loader ? blankData : dataResult}
                    onDataStateChange={dataStateChange}
                    className="kendoGrid_allqueue"
                    ref={_grid}
                >
                    <GridNoRecords>
                        {getSkeletons()}
                    </GridNoRecords>

                    <GridColumn
                        title="Action"
                        cell={(event) => {
                            return (
                                <>
                                    <td>
                                        <Button className='tab_inner_box_button_kendo' onClick={(e) => viewAlertHandler(e, event)} label="View Rule" variant="contained" size="small"  >View </Button>
                                    </td>
                                </>
                            );
                        }}
                        width='100px'
                    />
                    <GridColumn
                        title="Date"
                        field='alertDate'
                    />
                    <GridColumn
                        title="Alert ID"
                        field="alertId"
                    />
                    <GridColumn
                        title="Account ID"
                        field="maskedAccNo"
                    />
                    <GridColumn
                        title="Customer Name"
                        field="customerName"
                    />
                    <GridColumn
                        title="Processed By"
                        field="processedBy"
                    />
                    <GridColumn
                        title="Status"
                        field="finalstatus.name"
                    />
                </GridDiv>
            </ExcelExport>
            <Backdrop
                sx={{ color: '#fff' }}
                open={open}
            >
                <CircularProgress color="inherit" />
                <Typography className={classes.waitingText}>Please wait ...</Typography>
            </Backdrop>
        </div>
    )
}

export default Allqueues
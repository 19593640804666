import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box, AppBar, Toolbar, IconButton, Divider, Avatar, Tooltip, Menu, MenuItem, Typography, Grid } from "@mui/material";
import jwt_decode from "jwt-decode";

import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import { headerStyles, appbarStyles, avatarStyles, headerLeftStyles, searchIconStyle } from "./HeaderStyles";
import AlfaLogo from "./../../resources/images/ALFA_final.jpg";
import Avatar1 from "../alerts/images/avatar.png"
import '../Header/Header.css';
import { ArrowDropDown } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import ContactUsModal from "../ContactUs/ContactUsModal";
import { NotificationContainer } from "react-notifications";


function Header() {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false)
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    localStorage.removeItem("alfaToken");
    navigate('/');
  }
  const goToLanding = () => {
    navigate('/');
  }

  const goToSearch = () => {
    navigate('/search');
  }

  const [userContext, setuserContext] = useState([]);

  const tokenExpireVerification = async () => {
    const decodedJwt = await jwt_decode(localStorage.getItem("alfaToken"));
    setuserContext(decodedJwt);
  }

  useEffect(() => {
    tokenExpireVerification();
  }, []);
  const supportModalOpenHandler = () => {
    setIsSupportModalOpen(true);
    setAnchorElUser(null);
  }
  const supportModalCloseHandler = () => {
    setIsSupportModalOpen(false);
  }

  return (
    <>

      {/* Box for header section */}
      <Box sx={headerStyles} >
        <AppBar position="static" sx={appbarStyles} variant="dense" >
          <Container >
            <Toolbar disableGutters={true}>
              <div className="headerLeft">
                <Avatar variant="square" onClick={goToLanding} sx={avatarStyles} className="cp">
                  <img src={AlfaLogo} height="45vh" alt="Alfa Logo"></img>
                </Avatar>

              </div>

              <Box sx={headerLeftStyles}></Box>
              <Box>
                <IconButton >
                  <Avatar sx={{ height: "60px", width: "60px", bgcolor: "#f0f0f0" }}>
                    {/* onClick={goToLanding} sx={avatarStyles} className="cp" */}
                    <SearchIcon onClick={goToSearch} sx={{ color: 'black', fontSize: '50px' }} />
                  </Avatar>

                </IconButton>
              </Box>
              {/* <Divider orientation="vertical" variant="middle" flexItem light /> */}
              <Box className="mainMenuContainer">
                <Box className="dropDownMenu_header">
                  <Avatar alt="Remy Sharp" src={Avatar1} />
                  <div className="dropdown_information" onClick={handleOpenUserMenu}><span>Hello, {userContext.firstName}</span> <ArrowDropDown className="dropdownIcon" /></div>
                </Box>

                <Menu
                  className="menubar1"
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={supportModalOpenHandler}>
                    <div className="menuitemContainer">
                      <span className="fontFamily_menuItem">Support</span>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <div className="menuitemContainer">
                      <span className="fontFamily_menuItem">Sign Out</span>
                    </div>
                  </MenuItem>
                </Menu>

              </Box>



            </Toolbar>
          </Container>
        </AppBar>
      </Box>

      {/* supportus Modal */}
      <ContactUsModal isModalOpen={isSupportModalOpen} onModalClose={supportModalCloseHandler} />
      <NotificationContainer />
    </>
  )
}

export default Header
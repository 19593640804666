import axios from "axios";
const jsonConfig = require("../../config.json");

const  getAllDecision = async () =>{
    try{
    const getAllResponse = await axios.get(jsonConfig.apiUrl + "getallaidecision", {
           headers: {
             "x-functions-key": jsonConfig.key,
          },
        });

        return Promise.resolve(getAllResponse)

    }catch (error) {
       return Promise.reject(error)
    }
  }
export default  getAllDecision;